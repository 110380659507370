import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useHistory, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import getSEOKeywords from '../apis/dataforseo';
import { useDataContext } from '../contexts/data';
import { useUserContext } from '../contexts/user';
import { useProtectedFetch } from './fetch';
import { useCredits } from './credits';
import { useDropdownOutsideClick } from './dropdowns';
import { Register, Confirm, Login } from '../pages/authentication';
import { Modal } from '../components/modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useGA4React } from '../apis/googleanalytics';

import { ReactComponent as Columns } from '../assets/icon-columns.svg';
import { ReactComponent as Sort } from '../assets/icon-sort.svg';
import { ReactComponent as Addnew } from '../assets/icon-addnew.svg';
import { ReactComponent as Sorted } from '../assets/icon-sorted.svg';
import { ReactComponent as Article } from '../assets/icon-article.svg';
import { ReactComponent as Product } from '../assets/icon-product.svg';
import { ReactComponent as Social } from '../assets/icon-social.svg';
import { ReactComponent as Video } from '../assets/icon-video.svg';
import { ReactComponent as Podcast } from '../assets/icon-podcast.svg';
import { ReactComponent as Email } from '../assets/icon-email.svg';
import modalregister from '../assets/modal-register.jpg';

// Component to render the content plan table

const PlanTable = ({
	user,
	updateUser,
	userid,
	data,
	updateData,
	isdemo,
	isauth,
	incrementAction,
	modalsignup,
	setModalSignup,
	modallogin,
	setModalLogin,
	topicid,
	setTopicID,
	setContentID,
}) => {
	
	const { hasEnoughCredits, deductCredits } = useCredits();
	const { trackEvent } = useGA4React();
	
	// Update URL
	
	const updateURL = (slug) => {
		let currentURL = window.location.pathname;
		if (currentURL.endsWith('/')) {
			currentURL = currentURL.slice(0, -1);
		}
		if (currentURL === '/app/plan') {
			const newURL = `${currentURL}/${slug}`;
			window.history.pushState({}, '', newURL);
		}
	};
	
	// Initial setup
	
	const { id } = useParams();
	const [previd, setPrevID] = useState(null);
	const isInitialRender = useRef(true);
	const [isplanloading, setIsPlanLoading] = useState(true);
	const protectedFetch = useProtectedFetch();
	
	// Fetch topic history
	
	const fetchLastTopics = (projectid, number=1) => {
		if ( !projectid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/topics/${projectid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok:', response);
				return Promise.reject('Network response was not ok');
			}
			return response.json();
		})
		.then(topics => {
			if (topics && topics.length > 0) {
				const sortedTopics = topics.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedTopics.slice(0, number);
				} else {
					return sortedTopics;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
			return null;
		});
	};
	
	// Fetch topic by ID
	
	const fetchTopic = (topicid) => {
		if ( !topicid ) {
			return null;
		}
		return protectedFetch(`/api/topic/${topicid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while fetching topic:', response);
				return Promise.reject('Network response was not ok while fetching topic');
			}
			return response.json();
		})
		.then(topic => {
			return topic;
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
		});
	};
	
	// Create array of topics from tree object

	function treeToSet(obj, names = new Set()) {
		if (obj.name) {
			names.add(obj.name);
		}
		if (obj.children) {
			obj.children.forEach(child => {
				treeToSet(child, names);
			});
		}
		return names;
	}
	
	// Set topic to load
	
	const getTopicData = async () => {	
		if ( !user.projectid ) {
			return;
		}
		let newid;
		if (id && id !== topicid && id !== previd) {
			newid = id;
			setTopicID(id);
		} else { 
			if (topicid) {
				newid = topicid;
				updateURL(newid);
			} else {
				try {
					const fetchedTopics = await fetchLastTopics(user.projectid, 5);
					if (fetchedTopics && fetchedTopics.length > 0) {
						if (fetchedTopics[0] && fetchedTopics[0]._id) {
							newid = fetchedTopics[0]._id;
							updateURL(newid);
						}
					}
				} catch (error) {
					console.error('Last topic fetch error:', error);
				}
			}
		}
		if ( !newid ) {
			const focusElement = document.getElementById('input-seed');
			if (focusElement) {
				focusElement.focus();
			}
			setIsPlanLoading(false);
			return;
		} else {
			setPrevID(newid);
			setTopicID(newid);
		}
		try {
			const fetchedTopic = await fetchTopic(newid);
			if (fetchedTopic && fetchedTopic.topictree) {
				if (user.projectid && fetchedTopic.projectID && user.projectid !== fetchedTopic.projectID) {
					updateUser({ projectid: fetchedTopic.projectID });
				}
				const newUniqueTopics = treeToSet(fetchedTopic.topictree);
				updateData({ uniquetopics: newUniqueTopics, topicdata: fetchedTopic.topictree });
			} else {
				console.error('Ouch, could not load topic!');
				setIsPlanLoading(false);
			}
		} catch (error) {
			console.error('Topic fetch error:', error);
		}			
	};
	
	useEffect(() => {		
		getTopicData();
    }, [user.projectid, id, topicid]);
			
	// Transform topic tree into a flat table
	
	const [flatdata, setFlatData] = useState([]);	
	
	// Transform topic tree into a table

	function flattenData(data) {	
		const result = [];
		function recurse(data, parentid = null, depth = 0) {
			result.push({ 
				parentid, 
				depth,
				topic: data.name ? String(data.name) : '',
				deadline: data.deadline ? data.deadline : '',
				progress: data.progress ? data.progress : 'idea',
				format: data.format ? data.format : '',
				key: data.key ? data.key : '',
				volume: data.volume !== undefined ? data.volume : '',
				difficulty: data.difficulty !== undefined ? data.difficulty : '',
				created: data.created ? data.created : '',
				published: data.published ? data.published : '',
				notes: data.notes ? data.notes : '',
				content: data.content ? data.content : '',
			});
			(data.children || []).forEach(child => recurse(child, data.name, depth+1));
		}
		recurse(data);
		return result;	
	};
	
	function getOrder(data) {
		const order = [];
		function recurse(data) {
			order.push(data.name);
			(data.children || []).forEach(child => recurse(child));
		}
		recurse(data);
		return order;
	}
	
	useEffect(() => {
		if ( data.topicdata) {
			const demodata = localStorage.getItem('demodata');
			if (isdemo && demodata ) {
				const demodataparsed = JSON.parse(demodata);
				const newdemodata = {
					...demodataparsed,
					topicdata: data.topicdata
				};
				localStorage.setItem('demodata', JSON.stringify(newdemodata));
			}		
			const flat = flattenData(data.topicdata);
			var order = [];
			if ( user.planorder ) {
				order = user.planorder;
			} else {
				order = getOrder(data.topicdata);
			}
			flat.sort((a, b) => order.indexOf(a.topic) - order.indexOf(b.topic));	
			setFlatData(flat);
			setIsPlanLoading(false);
		}
    }, [data.topicdata]);
	
	useEffect(() => {
		if ( isInitialRender.current ) {		
			isInitialRender.current = false;
			return;
		} 
		if ( flatdata ) {
			const order = flatdata.map(item => item.topic);
			if (JSON.stringify(order) !== JSON.stringify(user.planorder)) {
				updateUser({ planorder: order });
			}
		}
	}, [flatdata]);
		
	// Update the topic tree object directly

	const updateTopicData = (currentNode, targetName, field, newValue) => {
		if (currentNode.name === targetName) {
			currentNode[field] = newValue;
		} else if (currentNode.children) {
			currentNode.children.forEach(child => updateTopicData(child, targetName, field, newValue));
		}
	}
	
	// Toggle columns
	
	const [temporaryColumns, setTemporaryColumns] = useState({ ...user.columnsToShow });
	const [plantablecrowded, setPlanTableCrowded] = useState("");
	const [plantablenotes, setPlanTableNotes] = useState("");
	const [seocolumnclass, setSEOColumnClass] = useState("");
	
	useEffect(() => {
		if ( isInitialRender.current ) {		
			isInitialRender.current = false;
			return;
		} 
		const newcolumns = {
			...user.columnsToShow,
			volume: user.seomode,
			difficulty: user.seomode,				
		}
		if ( ! user.seomode ) {
			setSEOColumnClass('dropdown-item-disabled');
		}
		if (JSON.stringify(newcolumns) !== JSON.stringify(user.columnsToShow)) {
			updateUser({ columnsToShow: newcolumns });
		}
    }, [user.seomode]);
	
	const toggleDropdownColumns = () => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		setShowDropdownColumns(!showDropdownColumns);
		setTemporaryColumns({ ...user.columnsToShow });
	};
	
	function gridColumns(temporaryColumns) { 
		const activeColumns = Object.values(temporaryColumns).filter(col => col);
		let newcolumns = activeColumns.map((item, index) => {
			return index === activeColumns.length - 1 ? 'auto' : 'max-content';
		}).join(' ');
		return 'max-content ' + newcolumns;
	}

	const applyColumnChanges = () => {		
		updateUser({
			columnsToShow: {
				...temporaryColumns			
			},
		});
		setShowDropdownColumns(false);
		if ( Object.values(temporaryColumns).filter(value => value).length > 6 ) {
			setPlanTableCrowded(" plan-table-crowded");
		} else {
			setPlanTableCrowded("");
		}
		if ( temporaryColumns.notes === false ) {
			setPlanTableNotes(" plan-table-notesoff");
		} else {
			setPlanTableNotes("");
		}
	};
	
	const handleColumnChange = (column) => {
		if ( user.seomode !== true ) {
			if ( column === 'volume' || column === 'difficulty' ) {
				alert("( ﾟｰﾟ)ノ  enable SEO mode in the top right corner to use this column");
				return;
			}
		}
		setTemporaryColumns((prevColumns) => ({
			...prevColumns,
			[column]: !prevColumns[column],
		}));
	};
	
	const { 
		showDropdown: showDropdownColumns, 
		setShowDropdown: setShowDropdownColumns, 
		dropdownRef: dropdownColumnsRef 
	} = useDropdownOutsideClick(false);
	
	// Create or edit content
	
	const gotourl = useNavigate();
	
	const handleTopicClick = async (item, topicid) => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'planclick',
			loc: window.location.pathname,
		});
		const topic = item.topic;
		let fetchid = item.content;
		let created = new Date().toISOString();
		if ( ! fetchid ) {
			try {
				const response = await protectedFetch('/api/content', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						userID: userid,
						topicID: topicid,
						heading: { topic: topic }
					})
				});
				const responsedata = await response.json();
				console.log(responsedata);
				if ( responsedata && responsedata._id ) {
					console.log('creating content');
					fetchid = responsedata._id;
					created = responsedata.created;
					const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
					updateTopicData(newTopicData, topic, 'content', fetchid);
					updateTopicData(newTopicData, topic, 'created', created);
					if ( item.progress === 'idea' ) { 
						updateTopicData(newTopicData, topic, 'progress', 'draft');
					}
					if ( !item.notes || item.notes === '' ) {
						updateTopicData(newTopicData, topic, 'notes', '');
					}
					console.log(newTopicData);
					updateData({ topicdata: newTopicData });
				} else {
					console.log('Error creating content from topic');
					return;
				}					
			} catch (error) {
				console.error('Content creation error:', error);
			}
		}
		setContentID(fetchid);
		gotourl(`/app/write/${fetchid}`);
	};
	
	// Context menu for plan items
	
	const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

	const handleContextMenu = (event, topic) => {
		event.preventDefault();
		event.stopPropagation();
		setIsContextMenuVisible(topic);
	};
	
	const hideContextMenu = (event) => {
		setIsContextMenuVisible(false);
	};
	
	const handleContextMenuClick = (event) => {
		hideContextMenu();
	};

	const handleContextMenuEscape = (event) => {
		if (event.key === "Escape") {
			hideContextMenu();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleContextMenuClick);
		document.addEventListener('keyup', handleContextMenuEscape);
		return () => {
			document.removeEventListener('click', handleContextMenuClick);
			document.removeEventListener('keyup', handleContextMenuEscape);
		};
	}, []);
	
	const handleContentRemove = async (item, parentNode = data.topicdata) => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		const isConfirmed = window.confirm("(╭ರ_•́)  Are you absolutely positive?");
		if (isConfirmed) {
			let deleteid = item.content;
			try {
				if ( deleteid ) {
					const response = await protectedFetch(`/api/content/${deleteid}`, {
						method: 'DELETE',
					});
				}
				const updatedTopicData = JSON.parse(JSON.stringify(parentNode));
				const removeItemRecursive = (itemName, node) => {
					node.children = node.children.filter(child => child.name !== itemName);
					node.children.forEach(child => {
						if (child.children) {
							removeItemRecursive(itemName, child);
						}
					});
				};
				removeItemRecursive(item.topic, updatedTopicData);
				updateData({ topicdata: updatedTopicData });
				setContentID(null);
			} catch (error) {
				console.error('Error deleting content: ', error);
			}	
		} else {
			return;
		}
	};
	
	// Set deadlines
	
	const [datepickerDeadlineClass, setDatepickerDeadlineClass] = useState('datepicker');
		
	const handleDeadlineChange = (date, targetName) => {
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		updateTopicData(newTopicData, targetName, 'deadline', date ? format(date, "MMM d, yyyy") : null);
		updateData({ topicdata: newTopicData });
	};
		
	// Choose item progress
	
	const [selectedProgressRow, setSelectedProgressRow] = useState(null);
	const progressStates = ["idea", "draft", "reviewing", "published"];
	
	const toggleDropdownProgress = () => {
		setShowDropdownProgress(!showDropdownProgress);
	};
	
	const handleProgressChange = (itemName, newState) => {
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		if ( newState === 'published' ) {
			const dateNow = format(new Date(), "MMM d, yyyy");
			updateTopicData(newTopicData, itemName, 'published', dateNow);
		} else {
			updateTopicData(newTopicData, itemName, 'published', '');
		}
		updateTopicData(newTopicData, itemName, 'progress', newState);
		updateData({ topicdata: newTopicData });
		setSelectedProgressRow(null);
		setShowDropdownProgress(false);
	};
	
	const { 
		showDropdown: showDropdownProgress, 
		setShowDropdown: setShowDropdownProgress, 
		dropdownRef: dropdownProgressRef 
	} = useDropdownOutsideClick(false);
	
	// Choose item format
	
	const [selectedFormatRow, setSelectedFormatRow] = useState(null);
	const formatIcons = {
		Article,
		Social,
		Product,
		Video,
		Podcast,
		Email,
	}
	const formatStates = Object.keys(formatIcons).map(key => key.toLowerCase());
	formatStates.push("clear");
	
	const FormatIcon = ({ iconName }) => {
		iconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
		const Icon = formatIcons[iconName];
		return <Icon />;
	}
	
	const toggleDropdownFormat = () => {
		setShowDropdownFormat(!showDropdownFormat);
	};
	
	const handleFormatChange = (itemName, newState) => {
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		if ( newState === 'clear' ) {
			updateTopicData(newTopicData, itemName, 'format', '');
		} else {
			updateTopicData(newTopicData, itemName, 'format', newState);
		}
		updateData({ topicdata: newTopicData });
		setSelectedFormatRow(null);
		setShowDropdownFormat(false);
	};
	
	const { 
		showDropdown: showDropdownFormat, 
		setShowDropdown: setShowDropdownFormat, 
		dropdownRef: dropdownFormatRef 
	} = useDropdownOutsideClick(false);
	
	// Fetch SEO data
	
	const { loadingSEOData, setLoadingSEOData } = useUserContext();
	const handleAddSEOData = async (event, topic) => {
		event.stopPropagation();
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalSignup(true);
			return;
		}
		if ( user.seocountry === 'World' || user.seolanguage === 'All languages' ) {
			alert("(~˘▾˘)~  For this to work, you need to pick a specific country and language in the top right corner!");
		} else {
			setLoadingSEOData(topic);
			await getPlanSEOData();
			setLoadingSEOData('');
		}
	};
	
	const getPlanSEOData = async() => {
		const topicstoenrich = flatdata.filter(item => item.volume === undefined || item.volume === '').map(item => item.topic);
		const newSEOData = await getSEOKeywords(topicstoenrich, 'historical_search_volume', user.seocountry, user.seolanguage);
		const topicsomitted = topicstoenrich.filter(str => 
			!newSEOData.some(item => item.key === str)
		);
		const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
		const updateTree = (node) => {
			const newProperties = newSEOData.find(
				(prop) => prop.key === node.name
			);
			if (newProperties) {
				for (let key in newProperties) {
					if (newProperties[key] === '' || newProperties[key] === null || newProperties[key] === undefined) {
						newProperties[key] = 0;
					}
				}
				Object.assign(node, newProperties);
			} else if (topicsomitted && topicsomitted.includes(node.name)) {
				node.volume = 0;
				node.difficulty = 0;
			}
			if (node.children) {
				node.children.forEach(updateTree);
			}
		};
		updateTree(updatedTopicData);
		updateData({ topicdata: updatedTopicData });
	}
	
	// Add notes
	
	const notePlaceholder = '';
	const notePlaceholderColor = '#0098C4';
	const [isNoteEditing, setIsNoteEditing] = useState(false);
	const noteRef = useRef({});

	const handleNoteEdit = (targetName) => {
		setIsNoteEditing(targetName);
	};

	const handleNoteBlur = (targetName) => (e) => {
		const currentText = e.currentTarget.innerText.trim() || notePlaceholder;
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		updateTopicData(newTopicData, targetName, 'notes', currentText);
		updateData({ topicdata: newTopicData });
		setIsNoteEditing(null);
	};
	
	const handleNoteKeyPress = (targetName) => (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleNoteBlur(targetName)(e);
		}
	}
	
	useEffect(() => {
		if (isNoteEditing && noteRef.current[isNoteEditing]) {
			const currentNoteRef = noteRef.current[isNoteEditing];
			const currentNode = flatdata.find(item => item.topic === isNoteEditing);
			currentNoteRef.textContent = currentNode ? currentNode.notes : notePlaceholder;
		}
	}, [isNoteEditing]);
	
	// Make rows draggable
	
	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(flatdata);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setFlatData(items);
	};
	
	// Sort columns
	
	const [sortSettings, setSortSettings] = useState({ column: 'tree', direction: 'desc' });
	const [temporarySortSettings, setTemporarySortSettings] = useState({ column: 'tree', direction: 'desc' });
	const [sortApplied, setSortApplied] = useState(false);
	const firstRenderRef = useRef(false);
	
	const { 
		showDropdown: showDropdownSort, 
		setShowDropdown: setShowDropdownSort, 
		dropdownRef: dropdownSortRef 
	} = useDropdownOutsideClick(false);

	const toggleDropdownSort = () => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		setShowDropdownSort(!showDropdownSort);
	};
	
	const handleSortColumnChange = (column) => {
		if ( user.seomode !== true ) {
			if ( column === 'volume' || column === 'difficulty' ) {
				alert("( ﾟｰﾟ)ノ  enable SEO mode in the top right corner to use this column");
				return;
			}
		}
		setTemporarySortSettings((prevSettings) => ({
			...prevSettings,
			column: column,
		}));
	};
	
	const handleSortDirectionChange = (direction) => {
		setTemporarySortSettings((prevSettings) => ({
			...prevSettings,
			direction: direction,
		}));
	};
	
	const applySortChanges = () => {
		setSortSettings({ ...temporarySortSettings });
		setShowDropdownSort(false);
		if ( temporarySortSettings.column === 'tree' ) {
			setSortApplied(false);
		} else {
			setSortApplied(true);
		}
	};

	useEffect(() => {
		if (firstRenderRef.current) {
			setFlatData((prevData) => {
				let newData = [...prevData];
				if ( sortSettings.column === 'tree' ) {
					newData = flattenData(data.topicdata);
				} else {
					newData.sort((a, b) => {
						let columnA = a[sortSettings.column];
						let columnB = b[sortSettings.column];
						let result = 0;
						if (Array.isArray(columnA)) {
							if (columnA.length < columnB.length) {
								return sortSettings.direction === 'asc' ? -1 : 1;
							}
							if (columnA.length > columnB.length) {
								return sortSettings.direction === 'asc' ? 1 : -1;
							}
						} else if (typeof columnA === 'string') {
							if (sortSettings.column === 'progress') {
								const progressOrder = ['idea', 'draft', 'reviewing', 'published'];
								return sortSettings.direction === 'asc' ? 
									progressOrder.indexOf(columnA) - progressOrder.indexOf(columnB) :
									progressOrder.indexOf(columnB) - progressOrder.indexOf(columnA);
							}
							if (columnA < columnB) {
								return sortSettings.direction === 'asc' ? -1 : 1;
							}
							if (columnA > columnB) {
								return sortSettings.direction === 'asc' ? 1 : -1;
							}
						} else if (typeof columnA === 'number') {
							return sortSettings.direction === 'asc' ? columnA - columnB : columnB - columnA;
						} else if (columnA instanceof Date) {
							return sortSettings.direction === 'asc' ? columnA - columnB : columnB - columnA;
						}
						if (result === 0) {
							result = a.created.localeCompare(b.created);
						}
						return result;
					});
				}
				return newData;
			});
		}
		firstRenderRef.current = true;
    }, [sortSettings]);
	
	// Add new item
	
	const handleAddNewClick = (event) => {
		event.stopPropagation();
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		const subTopicName = prompt('New item topic:');
		if (subTopicName) {
			addNewItem(subTopicName);
		}
	}
	
	const addNewItem = (subTopicName) => {
		const newItem = {
			name: subTopicName,
			added: new Date().toISOString(),
			progress: "idea",
			notes: '',
		};
		if (data.uniquetopics.has(subTopicName)) {
			alert("（；¬＿¬)  this subtopic already exists. Let's try another one?");
			return;
		}
		const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
		updatedTopicData.children.push(newItem);
		updateData({ topicdata: updatedTopicData });		
	}
		
	// Render content plan
	
	return (
		<div className="plan-wrap">
			{isplanloading ? (
				<div className="loader">...setting up content plan <span className="loading-indicator"></span></div>
			) : (
				data.topicdata ? (
				
				<div className="plan-inner">
								
					<div className="workspace-nav">
												
						<div className="workspace-nav-item plan-nav-columns">
							<div className="link-inline">
								<Columns className="icon icon-columns" />
								<a onClick={toggleDropdownColumns} className="noselect">
									Columns
								</a>
							</div>
							<span className="workspace-nav-hint">{Object.values(temporaryColumns).filter(value => value).length}/{Object.keys(user.columnsToShow).length}</span>
							{showDropdownColumns && (
							<div className="dropdown dropdown-nav dropdown-plan-columns" ref={dropdownColumnsRef}>
								<div className="dropdown-items">
									{Object.keys(temporaryColumns).map((column) => (
									<div className={`dropdown-item ${(column === 'volume' || column === 'difficulty') ? seocolumnclass : ''}`} key={column}>
										<label>
											<input type="checkbox" checked={temporaryColumns[column]} onChange={() => handleColumnChange(column)} />
											{column.charAt(0).toUpperCase() + column.slice(1)}
										</label>
									</div>
									))}
								</div>
								<div className="dropdown-apply" onClick={applyColumnChanges}>
									Apply
								</div>
							</div>
							)}
						</div>
												
						<div className="workspace-nav-item plan-nav-sort">
							<div className="link-inline">
								<Sort className="icon icon-sort" />
								<a onClick={toggleDropdownSort} className="noselect">
									Sort
								</a>
							</div>
							<span className="workspace-nav-hint">{sortApplied ? "ON\u00a0" : "OFF"}</span>
							{showDropdownSort && (	
							<div className="dropdown dropdown-nav" ref={dropdownSortRef}>
								<div className="dropdown-items">
									<div className="dropdown-section">
										{Object.keys(user.columnsToShow).filter(value => value !== "notes").map((column) => (
										<div className={`dropdown-item ${(column === 'volume' || column === 'difficulty') ? seocolumnclass : ''}`} key={column}>
											<input type="radio" id={'sort-'+column} checked={temporarySortSettings.column === column} onChange={() => handleSortColumnChange(column)} />
											<label htmlFor={'sort-'+column} >{column.charAt(0).toUpperCase() + column.slice(1)}</label>
										</div>
										))}
										<div className="dropdown-item" key="tree">
											<input type="radio" id='sort-tree' checked={temporarySortSettings.column === 'tree'} onChange={() => handleSortColumnChange('tree')} />
											<label htmlFor='sort-tree' >Tree order</label>
										</div>
									</div>
									<div className="dropdown-section">
										{['asc', 'desc'].map((direction) => (
										<div className="dropdown-item" key={direction}>
											<input type="radio" id={'sort-'+direction} checked={temporarySortSettings.direction === direction} onChange={() => handleSortDirectionChange(direction)} />
											<label htmlFor={'sort-'+direction} >{direction.toUpperCase()}</label>
										</div>
										))}
									</div>
								</div>
								<div className="dropdown-apply" onClick={applySortChanges}>
									Apply
								</div>
							</div>								
							)}
						</div>
						
						<div className="workspace-nav-item plan-nav-new">
							<div className="link-inline">
								<Addnew className="icon icon-addnew" />
								<a onClick={handleAddNewClick} className="noselect">
									Add item 
								</a>
							</div>
						</div>
						
					</div>
										
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="flatdata">
							{(provided) => (
			
								<div className={`plan-table ${plantablecrowded} ${plantablenotes}`} {...provided.droppableProps} ref={provided.innerRef}>
								
									<div className="plan-row plan-header" key="header">
										<div className="plan-col plan-col-helper">
											
										</div>
										{Object.entries(user.columnsToShow).map(([key, value], index) => {
											let colname = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
											if ( key === 'volume' ) {
												colname = 'Keyword volume';
											} else if ( key === 'difficulty' ) {
												colname = (
												<>
												<span>KD</span>
												<div className="tooltip">
													<div className="tooltip-mark">
														?
													</div>
													<div className="tooltip-text">
														<strong>Keyword Difficulty</strong> indicates how hard it is to get into the top-10 for a given keyword, on a scale from 0 (very easy) to 100 (extremely difficult).
													</div>
												</div>
												</>
												);
											}
											if (value) {
												return (
													<div className={`plan-col plan-col-${key} ${(user.columnsToShow.difficulty && key === 'volume') ? 'plan-col-concat' : ''}`} key={index}>
														<span className="plan-col-item">{colname}</span>
														{sortSettings.column === key && (
														<span className={`plan-col-sorted plan-col-sorted-${temporarySortSettings.direction}`}>
															<Sorted className="icon icon-sorted" />
														</span>
														)}
													</div>
												);
											} else {
												return null;
											}
										})}
									</div>
									
									{flatdata.map((item, index) => (
									
										<Draggable key={item.topic.replace(/\s+/g, '-')+'_' +index} draggableId={item.topic.replace(/\s+/g, '-')+'_'+index} index={index}>
											{(provided,snapshot) => {

												const draggingStyles = snapshot.isDragging
													? {
														borderRight: "none",
														borderBottom: "none",
														backgroundColor: "#f5f5f577",
														boxShadow: "none",
													}
													: {};
													
												return (
									
													<div className="plan-row" key={item.topic.replace(/\s+/g, '-')+'_'+index} ref={provided.innerRef} {...provided.draggableProps} style={provided.draggableProps.style}>
													
														<div className="plan-col plan-col-helper" {...provided.dragHandleProps} style={{...draggingStyles}}>
															<span>:::</span>
														</div>
														
														{user.columnsToShow.topic && (
															<div className="plan-col plan-col-topic" style={{...draggingStyles}} onClick={() => handleTopicClick(item, topicid)} onContextMenu={(e) => { handleContextMenu(e, item.topic); }} >
																<span className="plan-col-item">{item.topic}</span>
																{isContextMenuVisible !== item.topic && (
																	<div className="plan-topic-open">{item.content ? "open" : "create"}</div>
																)}
																{isContextMenuVisible === item.topic && (
																	<div className="context-menu context-menu-plan">
																		<div className="context-menu-pin">
																		</div>
																		<div className="context-menu-wrap">
																			<div className="context-menu-items">
																				<div className="context-menu-item" onClick={(e) => { e.stopPropagation(); handleTopicClick(item, topicid); }}>{item.content ? "Open content" : "Create content"}</div>
																				{item.depth > 0 && (
																				<div className="context-menu-item" onClick={(e) => { e.stopPropagation(); handleContentRemove(item); }}>Remove</div>
																				)}
																			</div>
																			<div className="context-menu-close" onClick={(e) => { e.stopPropagation(); hideContextMenu(e); }}>
																				✕
																			</div>
																		</div>
																	</div>
																)}
															</div>
														)}														
														
														{user.columnsToShow.deadline && (
															<div className="plan-col plan-col-deadline" style={{...draggingStyles}}>
																<DatePicker
																	className={"datepicker"}
																	popperClassName="datepicker-popper"
																	wrapperClassName="datepicker-wrapper"
																	selected={item.deadline ? new Date(item.deadline) : null}
																	onChange={(date) => handleDeadlineChange(date, item.topic)}
																	dateFormat="MMM d, yyyy"
																	isClearable
																	placeholderText=''
																	showPopperArrow={false}
																/>
															</div>
														)}
														
														{user.columnsToShow.progress && (
															<div className="plan-col plan-col-progress" onClick={() => { setSelectedProgressRow(index); toggleDropdownProgress(); }} style={{...draggingStyles}}>
																<div className={`plan-progress-tag plan-progress-tag-${item.progress ? item.progress : 'idea'}`}>
																	{item.progress ? item.progress : 'idea'}
																</div>
																{showDropdownProgress && selectedProgressRow === index && (
																	<div className="dropdown dropdown-progress" ref={dropdownProgressRef}>
																		<div className="dropdown-items">
																			{progressStates.map((state) => (
																				<div className={`plan-progress-tag plan-progress-tag-${state}`} key={state} onClick={() => handleProgressChange(item.topic, state)}>
																					{state}
																				</div>
																			))}
																		</div>
																	</div>
																)}
															</div>
														)}
														
														{user.columnsToShow.format && (
															<div className="plan-col plan-col-format" onClick={() => { setSelectedFormatRow(index); toggleDropdownFormat(); }} style={{...draggingStyles}}>
																{item.format && item.format !== '' && (
																<div className={`plan-format-tag plan-format-tag-${item.format}`}>
																	<FormatIcon iconName={item.format} className={`icon icon-${item.format}`} />
																	{item.format ? item.format : ''}
																</div>
																)}
																{showDropdownFormat && selectedFormatRow === index && (
																	<div className="dropdown dropdown-format" ref={dropdownFormatRef}>
																		<div className="dropdown-items">
																			{formatStates.map((state) => (
																				<div className={`plan-format-tag plan-format-tag-${state}`} key={state} onClick={() => handleFormatChange(item.topic, state)}>
																					{state !== 'clear' && (
																					<FormatIcon iconName={state} className={`icon icon-${state}`} />
																					)}
																					{state}
																				</div>
																			))}
																		</div>
																	</div>
																)}
															</div>
														)}
														
														{user.columnsToShow.volume && (
															<div className={`plan-col plan-col-volume ${user.columnsToShow.difficulty ? 'plan-col-concat' : ''}`} style={{...draggingStyles}}>
																<span className="plan-col-item">
																	{
																	( item.volume !== undefined && item.volume !== "" )
																	  ? item.volume
																	  : loadingSEOData === item.topic
																		? <span className="loading-indicator"></span>
																		: <span className="treeseo-add treeseo-add-volume" onClick={(event) => handleAddSEOData(event, item.topic)}>+</span>
																	}
																</span>
															</div>
														)}
														
														{user.columnsToShow.difficulty && (
															<div className="plan-col plan-col-difficulty" style={{...draggingStyles}}>
																<span className="plan-col-item">
																	{
																	( item.difficulty !== undefined && item.difficulty !== "" )
																	  ? item.difficulty
																	  : loadingSEOData === item.topic
																		? <span className="loading-indicator"></span>
																		: <span className="treeseo-add treeseo-add-difficulty" onClick={(event) => handleAddSEOData(event, item.topic)}>+</span>
																	}
																</span>
															</div>
														)}
														
														{user.columnsToShow.created && (
															<div className="plan-col plan-col-created" style={{...draggingStyles}}>
																<span className="plan-col-item">{item.created ? format(new Date(item.created), "MMM d, yyyy") : ''}</span>
															</div>
														)}
														
														{user.columnsToShow.published && (
															<div className="plan-col plan-col-published" style={{...draggingStyles}}>
																<span className="plan-col-item">{item.published ? item.published : ''}</span>
															</div>
														)}
														
														{user.columnsToShow.notes && (
															<div className="plan-col plan-col-notes" style={{...draggingStyles}}>
																
																{isNoteEditing === item.topic ? (
																<div 
																	className="plan-note plan-note-editing"
																	contentEditable
																	onBlur={handleNoteBlur(item.topic)}
																	onKeyDown={handleNoteKeyPress(item.topic)}
																	ref={el => {
																		noteRef.current[item.topic] = el;
																		if (el && isNoteEditing === item.topic) {
																			el.textContent = item.notes || notePlaceholder;
																			el.focus();
																		}
																	}}
																/>
																) : (
																<div
																	className={`plan-note ${!item.notes ? 'plan-note-empty' : ''}`}
																	onClick={() => handleNoteEdit(item.topic)}
																	style={!item.notes ? { color: notePlaceholderColor } : {}}
																>
																	{item.notes || notePlaceholder}
																</div>
																)}
																
															</div>
														)}
														
													</div>
													
												);
												
											}}
										</Draggable>
										
									))}
									{provided.placeholder}
								</div>
								
							)}
						</Droppable>
					</DragDropContext>
					
				</div>
					
				) : (
				
					<div className="view-empty-placeholder">Type something above to get started   <span className="textface">(づ｡◕‿‿◕｡)づ</span></div>
				
				)
			)}
			
			{isdemo && !isauth && (
			<div className="modals">
				<Modal className='modal-signup' backgroundImage={modalregister} isOpen={modalsignup} onClose={() => setModalSignup(false)}>
					<Register fullpage={false} />
				</Modal>
				<Modal className='modal-login' backgroundImage={modalregister} isOpen={modallogin} onClose={() => setModalLogin(false)}>
					<Login fullpage={false} />
				</Modal>
			</div>
			)}
			
		</div>
		
	);
	
};

// Content plan component for the Main view

function Plan() {	

	const { user, updateUser } = useUserContext();
	const { userid, setUserID } = useUserContext();
	const { topicid, setTopicID } = useUserContext();
	const { contentid, setContentID } = useUserContext();
	const { data, updateData } = useDataContext();
	const { isdemo, setIsDemo } = useUserContext();
	const { isauth, setIsAuth } = useUserContext();
	const { modalsignup, setModalSignup } = useUserContext();
	const { modallogin, setModalLogin } = useUserContext();	
			
	return (
		<div className="view view-plan">
			<PlanTable
				user={user}
				updateUser={updateUser}
				userid={userid}
				data={data}
				updateData={updateData}
				isdemo={isdemo}
				isauth={isauth}
				modalsignup={modalsignup}
				setModalSignup={setModalSignup}
				modallogin={modallogin}
				setModalLogin={setModalLogin}
				topicid={topicid}
				setTopicID={setTopicID}
				setContentID={setContentID}
			/>
		</div>
	);
	
}

export default Plan;
