import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { useDataContext } from '../contexts/data';
import { useProtectedFetch } from '../components/fetch';
import { Modal } from '../components/modal';

function Dashboard() {	
	
	const { user, updateUser } = useUserContext();
	const { accesstoken } = useUserContext();
	const { userid, setUserID } = useUserContext();
	const { projectid, setProjectID } = useUserContext();
	const { topicid, setTopicID } = useUserContext();
	const { contentid, setContentID } = useUserContext();
	const { data, updateData } = useDataContext();
	const [projects, setProjects] = useState([]);
	const [projectsandtopics, setProjectsAndTopics] = useState([]);
	const [error, setError] = useState(null); 
	const [isprojectsloading, setIsProjectsLoading] = useState(true);
	const [updateprojects, setUpdateProjects] = useState(0);
	const protectedFetch = useProtectedFetch();
	const gotourl = useNavigate();
	
	// Get projects by user ID
	
	const fetchProjects = (userid, number=1) => {
		if ( !userid ) {
			return;
		}
		return protectedFetch(`/api/projects/${userid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok:', response);
				return Promise.reject('Network response was not ok');
			}
			return response.json();
		})
		.then(projects => {
			if (projects && projects.length > 0) {
				const sortedProjects = projects.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedProjects.slice(0, number);
				} else {
					return sortedProjects;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Projects fetch error:', error);
		});
	};

	useEffect(() => {
		if ( !userid  || !accesstoken ) {
			setIsProjectsLoading(false);
			return;
		}
        fetchProjects(userid, -1)
		.then(fetchedProjects => {
			if ( fetchedProjects ) {
				setProjects(fetchedProjects);
			}
			setIsProjectsLoading(false);
		})
		.catch((error) => {
			console.error('Projects fetch error:', error);
		});
    }, [userid,updateprojects,accesstoken]);
	
	// Get topics by project
	
	const fetchTopics = (projectid, number=5) => {
		if ( !projectid ) {
			return;
		}
		return protectedFetch(`/api/topics/${projectid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response not ok while fethching topics:', response);
				return Promise.reject('Network response not ok while fethching topics:');
			}
			return response.json();
		})
		.then(topics => {
			if (topics && topics.length > 0) {
				const sortedTopics = topics.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedTopics.slice(0, number);
				} else {
					return sortedTopics;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Topics fetch error:', error);
			return null;
		});
	};
	
	const getProjectsWithTopics = async (projects) => {
		const projectsWithTopicsPromises = projects.map(async (project) => {
			if ( !project._id ) {
				return;
			}
			const topics = await fetchTopics(project._id, -1);
			return {
				...project,
				topics: topics
			};
		});
		const projectsWithTopics = await Promise.all(projectsWithTopicsPromises);
		return projectsWithTopics;
	};
	
	useEffect(() => {
		if ( !projects || projects.length === 0 ) {
			return;
		}
		const fetchAndSetProjects = async () => {
			const fetchedProjectsWithTopics = await getProjectsWithTopics(projects);
			if ( user.projectid ) {
				fetchedProjectsWithTopics.sort((a, b) => {
					if (a._id === user.projectid) {
						return -1;
					}
					if (b._id === user.projectid) {
						return 1;
					}
					return 0;
				});
			}
			setProjectsAndTopics(fetchedProjectsWithTopics);
		};
		fetchAndSetProjects();
    }, [projects]);
	
	// Format date
	
	function historyDate(dateString) {
		const options = { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
		const date = new Date(dateString);
		return date.toLocaleString(undefined, options);
	}
	
	// Return a list of projects
	
	const [moveSelectedTopic, setMoveSelectedTopic] = useState(null);
	const [moveSelectedProject, setMoveSelectedProject] = useState('');
	
	function SelectProject({ data, currentID }) {	
		function handleChange(event) {
			const selectedName = event.target.value;
			setMoveSelectedProject(selectedName);
			const selectedItem = data.find(item => item.name === selectedName);
			if (selectedItem) {
				setSelectedProjectMovetopic(selectedItem._id);
			}
		}
		const options = data.filter(item => item._id !== currentID);
		return (
			<div className="select-project-dropdown">
				<select value={moveSelectedProject} onChange={handleChange}>
					<option value="" disabled>Select project</option>
					{options.map(option => (
						<option key={option._id} value={option.name}>
							{option.name}
						</option>
					))}
				</select>
			</div>
		);
	}
	
	// Create new project
	
	const createProject = async(event) => {
		event.stopPropagation();
		if ( !userid ) {
			return;
		}
		const projectName = prompt('Name your new project:');
		if (!projectName) {
			return;
		}
		const response = await protectedFetch('/api/project', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				userID: userid,
				name: projectName,
			})
		});
		const responsedata = await response.json();
		if ( responsedata._id ) {
			updateUser({ projectid: responsedata._id });
			setTopicID(null);
			setContentID(null);
			setProjects(prevProjects => [...prevProjects, responsedata]);
		} else {
			setError('Something went haywire, could not create a new project!');
		}
	};
	
	// Rename project
	
	const renameProject = async(event, projectid) => {
		event.stopPropagation();
		const newProjectName = prompt('Rename project to:');
		if (newProjectName) {
			try {
				const updatedProject = await updateProject(projectid, { name: newProjectName });
				if (updatedProject && updatedProject._id) {
					setUpdateProjects(updateprojects+1);
				}
			} catch (error) {
				console.error('Last topic fetch error:', error);
			}
		}
	}
	
	const updateProject = (projectid, updatedProperties) => {
		if ( !projectid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/project/${projectid}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(updatedProperties)
		})
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while updating project:', response);
				return Promise.reject('Network response was not ok while updating project');
			}
			return response.json();
		})
		.catch((error) => {
			console.error('Project update error:', error);
			return null;
		});
	};
	
	// Delete project 
	
	const deleteProject = async(event, projectid) => {
		event.stopPropagation();
		const isConfirmed = window.confirm("(╭ರ_•́)  Oh my! Are you absolutely positive you want to delete this project and ALL its topics and content?");
		if (isConfirmed) {
			try {
				const updatedProject = await updateProject(projectid, { bin: true });
				if (updatedProject && updatedProject._id) {
					setUpdateProjects(updateprojects+1);
				}
			} catch (error) {
				console.error('Last topic fetch error:', error);
			}
		} else {
			return;
		}
	}
	
	// Click on topic
	
	const handleTopicClick = (event,id) => {
		updateData({ isloading: true });
		event.stopPropagation();
		setTopicID(id);
		gotourl(`/app/topics/${id}`);
	}
	
	// Add topic
	
	const addTopic = (event, projectID) => {
		if (window.pageYOffset === 0) {
            const focusElement = document.getElementById('input-seed');
            if (focusElement) {
                focusElement.focus();
            }
            return;
        }
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		const handleScroll = () => {
            if (window.pageYOffset === 0) {
                window.removeEventListener('scroll', handleScroll);
                const focusElement = document.getElementById('input-seed');
                if (focusElement) {
                    focusElement.focus();
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
	}
	
	// Reveal additional topic options
	
	const [showOptionsLinks, setShowOptionsLinks] = useState(null);
	
	const handleTopicOptions = (event, topic) => {
		event.stopPropagation();
		if ( showOptionsLinks !== topic._id ) {
			setShowOptionsLinks(topic._id);
		} else {
			setShowOptionsLinks(null);
		}
	}
	
	// Update topic
	
	const updateTopic = (topicid, updatedProperties) => {
		if ( !topicid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/topic/${topicid}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(updatedProperties)
		})
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while updating topic:', response);
				return Promise.reject('Network response was not ok while updating topic');
			}
			return response.json();
		})
		.catch((error) => {
			console.error('Topic update error:', error);
			return null;
		});
	};
	
	// Move topic
	
	const [isModalOpenMovetopic, setIsModalOpenMovetopic] = useState(false);
	const [selectedProjectMovetopic, setSelectedProjectMovetopic] = useState("");
	
	const moveTopic = async (topic) => {
		if (selectedProjectMovetopic) {
			try {
				const updatedTopic = await updateTopic(topic._id, { projectID: selectedProjectMovetopic });
				if (updatedTopic && updatedTopic._id) {
					setUpdateProjects(updateprojects+1);
				}
				setIsModalOpenMovetopic(false);
			} catch (error) {
				console.error('Topic move error:', error);
			}
		} else {
			return;
		}
	}
	
	// Delete topic
	
	const deleteTopic = async (event, topicid) => {
		event.stopPropagation();
		const isConfirmed = window.confirm("(╭ರ_•́)  Are you sure you want to delete this topic and all its associated content?");
		if (isConfirmed) {
			try {
				const updatedTopic = await updateTopic(topicid, { bin: true });
				if (updatedTopic && updatedTopic._id) {
					if ( topicid && topicid === updatedTopic._id ) {
						setTopicID(null);
					}
					setUpdateProjects(updateprojects+1);
				}
			} catch (error) {
				console.error('Topic deletion error:', error);
			}
		} else {
			return;
		}
	}
	
	// Render dashboard content
	
	return (
		<div className="dashboard-wrap">
		
			{isprojectsloading ? (
				<div className="loader">...loading your projects <span className="loading-indicator"></span></div>
			) : (
			
				<div className="dashboard-projects">
			
					<div className="projects-create">
						+ <a className="noselect create-link projects-create-link" onClick={(event) => createProject(event)}>create project</a>
					</div>
					
					<div className="projects-list">
				
						{projectsandtopics && projectsandtopics && projectsandtopics.map((project, index) => (
							
							<div className={`projects-item ${project._id === user.projectid ? 'projects-item-active' : ''}`} key={index}>
								
								<div className="projects-item-icon">
								</div>
								
								<div className="projects-item-inner">
									
									<div className="projects-item-parent">
										
										<div className="projects-item-name">
											{project.name && project.name}
										</div>
										
										<div className="projects-item-actions">
											<div className="projects-item-action projects-item-action-rename" onClick={(event) => renameProject(event,project._id)}>
												rename
											</div>
											<div className="projects-item-action projects-item-action-delete" onClick={(event) => deleteProject(event,project._id)}>
												delete
											</div>
										</div>
										
									</div>
									
									<div className="projects-item-topics">
										
										<div className="projects-item-topic-prefix">
											{project.topics && project.topics.length > 0 && '│'}
										</div>
										
										{project.topics && project.topics.length > 0 && project.topics.map((topic, index) => (
										
										<div className="projects-item-topic" key={index}>
											
											<span className="projects-item-topic-prefix">
												{`${'├──\u00a0'}`}
											</span>
											
											<div className="projects-item-topic-name" onMouseLeave={() => setShowOptionsLinks(null)}>
												<div onClick={(event) => handleTopicClick(event,topic._id)} className={`projects-item-topic-link ${topic._id === topicid ? 'projects-item-topic-link-active' : ''}`}>{topic.seed}</div>
												<div className="projects-item-topic-options">
													<div className="projects-item-topic-options-dots noselect" onClick={(event) => handleTopicOptions(event,topic)}>
														●●●
													</div>
													{showOptionsLinks === topic._id && (
													<div className="projects-item-topic-options-links">
														<div className="projects-item-topic-options-link projects-item-topic-options-link-move" onClick={() => { setIsModalOpenMovetopic(true); setMoveSelectedTopic(topic); }}>
															move
														</div>
														<div className="projects-item-topic-options-link projects-item-topic-options-link-delete" onClick={(event) => deleteTopic(event, topic._id)}>
															delete
														</div>
													</div>
													)}
												</div>
											</div>
											
											<div className="projects-item-topic-prefix">
												{index + 1 < project.topics.length ? '│' : ''}
											</div>
											
										</div>
											
										))}
										
										<div className="topics-create">
										
											<div className="projects-item-topic-prefix">
												│
											</div>
											
											<span className="projects-item-topic-prefix">
												{`${'└──\u00a0'}`}
											</span>
											
											<span className="noselect create-link topics-create-link" onClick={(event) => addTopic(event, project._id)}>add topic</span>
												
										</div>
											
									</div>
									
								</div>
								
							</div>
							
						))}
					
					</div>
					
				</div>

			)}
			
			{moveSelectedTopic && (
			<Modal isOpen={isModalOpenMovetopic} onClose={() => setIsModalOpenMovetopic(false)}>
				<p>Move the topic cluster to the following project:</p>
				<div className="select-project">
					<SelectProject data={projectsandtopics} currentID={moveSelectedTopic.projectID} />
					<div className="select-project-link" onClick={() => moveTopic(moveSelectedTopic)}>Move topic</div>
				</div>
			</Modal>
			)}
			
		</div>
	);
  
}

export default Dashboard;