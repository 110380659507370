// TODO
// Merge uniquetopics with the order array in plan.js
// Deduplicate functions vs plan.js: treeToSet and fetchLastTopics
// Avoid deep-cloning
// Consider refactoring into smaller components or using hooks
// Perhaps useReducer for managing the state of the tree 

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDataContext } from '../contexts/data';
import { useUserContext } from '../contexts/user';
import { useProtectedFetch } from './fetch';
import { useCredits } from './credits';
import isEqual from 'lodash/isEqual';
import apiOpenAI from '../apis/openai';
import getSEOKeywords from '../apis/dataforseo';
import { useGA4React } from '../apis/googleanalytics';

import { ReactComponent as Treenode } from '../assets/icon-treenode.svg';
import { ReactComponent as Undo } from '../assets/icon-undo.svg';
import { ReactComponent as Redo } from '../assets/icon-redo.svg';
import { ReactComponent as Expand } from '../assets/icon-expand.svg';
import { ReactComponent as Collapse } from '../assets/icon-collapse.svg';
import { ReactComponent as History } from '../assets/icon-history.svg';
import { ReactComponent as Slide } from '../assets/icon-slide.svg';
import { ReactComponent as Logomark } from '../assets/logomark.svg';
import iconai from '../assets/icon-ai.svg';
import iconseo from '../assets/icon-seo.svg';

// Topic tree render logic

const TreeItem = ({ 
	label, 
	children,
	volume,
	difficulty,
	depth,
	content,
	isFirstItem, 
	isLastItem, 
	isFirstChild, 
	isLastChild, 
	prefix, 
	separatorTop, 
	separatorBottom, 
	isExpanded,
	toggleNode,
	fetchSubtopics,
	getSEOSubtopics,
	removeItem,
	appendSubtopic,
	removeAllChildren,
	addParentTopic,
	isAddSubtopicNode,
	maxLength,
	maxVolLength,
	user,
	isauth,
	getSEOData,
	renderContextMenu,
	handleContextMenu,
	isContextMenuVisible,
	setModalSignup,
	setModalUpgrade,
	loadingTopics, 
	setLoadingTopics,
	loadingSEOData, 
	setLoadingSEOData,
	keywordsEmpty,
	setKeywordsEmpty
}) => {
	
	const { hasEnoughCredits, deductCredits } = useCredits();
	
	const hasChildren = children && children.length > 0;
	const divRef = useRef(null);
	const labelRef = useRef(null);
	const treeContainerRef = useRef(null);
	const [truncatedName, setTruncatedName] = useState(label);
	const [truncatedClass, setTruncatedClass] = useState(false);
	const [truncatedTitle, setTruncatedTitle] = useState(false);

	let childPrefix = '';
	if (isLastChild) {
		childPrefix += '\u00a0';
	} else {
		childPrefix += '│\u00a0\u00a0\u00a0\u00a0\u00a0';
	}
	if (isLastItem) {
		childPrefix += '\u00a0\u00a0\u00a0\u00a0\u00a0';
	} else {
		childPrefix += '';
	}
			
	let itemPrefix = '';
	if (prefix) {
		itemPrefix += prefix;
		if (isLastChild) {
			if (isAddSubtopicNode) {
				itemPrefix += '\u00a0\u00a0\u00a0\u00a0';
			} else {
				itemPrefix += '└──\u00a0';
			}
		} else {
			itemPrefix += '├──\u00a0';
		}
	} else {
		itemPrefix += '\u00a0';
	}
	
	let childSeparatorTop = '';
	if (separatorTop) {
		childSeparatorTop += separatorTop;
		if (!isLastChild) {
			childSeparatorTop += '│\u00a0\u00a0\u00a0\u00a0\u00a0';
		} else {
			childSeparatorTop += '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0';
		}
	} else {
		childSeparatorTop += '\u00a0';
	}
	
	let childSeparatorBottom = '';
	if (separatorBottom) {
		childSeparatorBottom += separatorBottom;
		if (!isLastChild) {
			childSeparatorBottom += '│\u00a0\u00a0\u00a0\u00a0\u00a0';
		} 
		if (isLastItem) {
			childSeparatorBottom += '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0';
		}
	} else {
		childSeparatorBottom += '\u00a0';
	}
	
	const typingSpeed = 50;
	// const animatedLabel = useTypingEffect(label, typingSpeed);
	// const animatedItemPrefix = useTypingEffect(itemPrefix, typingSpeed);
			
	const removeHandler = () => { removeItem(label); };
	
	const fetchSubtopicsHandler = async (event, topic, adding) => {
		event.stopPropagation();
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			if ( !isauth ) {
				setModalSignup(true);
			} else {
				setModalUpgrade(true);
			}
			return;
		}
		setLoadingTopics(topic);
		await fetchSubtopics(topic, adding);
	};
	
	const addSubtopicHandler = (event) => {
		event.stopPropagation();
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			if ( !isauth ) {
				setModalSignup(true);
			} else {
				setModalUpgrade(true);
			}
			return;
		}
		const subTopicName = prompt('Subtopic name:');
		if (subTopicName) {
			appendSubtopic(label, subTopicName);
		}
	};
	
	const fetchKeywordsHandler = async (event, topic, adding) => {
		event.stopPropagation();
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalSignup(true);
			return;
		}
		setLoadingTopics(topic);
		let num = 10;
		if (adding) {
			num = 3;
		}
		await getSEOSubtopics(topic, num, adding);
	};
	const handleRemoveAllChildren = () => {
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalSignup(true);
			return;
		}
		removeAllChildren(label);
	};
	const handleAddParentTopic = async (event, topic) => {
		event.stopPropagation();
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalSignup(true);
			return;
		}
		setLoadingTopics('parent');
		await addParentTopic(label);
	}
	const clickHandlerProps = isAddSubtopicNode ? {} : hasChildren ? { onClick: () => toggleNode(label) } : {};
	const handleAddSEOData = async (event, topic) => {
		event.stopPropagation();
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalSignup(true);
			return;
		}
		if ( user.seocountry === 'World' || user.seolanguage === 'All languages' ) {
			alert("(~˘▾˘)~  For this to work, you need to pick a specific country and language in the top right corner!");
		} else {
			setLoadingSEOData(topic);
			await getSEOData(label);
			setLoadingSEOData('');
		}
	};
	
	// Left-align SEO data
	
	let numDashes = 0;
	let numDashesVol = 0;
	const dashSymbol = '—';
	if ( typeof maxLength === 'number' && !isNaN(maxLength) ) {
		numDashes = maxLength - label.length;
		numDashes = Math.max(0, numDashes);
	}
	volume = volume !== undefined ? Math.round(volume / 100) * 100 : undefined;
	const volLength = volume !== undefined ? volume.toString().length : 0;
	numDashesVol = maxVolLength - volLength;
	
	let dashesvol = '';
	dashesvol = new Array(numDashes + 1).join(dashSymbol);
	numDashesVol = parseInt(numDashesVol); 
	if ( isNaN(numDashesVol) || numDashesVol < 0 ) {
		numDashesVol = 0;
	}
	let moredashes = new Array(numDashesVol + 1).join(dashSymbol);
	dashesvol = dashSymbol + dashSymbol + dashesvol + moredashes;
	if ( hasChildren ) {	
		maxLength = children.reduce((max, item) => Math.max(max, item.name.length), 0);
		maxVolLength = children.reduce((max, item) => Math.max(max, (item.volume ? item.volume.toString().length : 0)), 0);
	} else {
		dashesvol = dashSymbol + dashesvol;
	}
	if ( volume === undefined ) {
		dashesvol = dashesvol.slice(0, -1);
	}
	
	let dasheskd = '—';
	if ( typeof difficulty === 'number' && !isNaN(difficulty) ) {
		if ( difficulty < 100 ) {
			dasheskd = dasheskd + dashSymbol;
			if ( difficulty < 10 ) {
				dasheskd = dasheskd + dashSymbol;
			}
		}
	}
		
	// Handle overflowing items
	
	/*
	useEffect(() => {
		if (divRef.current && treeContainerRef.current) {
			const parentWidth = treeContainerRef.current.clientWidth;
			let textWidth = divRef.current.scrollWidth;
			const maxItemLength = 40; 
			if (label.length > maxItemLength) {
				const extra = maxItemLength - label.length;
				const truncatedText = label.slice(0, extra);
				setTruncatedName(truncatedText + '...');
				setTruncatedTitle(true);
			} else {
				if (textWidth > parentWidth - 200) {
					setTruncatedClass(true);
					setTruncatedTitle(true);
				}
			}			
			/*
			// alternatively, truncate to maxium container width
			if (textWidth > parentWidth) {
				let truncatedText = label;
				while (textWidth > parentWidth && truncatedText.length > 0) {
					truncatedText = truncatedText.slice(0, -1);
					labelRef.current.innerText = `${truncatedText}...`;
					textWidth = divRef.current.scrollWidth;
				}
				setTruncatedName(truncatedText + '...');
			}
			// add comment block close here!!
		}
	}, [label, divRef, treeContainerRef]);	
	*/
			
	return (
		<div ref={treeContainerRef} className="treewrap">
			{isFirstItem ? (
			<span className="treeitem-top">
				<span className="treeitem-parent" onContextMenu={(e) => { handleContextMenu(e, label); }}>{label}</span>
				{renderContextMenu(label, content, depth)}
				{loadingTopics === 'parent' ? (
					<span className="loader loader-subtopics"> ...brainstorming <span className="loading-indicator"></span></span>
				) : (
					<div className="treelinks">
						<img src={iconai} alt="AI" /><span className="treelink treelink-parent" onClick={(event) => handleAddParentTopic(event, label)}>brainstorm parent</span>
					</div>
				)}
			</span>
			) : (
			<span ref={divRef} {...clickHandlerProps} className={`treeitem ${truncatedClass ? 'treeitem-truncated' : ''} ${hasChildren ? 'treeitem-expandable' : ''}`}>
				<span className="treebranch">{itemPrefix}</span>
				{hasChildren && 
				<span className={`triangle triangle-${isExpanded ? 'expanded' : 'collapsed'}`}>
					<Treenode className="icon icon-treenode" />
				</span>
				}
				{isAddSubtopicNode ? (
					<>
					{loadingTopics === label ? (
						<span className="loader loader-subtopics"> ...brainstorming <span className="loading-indicator"></span></span>
					) : (
						<>
						{keywordsEmpty === label ? (
							<div className="treelinks treelinks-after treelinks-after-message">
								Couldn't find any relevant keywords 〈◕﹏◕〉
							</div>
						) : ( 
							<div className="treelinks treelinks-after">
								<span>
									<img src={iconai} alt="AI" /><span onClick={(event) => fetchSubtopicsHandler(event, label, true)} className="treelink treelink-more">more subtopics</span>
									{user.seomode &&
									<>
									<img src={iconseo} alt="SEO" /><span onClick={(event) => fetchKeywordsHandler(event, label, true)} className="treelink treelink-more">more keywords</span>
									</>
									}
									<span onClick={(event) => addSubtopicHandler(event)} className="treelink treelink-manual">manual subtopic</span>
								</span>
							</div>
						)}
						</>
					)}
					</>
				) : (
					<>
					<div ref={labelRef} className="treelabel" title={truncatedTitle ? label : undefined} onContextMenu={(e) => { handleContextMenu(e, label); }}>{label}{renderContextMenu(label, content, depth)}</div>
					{user.seomode &&  
					<span className="treeseo">
						<span className="treeseo-dashes treeseo-dashes-volume">
							{dashesvol}
						</span>	
						<span className="treeseo-volume">
							{
							volume !== undefined
							  ? volume
							  : loadingSEOData === label
								? <span className="loading-indicator"></span>
								: <span className="treeseo-add treeseo-add-volume" onClick={(event) => handleAddSEOData(event, label)}>+</span>
							}
							{isFirstChild &&
							<div className="tree-heading tree-heading-volume">
								Volume
							</div>
							}
						</span>
						<span className="treeseo-dashes treeseo-dashes-difficulty">
							{dasheskd}
						</span>	
						<span className="treeseo-difficulty">
							{
							difficulty !== undefined
							  ? difficulty
							  : loadingSEOData === label
								? <span className="loading-indicator"></span>
								: <span className="treeseo-add treeseo-add-difficulty" onClick={(event) => handleAddSEOData(event, label)}>+</span>
							}
							{isFirstChild &&
							<div className="tree-heading tree-heading-difficulty">
								<span>KD</span>
								<div className="tooltip">
									<div className="tooltip-mark">
										?
									</div>
									<div className="tooltip-text">
										<strong>Keyword Difficulty</strong> indicates how hard it is to get into the top-10 for a given keyword, on a scale from 0 (very easy) to 100 (extremely difficult).
									</div>
								</div>
							</div>
							}
						</span>
					</span>
					}
					{(loadingTopics === label && !hasChildren) ? (
						<span className="loader loader-subtopics"> ...brainstorming <span className="loading-indicator"></span></span>
					) : (
						isContextMenuVisible !== label && (
						<div className="treelinks">
							{hasChildren ? (
							<span className="treelink treelink-remove" onClick={handleRemoveAllChildren}>remove subtopics</span>
							) : (
							<span>
								<img src={iconai} alt="AI" /><span className="treelink treelink-deepen" onClick={(event) => fetchSubtopicsHandler(event, label, false)}>add subtopics</span>
								{user.seomode &&
								<>
								<img src={iconseo} alt="SEO" /><span className="treelink treelink-seo" onClick={(event) => fetchKeywordsHandler(event, label, false)}>check keywords</span>
								</>
								}
								<span className="treelink treelink-remove" onClick={removeHandler}>remove</span>
							</span>
							)}
						</div>
						)
					)}
					</>
				)}
			</span>
			)}
			
			{hasChildren && (
			<div className={`tree ${isExpanded ? 'tree-expanded' : ''}`}>
				{isFirstItem && <div><span className="treebranch">{'\u00a0'+'│'}</span></div>}
				<span className="treebranch">{childSeparatorTop}{'│'}</span>
				{children.map((child, index, arr) => (
					<TreeItem 
						key={child.name} 
						label={child.name} 
						volume={child.volume}
						difficulty={child.difficulty}
						children={child.children} 
						depth={depth + 1}
						content={child.content} 
						isFirstItem={false}
						isLastItem={index===arr.length - 1}
						isFirstChild={index===0}
						isLastChild={index===arr.length - 1}
						prefix={`${prefix}${childPrefix}`}
						separatorTop={childSeparatorTop}
						separatorBottom={childSeparatorBottom}
						toggleNode={toggleNode}
						isExpanded={child.expanded}
						fetchSubtopics={fetchSubtopics}
						getSEOSubtopics={getSEOSubtopics}
						removeItem={removeItem}
						appendSubtopic={appendSubtopic}
						removeAllChildren={removeAllChildren}
						truncatedName={truncatedName}
						setTruncatedName={setTruncatedName}
						maxLength={maxLength}
						maxVolLength={maxVolLength}
						user={user}
						isauth={isauth}
						getSEOData={getSEOData}
						renderContextMenu={renderContextMenu}
						handleContextMenu={handleContextMenu}
						isContextMenuVisible={isContextMenuVisible}
						setModalSignup={setModalSignup}
						setModalUpgrade={setModalUpgrade}
						loadingTopics={loadingTopics}
						setLoadingTopics={setLoadingTopics}
						loadingSEOData={loadingSEOData}
						setLoadingSEOData={setLoadingSEOData}
						keywordsEmpty={keywordsEmpty}
						setKeywordsEmpty={setKeywordsEmpty}
					/>
				))}		
				<TreeItem 
                    label={label} 
					volume={volume}
					difficulty={difficulty}
                    children={[]} 
                    depth={depth + 1}
                    isFirstItem={false}
                    isLastItem={true}
                    isFirstChild={children.length === 0}
                    isLastChild={true}
                    prefix={`${prefix}${childPrefix}`}
                    separatorTop={childSeparatorTop}
                    separatorBottom={childSeparatorBottom}
                    toggleNode={toggleNode}
                    isExpanded={false}
					fetchSubtopics={fetchSubtopics}
					getSEOSubtopics={getSEOSubtopics}
                    appendSubtopic={appendSubtopic}
                    isAddSubtopicNode={true}
					truncatedName={truncatedName}
					setTruncatedName={setTruncatedName}
					maxLength={maxLength}
					maxVolLength={maxVolLength}
					user={user}
					isauth={isauth}
					getSEOData={getSEOData}
					setModalSignup={setModalSignup}
					setModalUpgrade={setModalUpgrade}
					loadingTopics={loadingTopics}
					setLoadingTopics={setLoadingTopics}
					loadingSEOData={loadingSEOData}
					setLoadingSEOData={setLoadingSEOData}
					keywordsEmpty={keywordsEmpty}
					setKeywordsEmpty={setKeywordsEmpty}
                />				
				{!isLastItem && !isLastChild &&	<span className="treebranch">{childSeparatorBottom}</span>}
			</div>
			)}
		</div>
	);
	
};

// Topic tree component

function TopicTree({}) {
	
	const { hasEnoughCredits, deductCredits } = useCredits();
	const { data, updateData } = useDataContext();
	const { user, updateUser } = useUserContext();
	const { isdemo, setIsDemo } = useUserContext();
	const { isauth, setIsAuth } = useUserContext();
	const { userid, setUserID } = useUserContext();
	const { setModalSignup, setModalUpgrade } = useUserContext();
	const { topicid, setTopicID } = useUserContext();
	const { contentid, setContentID } = useUserContext();
	const { loadingTopics, setLoadingTopics } = useUserContext();
	const { loadingSEOData, setLoadingSEOData } = useUserContext();
	const { keywordsEmpty, setKeywordsEmpty } = useUserContext();
	const [ treehistory, setTreeHistory ] = useState([]);
	const [ currenthistoryindex, setCurrentHistoryIndex ] = useState(-1);
	const [ isTreeExpanded, setIsTreeExpanded ] = useState(false);
	const gotourl = useNavigate();
	const { trackEvent } = useGA4React();
	const [ navigateUrl, setNavigateUrl ] = useState(null);
	const [ newid, setNewId ] = useState(null);
		
	// Update URL
	
	const updateURL = (slug) => {
		let currentURL = window.location.pathname;
		if (currentURL.endsWith('/')) {
			currentURL = currentURL.slice(0, -1);
		}
		if (currentURL === '/app/topics') {
			const newURL = `${currentURL}/${slug}`;
			window.history.pushState({}, '', newURL);
		}
	};
	
	// Create array of topics from tree object

	function treeToSet(obj, names = new Set()) {
		if (obj.name) {
			names.add(obj.name);
		}
		if (obj.children) {
			obj.children.forEach(child => {
				treeToSet(child, names);
			});
		}
		return names;
	}
	
	// Fetch topic by topic ID
	
	const fetchTopic = (topicid) => {
		if ( !topicid ) {
			return null;
		}
		return protectedFetch(`/api/topic/${topicid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while fetching topic:', response);
				return Promise.reject('Network response was not ok while fetching topic');
			}
			return response.json();
		})
		.then(topic => {
			return topic;
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
		});
	};
	
	// Update project
	
	const updateProject = (projectid, updatedProperties) => {
		if ( !projectid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/project/${projectid}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(updatedProperties)
		})
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while updating project:', response);
				return Promise.reject('Network response was not ok while updating project');
			}
			return response.json();
		})
		.catch((error) => {
			console.error('Project update error:', error);
			return null;
		});
	};
	
	// Fetch topic history
	
	const fetchLastTopics = (projectid, number=1) => {
		if ( !projectid || isdemo ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/topics/${projectid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok:', response);
				return Promise.reject('Network response was not ok');
			}
			return response.json();
		})
		.then(topics => {
			if (topics && topics.length > 0) {
				const sortedTopics = topics.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedTopics.slice(0, number);
				} else {
					return sortedTopics;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
			return null;
		});
	};
	
	// Get topic to load
	
	const { id } = useParams();
	const [previd, setPrevID] = useState(null);
	const protectedFetch = useProtectedFetch();
	
	// Set topic to load

	const getTopicData = async () => {
		if ( isdemo && !isauth ) {
			return;
		}
		let fetchedTopic;		
		let fetchedTopics;
		if ( !user.projectid ) {
			return;
		} else if (id) {
			fetchedTopic = await fetchTopic(id);
			if (fetchedTopic && fetchedTopic.projectID && user.projectid !== fetchedTopic.projectID) {
				updateUser({ projectid: fetchedTopic.projectID });
				try {
					const updatedProject = await updateProject(fetchedTopic.projectID, { updated: new Date().toISOString() });
				} catch (error) {
					console.error('Last topic fetch error:', error);
				}
				return;
			}
		}
		try {
			fetchedTopics = await fetchLastTopics(user.projectid, 5);
		} catch (error) {
			console.error('Last topic fetch error:', error);
		}
		let newid;
		if (id && id !== topicid && id !== previd) {
			newid = id;
			setTopicID(id);
		} else { 
			if (topicid) {
				newid = topicid;
				updateURL(newid);
			} else {
				if ( fetchedTopics && fetchedTopics[0] && fetchedTopics[0]._id ) {
					newid = fetchedTopics[0]._id;
					updateURL(newid);
				} else {
					updateData({ isloading: false });
					const focusElement = document.getElementById('input-seed');
					if (focusElement) {
						focusElement.focus();
					}
					return null;
				}
			}
		}
		if ( !newid ) {
			return;
		} else {
			setPrevID(newid);
			setTopicID(newid);
		}
		try {
			const fetchedTopic = await fetchTopic(newid);
			if (fetchedTopic && fetchedTopic.topictree) {
				const newUniqueTopics = treeToSet(fetchedTopic.topictree);
				setTreeHistory([]);
				setCurrentHistoryIndex(0);
				updateData({ uniquetopics: newUniqueTopics, topicdata: fetchedTopic.topictree, isloading: false });
			} else {
				console.error('Ouch, could not load topic!');
			}
		} catch (error) {
			console.error('Topic fetch error:', error);
		}
	};
	
	useEffect(() => {		
		getTopicData();
    }, [user.projectid, id, topicid]);
	
	// Save tree to database - or local storage if demo
	
	const [shouldUpdateHistory, setShouldUpdateHistory] = useState(true);
	
	useEffect(() => {
		if (!data.topicdata) {
			return;
		}
		const newUniqueTopics = treeToSet(data.topicdata);
		const demodata = localStorage.getItem('demodata');
		if (isdemo && demodata ) {
			const demodataparsed = JSON.parse(demodata);
			const newdemodata = {
				...demodataparsed,
				topicdata: data.topicdata
			};
			localStorage.setItem('demodata', JSON.stringify(newdemodata));
		}
		if ( shouldUpdateHistory ) {
			const newHistory = [...treehistory, data.topicdata];
			setTreeHistory(newHistory.slice(-5));
			setCurrentHistoryIndex(currenthistoryindex + 1);
		} else {
			setShouldUpdateHistory(true);
		}
		updateData({ uniquetopics: newUniqueTopics });
	}, [data.topicdata]);
	
	// Save tree history
	
	useEffect(() => {
		localStorage.setItem('topicDataHistory', JSON.stringify(treehistory));
		localStorage.setItem('topicDataHistoryIndex', JSON.stringify(currenthistoryindex));
    }, [treehistory,currenthistoryindex]);
		
	// Get search keyword data
	
	const getSEOSubtopics = async(topic, num=10, adding=false) => {
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'seosubtopic',
			loc: window.location.pathname,
		});
		let SEOsubtopics;	
		const isArrayEmpty = arr => Array.isArray(arr) && arr.length === 0;
		const storageKey = `SEOsubtopics_${user.projectid}_${topicid}_${topic}_${user.seocountry}_${user.seolanguage}`;	
		if (adding) {
			const storedSEOsubtopics = localStorage.getItem(storageKey);
			if (storedSEOsubtopics) {
				SEOsubtopics = JSON.parse(storedSEOsubtopics);
				if ( isArrayEmpty(SEOsubtopics) ) {
					setLoadingTopics('');
					setKeywordsEmpty(topic);
					setTimeout(() => {
						setKeywordsEmpty('');
					}, 3000);
				}
			} else {
				SEOsubtopics = await getSEOKeywords(topic, 'keyword_suggestions', user.seocountry, user.seolanguage);
				localStorage.setItem(storageKey, JSON.stringify(SEOsubtopics));
			}
		} else {
			SEOsubtopics = await getSEOKeywords(topic, 'keyword_suggestions', user.seocountry, user.seolanguage);
			localStorage.setItem(storageKey, JSON.stringify(SEOsubtopics));
		}
		const indexToRemove = SEOsubtopics.findIndex(item => item.key === topic);
		let otherProps = null;
		if (indexToRemove !== -1) {
			const removedItem = SEOsubtopics.splice(indexToRemove, 1)[0];
			const { key, ...props } = removedItem;
			otherProps = props;
		}
		const topSEOsubtopics = SEOsubtopics.filter(item => !data.uniquetopics.has(item.key)).slice(0, num);
		if ( !isArrayEmpty(topSEOsubtopics) ) {
			const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
			const updateTree = (node) => {
				if (node.name === topic) {
					if (adding) {
						node.children = node.children.concat(topSEOsubtopics.map((subItem) => ({ name: subItem.key, added: new Date().toISOString(), volume: subItem.volume, difficulty: subItem.difficulty })));
					} else {
						node.children = topSEOsubtopics.map((subItem) => ({ name: subItem.key, added: new Date().toISOString(), volume: subItem.volume, difficulty: subItem.difficulty }));
						if ( otherProps ) {
							Object.assign(node, otherProps);
						}
					}
					node.expanded = true;
				} else if (node.children) {
					node.children.forEach(updateTree);
				}
			};
			updateTree(updatedTopicData);
			updateData({ topicdata: updatedTopicData });
			setLoadingTopics('');
		} else {
			setLoadingTopics('');
			setKeywordsEmpty(topic);
			setTimeout(() => {
				setKeywordsEmpty('');
			}, 3000);
		}		
	}
	
	// Enrich subtopics with SEO data
	
	const getSEOData = async(topic) => {
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'seodata',
			loc: window.location.pathname,
		});
		function findSiblings(data, targetName) {
			if (data.children) {
				for (let child of data.children) {
					if (child.name === targetName) {
					return {
						siblings: data.children.map(sibling => sibling.name),
						parentName: data.name
					};
				}
				const result = findSiblings(child, targetName);
					if (result) return result; 
				}
			}
			return null; 
		}
		const siblingdata = findSiblings(data.topicdata, topic);	
		if ( siblingdata.siblings ) {
			siblingdata.siblings.push(siblingdata.parentName);
		}
		const siblingExtras = await getSEOKeywords(siblingdata.siblings, 'historical_search_volume', user.seocountry, user.seolanguage);
		const topicsomitted = siblingdata.siblings.filter(str => 
			!siblingExtras.some(item => item.key === str)
		);
		const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
		const updateTree = (node) => {
			const newProperties = siblingExtras.find(
				(prop) => prop.key === node.name
			);
			if (newProperties) {
				for (let key in newProperties) {
					if (newProperties[key] === '' || newProperties[key] === null || newProperties[key] === undefined) {
						newProperties[key] = 0;
					}
				}
				Object.assign(node, newProperties);
			} else if (topicsomitted && topicsomitted.includes(node.name)) {
				node.volume = 0;
				node.difficulty = 0;
			}				
			if (node.children) {
				node.children.forEach(updateTree);
			}
		};
		updateTree(updatedTopicData);
		updateData({ topicdata: updatedTopicData });	
	}
	
	// Undo and redo buttons 
	
	const undo = () => {
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalSignup(true);
			return;
		}
        if (currenthistoryindex > 0) {
			setShouldUpdateHistory(false);
            const newIndex = currenthistoryindex - 1;
            if ( treehistory[newIndex-1] ) {
				setCurrentHistoryIndex(newIndex);
				updateData({ topicdata: treehistory[newIndex-1] });
			}
        } else {
			return null;
		}
    };

    const redo = () => {
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			if ( !isauth ) {
				setModalSignup(true);
			} else {
				setModalUpgrade(true);
			}
			return;
		}
        if (currenthistoryindex < 5) {
			setShouldUpdateHistory(false);
            const newIndex = currenthistoryindex + 1;
			if ( treehistory[newIndex-1] ) {
				setCurrentHistoryIndex(newIndex);
				updateData({ topicdata: treehistory[newIndex-1] });
			}
        } else {
			return null;
		}
    };
	
	// Expand and collapse tree branches

	const toggleNode = (nodeName) => {
		const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
		const setExpandedForNode = (obj) => {
			if (obj.name === nodeName) {
				obj.expanded = ! obj.expanded;
			}
			if (obj.children) {
				obj.children.forEach(child => setExpandedForNode(child));
			}
		};
		setExpandedForNode(updatedTopicData);		
		updateData({ topicdata: updatedTopicData });
	};

	const toggleAllNodes = (state) => {
		const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
		const setExpandedForAllChildren = (obj) => {
			if (obj.children) {
				obj.expanded = state;
				obj.children.forEach(child => setExpandedForAllChildren(child));
			}
		}		
		setExpandedForAllChildren(updatedTopicData);
		updatedTopicData.expanded = true;
		updateData({ topicdata: updatedTopicData });
		setIsTreeExpanded(state);
	};
	
	// Remove item
	
	const removeItem = (itemName, parentNode = data.topicdata) => {
		const updatedTopicData = JSON.parse(JSON.stringify(parentNode));
		const removeItemRecursive = (itemName, node) => {
			node.children = node.children.filter(child => child.name !== itemName);
			node.children.forEach(child => {
				if (child.children) {
					removeItemRecursive(itemName, child);
				}
			});
		};
		removeItemRecursive(itemName, updatedTopicData);
		updateData({ topicdata: updatedTopicData });
	};
	
	// Context menu for tree items
	
	const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

	const handleContextMenu = (event, topic) => {
		event.preventDefault();
		event.stopPropagation();
		setIsContextMenuVisible(topic);
	};
	
	const hideContextMenu = (event) => {
		setIsContextMenuVisible(false);
	};
	
	const handleContextMenuClick = (event) => {
		hideContextMenu();
	};

	const handleContextMenuEscape = (event) => {
		if (event.key === "Escape") {
			hideContextMenu();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleContextMenuClick);
		document.addEventListener('keyup', handleContextMenuEscape);
		return () => {
			document.removeEventListener('click', handleContextMenuClick);
			document.removeEventListener('keyup', handleContextMenuEscape);
		};
	}, []);
	
	const renderContextMenu = (label, content, depth) => {		
		if ( isContextMenuVisible === label ) {
			return (
				<div className="context-menu context-menu-tree">
					<div className="context-menu-pin">
					</div>
					<div className="context-menu-wrap">
						<div className="context-menu-items">
							<div className="context-menu-item" onClick={(e) => { e.stopPropagation(); handleTopicClick(label, content); }}>{content ? "Open content" : "Create content"}</div>
							{depth > 0 && (
							<div className="context-menu-item" onClick={(e) => { e.stopPropagation(); handleContentRemove(label, content); }}>Remove topic</div>
							)}
						</div>
						<div className="context-menu-close" onClick={(e) => { e.stopPropagation(); hideContextMenu(e); }}>
							✕
						</div>
					</div>
				</div>
			);
		}
	};
	
	// Create or open associated content
	
	const handleTopicClick = async (label, content, id = topicid, pid = user.projectid) => {
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'topicclick',
			loc: window.location.pathname,
		});
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		let created = new Date().toISOString();
		let fetchid = content;
		try {
			if ( ! fetchid ) {
                const response = await protectedFetch('/api/content', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
						userID: userid,
						projectID: pid,
						topicID: id,
						heading: { topic: label }
                    })
                });
                const responsedata = await response.json();
				fetchid = responsedata._id;
				created = responsedata.created;
            }
			const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
			const updateNodeContent = (node) => {
				if (node.name === label) {
					node.content = fetchid;
					node.created = created;
					if ( node.progress && node.progress === 'idea' ) {
						node.progress = 'draft';
					}
					if ( !node.notes || node.notes === '' ) {
						node.notes = '';
					}
				} else if (node.children) {
					node.children.forEach(updateNodeContent);
				}
			};				
			updateNodeContent(newTopicData);
			updateData({ topicdata: newTopicData });
			setContentID(fetchid);
			gotourl(`/app/write`);
		} catch (error) {
            console.error('Error saving topic tree: ', error);
        }
	};
	
	// Delete node and associated content
	
	const handleContentRemove = async (item, content, parentNode = data.topicdata) => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		const isConfirmed = window.confirm("(╭ರ_•́)  Are you absolutely positive?");
		if (isConfirmed) {
			try {
				if ( content ) {
					const response = await protectedFetch(`/api/content/${content}`, {
						method: 'DELETE',
					});
				}
				removeItem(item);
				setContentID(null);
			} catch (error) {
				console.error('Error deleting content: ', error);
			}	
		} else {
			return;
		}
	};
	
	// Brainstorm subtopics
	
	const fetchSubtopics = async (topic, adding) => {
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'getsubtopics',
			loc: window.location.pathname,
		});
		let number = 10;
		if ( adding ) {
			number = 3;
		}
		let subtopicstring = 'Subtopic search keywords';
		if ( !user.seomode ) {
			subtopicstring = 'Short subtopics for a content campaign';
		}
		const existing = Array.from(data.uniquetopics).join(', ');
		const input = `Parent topic: "${topic}". ${subtopicstring} (max ${number}, the following already exist: ${existing}):`;
		try {
			const response = await apiOpenAI(
				input,
				0.6,
				300
			);
			const subItemsArray = response.split(/[\n,]+/).map((item) => 
				item.replace(/^\d+(\.\s*|\(\)\s*|\s+)/, "") /* numbering */
					.replace(/["'.]/g, "") /* all quotes */
					.replace(/^(\s?and\s)/, "") /* conjunctive and */
					.replace(/-\s+/g, "") /* dash bullets */
					.trim()
					.toLowerCase()
				).filter(item => !data.uniquetopics.has(item));
			const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
			const updateTree = (node) => {
				if (node.name === topic) {
					if (adding) {
						node.children = node.children.concat(subItemsArray.map((subItem) => ({ name: subItem, added: new Date().toISOString() })));
					} else {
						node.children = subItemsArray.map((subItem) => ({ name: subItem, added: new Date().toISOString() }));
					}
					node.expanded = true;
				} else if (node.children) {
					node.children.forEach(updateTree);
				}
			};				
			updateTree(updatedTopicData);			
			updateData({ topicdata: updatedTopicData });
			setLoadingTopics('');
		} catch (error) {
			//// Add a proper user-side error message here!
			console.error("Failed to fetch subtopics: ", error);
			setLoadingTopics('');
			return;
		}
	};
	
	// Add user-defined subtopic

	const appendSubtopic = (parentTopic, subTopicName) => {
		subTopicName = subTopicName.toLowerCase();
		const appendSubtopicToTree = (node) => {
			if (node.name === parentTopic) {
				if (node.children) {
					node.children.push({ name: subTopicName, added: new Date().toISOString(), });
				} else {
					node.children = [{ name: subTopicName, added: new Date().toISOString(), }];
				}
			} else if (node.children) {
				node.children.forEach(appendSubtopicToTree);
			}
		};
		if (data.uniquetopics.has(subTopicName)) {
			alert("（；¬＿¬)  this subtopic already exists. Let's try another one?");
			return;
		}
		const updatedTopicData = JSON.parse(JSON.stringify(data.topicdata));
		appendSubtopicToTree(updatedTopicData);
		updateData({ topicdata: updatedTopicData });
	};
	
	// Remove all children
	
	const removeAllChildren = (nodeName, parentNode = data.topicdata) => {
		if (parentNode.name === nodeName) {
			parentNode.children = [];
			return;
		}
		if (parentNode.children) {
			parentNode.children.forEach(child => {
				removeAllChildren(nodeName, child);
			});
		}
		updateData({ topicdata: parentNode });
	};
	
	// Add parent topic
	
	const addParentTopic = async (topic) => {
		let existing = Array.from(data.uniquetopics).join(', ');
		try {
			const response = await apiOpenAI(
				`Subtopic: "${topic}". Parent search keyword (do not use the following: ${existing}):`,
				0.6,
				300
			);
			const newParentTopic = 
				response.replace(/^\d+(\.\s*|\(\)\s*|\s+)/, "") // remove numbering
						.replace(/["'.]/g, "") // remove all quotes
						.replace(/^(\s?and\s)/, "") // remove the conjunctive "and"
						.replace(/-\s+/g, "") // remove dash bullets
						.trim()
						.toLowerCase();
			updateData({ topicdata: {
                name: newParentTopic,
				added: new Date().toISOString(),
                children: [data.topicdata]
            }});
			setLoadingTopics('');
		} catch (error) {
			console.error("Failed to generate a new parent topic: ", error);
		}
	};
	
	// Construct topic tree

	const Tree = () => {
		return (
			<div className="topictree">
				<TreeItem 
					key={data.topicdata.name} 
					label={data.topicdata.name}
					children={data.topicdata.children}
					volume={data.topicdata.volume}
					difficulty={data.topicdata.difficulty}
					depth={0}
					content={data.topicdata.content} 
					isFirstItem={true}
					isLastItem={false}
					isFirstChild={true}
					isLastChild={true} 
					prefix=""
					separatorTop=""
					separatorBottom=""
					toggleNode={toggleNode} 
					isExpanded={true}
					fetchSubtopics={fetchSubtopics}
					getSEOSubtopics={getSEOSubtopics}
					removeItem={removeItem}
					appendSubtopic={appendSubtopic}
					removeAllChildren={removeAllChildren}
					addParentTopic={addParentTopic}
					isAddSubtopicNode={false}
					maxLength={0}
					maxVolLength={0}
					user={user}
					isauth={isauth}
					getSEOData={getSEOData}	
					renderContextMenu={renderContextMenu}		
					handleContextMenu={handleContextMenu}
					isContextMenuVisible={isContextMenuVisible}
					setModalSignup={setModalSignup}
					setModalUpgrade={setModalUpgrade}
					loadingTopics={loadingTopics}
					setLoadingTopics={setLoadingTopics}
					loadingSEOData={loadingSEOData}
					setLoadingSEOData={setLoadingSEOData}
					keywordsEmpty={keywordsEmpty}
					setKeywordsEmpty={setKeywordsEmpty}
					keywordsEmpty={keywordsEmpty}
					setKeywordsEmpty={setKeywordsEmpty}
				/>
			</div>
		);
	};
						
	return (
		<div className="topics-wrap">
			
			{data.isloading ? (
				<div className="loader">...growing topic tree <span className="loading-indicator"></span></div>
			) : (
				data.topicdata ? (
					<div className="topics-inner">
						<div className="workspace-nav">
						
							<div className="workspace-nav-item topics-nav-expand">
								<div className="link-inline">
									<Expand className="icon icon-expand" />
									<a onClick={() => toggleAllNodes(true)} className={`noselect ${isTreeExpanded ? 'link-inactive' : ''}`}>
										Expand All
									</a>
								</div>
							</div>
							
							<div className="workspace-nav-item topics-nav-collapse">
								<div className="link-inline">
									<Collapse className="icon icon-collapse" />
									<a onClick={() => toggleAllNodes(false)} className={`noselect ${!isTreeExpanded ? 'link-inactive' : ''}`}>
										Collapse All
									</a>
								</div>
							</div>
								
							<div className="workspace-nav-item topics-nav-undo">	
								<div className="link-inline">
									<Undo className="icon icon-undo" />
									<a onClick={undo} className="noselect">
										Undo
									</a>
								</div>
							</div>
							
							<div className="workspace-nav-item topics-nav-redo">	
								<div className="link-inline">
									<Redo className="icon icon-redo" />
									<a onClick={redo} className="noselect">
										Redo
									</a>
								</div>
							</div>
							
							<div className="workspace-nav-item workspace-nav-item-primary topics-nav-plan">	
								<div className="link-inline">
									<Logomark className="icon icon-toplan" />	
									<Link to="/app/plan">To content plan</Link>								
								</div>
							</div>
							
						</div>
						<Tree />
					</div>
				) : (
					<div className="view-empty-placeholder">
						Just start typing above to create a topic tree   <span className="textface">(ﾉ◕ヮ◕)ﾉ</span><br />
						...or switch to <Link to="/app/dashboard">another project</Link> to view its topics. 
					</div>
				)
			)}
			
		</div>
	);
	
}

// Display topic history

const TopicHistory = React.memo(function({}) {
	
	const { user, topicid, setTopicID, isdemo, setModalSignup, topichistory, setTopicHistory } = useUserContext();
	const { updateData } = useDataContext();
	const protectedFetch = useProtectedFetch();
	const gotourl = useNavigate();
		
	// Format date
	
	function historyDate(dateString) {
		const options = { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
		const date = new Date(dateString);
		return date.toLocaleString(undefined, options);
	}
		
	// Slide history panel
	
	const [isHistoryOpen, setIsHistoryOpen] = useState(true);
		
	const isArrayEmpty = arr => Array.isArray(arr) && arr.length === 0;
	
	// Fetch topic history
	
	const fetchLastTopics = (projectid, number=1) => {
		if ( !projectid || isdemo ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/topics/${projectid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok:', response);
				return Promise.reject('Network response was not ok');
			}
			return response.json();
		})
		.then(topics => {
			if (topics && topics.length > 0) {
				const sortedTopics = topics.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedTopics.slice(0, number);
				} else {
					return sortedTopics;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
			return null;
		});
	};
	
	// Set topic History
	
	const setHistory = async() => {
		try {
			let fetchedTopics;
			fetchedTopics = await fetchLastTopics(user.projectid, 5);
			if (fetchedTopics && fetchedTopics.length > 0) {
				if (!isEqual(fetchedTopics, topichistory)) {
					setTopicHistory(fetchedTopics);
				}
			}
		} catch (error) {
			console.error('Last topic fetch error:', error);
		}
	};
	
	useEffect(() => {	
		setHistory();
    }, [topicid]);
	
	// Click on topic from history
	
	const handleHistoryItemClick = (topic) => {
		if ( topic ) {
			updateData({ isloading: true });
			setTopicID(topic._id);
			gotourl(`/app/topics/${topic._id}`);
		}
    };
	
	if ( isArrayEmpty(topichistory) && !isdemo ) {
		return;
	}
	
	return (
		<div className={`history history-topics ${isHistoryOpen ? 'history-open' : 'history-closed'}`}>
			
			{!isdemo && (
			
				<div className="history-slide" onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
					<Slide className="icon icon-slide" />
					{isHistoryOpen ? ( 
						<span className="history-slide-desc">Collapse history</span>
					) : (
						<History className="icon icon-history" />
					)}
				</div>
			
			)}
			
			{(isHistoryOpen || isdemo) && (
			
				<div className="history-list-wrap">
				
					{isArrayEmpty(topichistory) ? (
						
						<div className="history-empty">
							<a onClick={() => { setModalSignup(true); }} className="history-empty-link">Create an account</a> to save and display your topic history
						</div>
						
					) : (
					
						<ul className="history-list">
						
							{topichistory.map((topic, index) => (
							
								<li className="history-item" key={index} onClick={() => handleHistoryItemClick(topic)}>
									
									<div className="history-item-icon">
										<History className="icon icon-history" />
									</div>
									
									<div className="history-item-inner">
										<div className="history-item-name">
											{topic.topictree && topic.topictree.name}
										</div>
										<div className="history-item-updated">
											{historyDate(topic.updated)}
										</div>
									</div>
									
								</li>
								
							))}
							
						</ul>
					
					)}
				
				</div>
			
			)}
			
        </div>
    );
	
});

// Topics component for the Main view

function Topics() {
	
	// Build the view
	
	return (
		<div className="view view-topics">
			<TopicTree />
			<TopicHistory />
		</div>
	);
	
}
//Topics.whyDidYouRender = true

export default Topics;
