import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDataContext } from '../contexts/data';
import { useUserContext } from '../contexts/user';
import { useProtectedFetch } from './fetch';
import { useDropdownOutsideClick } from './dropdowns';
import { Modal } from './modal';
import { useCredits } from './credits';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import Delta from 'quill-delta';
import QuillToolbar, { modules, formats } from "./quill.js";
import 'react-quill/dist/quill.snow.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import isEqual from 'lodash/isEqual';
import apiOpenAI from '../apis/openai';
import { useGA4React } from '../apis/googleanalytics';

import { ReactComponent as Article } from '../assets/icon-article.svg';
import { ReactComponent as Product } from '../assets/icon-product.svg';
import { ReactComponent as Social } from '../assets/icon-social.svg';
import { ReactComponent as Video } from '../assets/icon-video.svg';
import { ReactComponent as Podcast } from '../assets/icon-podcast.svg';
import { ReactComponent as Email } from '../assets/icon-email.svg';
import { ReactComponent as History } from '../assets/icon-history.svg';
import { ReactComponent as Slide } from '../assets/icon-slide.svg';
import { ReactComponent as Choose } from '../assets/icon-choose.svg';
import { ReactComponent as Logomark } from '../assets/logomark.svg';
import { ReactComponent as Voice } from '../assets/icon-voice.svg';
import { ReactComponent as Template } from '../assets/icon-template.svg';
import { ReactComponent as Export } from '../assets/icon-export.svg';
import { ReactComponent as Delete } from '../assets/icon-delete.svg';
import iconai from '../assets/icon-ai.svg';
import modalwriting from '../assets/modal-writing.gif';

// Obtain content parameters from topic tree

const getContentParams = (obj, id) => {
	if (obj.content === id) {
		const { children, expanded, ...rest } = obj;
		return rest;
	}
	if (obj.children) {
		for (const child of obj.children) {
			const result = getContentParams(child, id);
			if (result) return result;
		}
	}
	return null;
}

// Update URL

function updateURL(slug) {
	let currentURL = window.location.pathname;
	if (currentURL.endsWith('/')) {
		currentURL = currentURL.slice(0, -1);
	}
	if (currentURL === '/app/write') {
		const newURL = `${currentURL}/${slug}`;
		window.history.pushState({}, '', newURL);
	}
}

// Format past datetime 

function historyDate(dateString) {
	const options = { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
	const date = new Date(dateString);
	return date.toLocaleString(undefined, options);
}

// Component to render the content plan table

const Writing = ({
	userid,
	user,
	updateUser,
	data,
	updateData,
	topicid,
	setTopicID,
	gotourl,
	contentid,
	setContentID,
	currentcontent,
	setCurrentContent,
	loadingcontent,
	setLoadingContent,
	titleRef,
	newTitleRef,
	adjustTitleareaHeight,
	accesstoken,
	isauth,
	setModalUpgrade,
}) => {
	
	// Initialize states
	
	const { id } = useParams();
	const { hasEnoughCredits, deductCredits } = useCredits();
	const { trackEvent } = useGA4React();
	const isInitialRender = useRef(true);
	const [lasttopic, setLastTopic] = useState(null);
	const [contentparams, setContentParams] = useState(null);
	const [treeparent, setTreeParent] = useState('');
	const [navigateUrl, setNavigateUrl] = useState(null);
	const [previd, setPrevID] = useState(null);
	const protectedFetch = useProtectedFetch();
	
	// Say hello
	
	const WritingModal = () => {
		return (
			<div className="modal-writing-inner">
				<h1>Your writing sidekick</h1>
				<div className="modal-text">
					<span>It's like having a secret <em>superpower</em>: choose tone of voice, brainstorm titles, then draft content with ease - and remember, you can always <strong>right-click</strong> anywhere in the content area for more AI goodness ~</span>
				</div>
				<div className="modal-action">
					<div className="userbutton" type="button" onClick={() => updateUser({ modalwriting: false })}>
						Let's take it for a spin!
					</div>
				</div>
			</div>
		);
	};
	
	// Navigate to url
	
	useEffect(() => {
		if (navigateUrl) {
			gotourl(navigateUrl);
		}
	}, [navigateUrl, gotourl]);
	
	// Get content data by ID
		
	const fetchContent = (contentid) => {
		if ( !contentid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/content/${contentid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while fetching content:', response);
				setContentID(null);
				setNavigateUrl(`/app/plan`);
			}
			return response.json();
		})
		.then(fetchedcontent => {
			if (fetchedcontent) {
				return fetchedcontent;
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Content fetch error:', error);
		});
	};
	
	// Get content by Topic ID

	const fetchLastContents = (topicid, number=1) => {
		if ( !topicid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/contents/${topicid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok when fetching contents:', response);
				return Promise.reject('Network response was not ok when fetching contents');
			}
			return response.json();
		})
		.then(contents => {
			if (contents && contents.length > 0) {
				const sortedContents = contents.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedContents.slice(0, number);
				} else {
					return sortedContents;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Contents fetch error:', error);
			return null;
		});
	};
	
	// Fetch topic by topic ID
	
	const fetchTopic = (topicid) => {
		if ( !topicid ) {
			return null;
		}
		return protectedFetch(`/api/topic/${topicid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok while fetching topic:', response);
				return Promise.reject('Network response was not ok while fetching topic');
			}
			return response.json();
		})
		.then(topic => {
			return topic;
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
		});
	};
	
	// Fetch topic history
	
	const fetchLastTopics = (projectid, number=1) => {
		if ( !projectid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/topics/${projectid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok:', response);
				return Promise.reject('Network response was not ok');
			}
			return response.json();
		})
		.then(topics => {
			if (topics && topics.length > 0) {
				const sortedTopics = topics.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedTopics.slice(0, number);
				} else {
					return sortedTopics;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Topic fetch error:', error);
			return null;
		});
	};
	
	// Load content, topic, and history
	
	const getContentData = async () => {
		
		if ( !user.projectid ) {
			return;
		}		
		let newCID = contentid;
		let newTID = topicid;
		let topicToUse;
		
		// Determine which content ID to use
		
		if ( id && id !== contentid && id !== previd ) {
			newCID = id;
			setContentID(newCID);
		} else {
			if ( contentid ) {
				newCID = contentid;
				updateURL(newCID);
				if ( topicid ) {
					newTID = topicid;
				}
			} else {
				if ( topicid ) {
					newTID = topicid;
				} else {
					try {
						const fetchedTopics = await fetchLastTopics(user.projectid);
						if (fetchedTopics && fetchedTopics.length > 0 && fetchedTopics[0]._id) {
							setTopicID(fetchedTopics[0]._id);
							newTID = fetchedTopics[0]._id;
							topicToUse = fetchedTopics[0];
						} else {
							console.log('No topic to load');
							setLoadingContent(false);
							return;
						}
					} catch (error) {
						console.error('Last topic fetch error:', error);
					}
				}
				const fetchedContents = await fetchLastContents(newTID, 5);
				if (fetchedContents && fetchedContents.length > 0) {
					if ( fetchedContents[0] && fetchedContents[0]._id ) {
						newCID = fetchedContents[0]._id;
						setContentID(newCID);
						updateURL(newCID);
					}
				} else {
					console.log('Ouch, could not load topic!');
					setLoadingContent(false);
				}
			}
		}
		if ( !newCID ) {
			console.log('Could not get content ID, loading blank state');
			setLoadingContent(false);
		} else {
			setPrevID(newCID);
		}
		// We have the right content ID (newCID) by now. Let's fetch the content:
		
		if ( !currentcontent && newCID ) {
			try {
				const fetchedContent = await fetchContent(newCID);
				if (fetchedContent) {
					setCurrentContent(fetchedContent);
					if ( !newTID ) {
						newTID = fetchedContent.topicID;
					}
				} else {
					console.error('Ouch, could not load content!');
					setLoadingContent(false);
				}
			} catch (error) {
				console.error('Topic fetch error:', error);
				setLoadingContent(false);
			}
		}
		setTopicID(newTID);
		// Now we definitely have topic ID (newTID). Let's make sure we've fetched the respective topic:
		
		if ( !topicToUse && newTID ) {
			try {
				const fetchedTopic = await fetchTopic(newTID);
				if (fetchedTopic && fetchedTopic.topictree) {
					topicToUse = fetchedTopic;
				} else {
					console.error('Ouch, could not load topic!');
					setLoadingContent(false);
					return;
				}
			} catch (error) {
				console.error('Topic fetch error:', error);
				setLoadingContent(false);
			}
		}
		// Now we have the topic, let's set the content parameters:
		
		if ( user.projectid && topicToUse.projectID && user.projectid !== topicToUse.projectID ) {
			updateUser({ projectid: topicToUse.projectID });
		}
		if ( topicToUse && newCID ) {
			const newParams = getContentParams(topicToUse.topictree, newCID);
			setContentParams(newParams);
			setNoteContent(newParams && newParams.notes ? newParams.notes : notePlaceholder);
		}
		if ( topicToUse && !data.topicdata ) {
			updateData({ topicdata: topicToUse.topictree });
		}
		
	};
	
	useEffect(() => {		
		getContentData();
    }, [user.projectid, topicid, id]);
	
	// Update content data
	
	const saveContentToDatabase = async (contentdata) => {
		try {
			if (contentid) {
                const response = await protectedFetch(`/api/content/${contentid}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(contentdata)
                });
                const responsedata = await response.json();
			}
		} catch (error) {
            console.log('Error saving topic tree: ', error);
        }
    };
		
	// Traverse and update the topic tree object directly

	const updateTopicData = (currentNode, targetName, field, newValue) => {
		if (currentNode.name === targetName) {
			currentNode[field] = newValue;
		} else if (currentNode.children) {
			currentNode.children.forEach(child => updateTopicData(child, targetName, field, newValue));
		}
	}
	
	// Delete a topic tree property
	
	const deleteTopicData = (currentNode, targetName, field) => {
		if (currentNode.name === targetName) {
			delete currentNode[field];
		} else if (currentNode.children) {
			currentNode.children.forEach(child => deleteTopicData(child, targetName, field));
		}
	};
	
	// Update content parameters
	
	useEffect(() => {
		if ( data.topicdata && contentparams ) {
			let newparams = getContentParams(data.topicdata, contentid);
			let paramstoupdate = {...contentparams};
			for (let key in paramstoupdate) {
				if (newparams && newparams.hasOwnProperty(key) && newparams[key] !== paramstoupdate[key]) {
					paramstoupdate[key] = newparams[key];
				}
			}
			setContentParams(paramstoupdate);
		}
	}, [data.topicdata]);	
	
	// Scales for writing parameters
	
	function WritingScale({ id, labels, position, onPointClick, onHandleDrag, onDragEnd }) {
		
		const scaleRef = useRef(null);
		const positionRef = useRef(position);
		const isCurrentlyDragging = useRef(false);	
		
		const handleMouseDown = (e) => {
			e.preventDefault();
			isCurrentlyDragging.current = true;
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		};
		
		const handleMouseMove = (e) => {
			if (!isCurrentlyDragging.current || !scaleRef.current) return;
			const rect = scaleRef.current.getBoundingClientRect();
			const dragPercentage = (e.clientY - rect.top) / rect.height;
			let newPosition;
			if (dragPercentage < 1/3) {
				newPosition = 0;
			} else if (dragPercentage < 2/3) {
				newPosition = 0.5;
			} else {
				newPosition = 1;
			}
			positionRef.current = newPosition;
		};
		
		const handleMouseUp = () => {
			isCurrentlyDragging.current = false;
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
			onDragEnd(id, positionRef.current);
		};

		return (
			<div ref={scaleRef} className="writing-scale-container">
				<div className="writing-scale-row-heading">
					{id.charAt(0).toUpperCase() + id.slice(1)}
				</div>
				{labels.map((label, index) => (
					<div key={label} className={`writing-scale-row writing-scale-row-${index}`} onClick={() => onPointClick(id, index)} >
						<div className="writing-scale-point">[
						{(positionRef.current * 2) === index ? (
							<span className="writing-scale-handle" onMouseDown={handleMouseDown}>
								{'\u25fc'}
							</span>
						) : (
							<span className="writing-scale-empty">{'\u25FB'}</span>
						)}
						]</div>
						<div key={label} className={`writing-scale-label`} >
							{label}
						</div>
					</div>
				))}
			</div>
		);
	}
	
	const [writingscales, setWritingScales] = useState([
		{ id: 'length', labels: ['Short', 'Medium', 'Long'], position: 0.5 },
		{ id: 'formality', labels: ['Informal', 'Neutral', 'Formal'], position: 0.5 },
		{ id: 'complexity', labels: ['Simple', 'Neutral', 'Advanced'], position: 0.5 },
		{ id: 'emotion', labels: ['Emotional', 'Neutral', 'Analytical'], position: 0.5 }
	]);
	
	const handlePointClick = (id, index) => {
        const updatedScales = writingscales.map(scale => {
            if (scale.id === id) {
                return { ...scale, position: index / 2 };
            }
            return scale;
        });
        setWritingScales(updatedScales);
    };

    const handleDragEnd = (id, finalPosition) => {
        const updatedScales = writingscales.map(scale => {
            if (scale.id === id) {
                return { ...scale, position: finalPosition };
            }
            return scale;
        });
        setWritingScales(updatedScales);
    };
	
	const getWritingSettings = () => {
		const settings = {};
		writingscales.forEach(scale => {
			if (scale.position === 0) settings[scale.id] = scale.labels[0];
			else if (scale.position === 0.5) settings[scale.id] = scale.labels[1];
			else if (scale.position === 1) settings[scale.id] = scale.labels[2];
		});
		return settings;
	};
	
	const [showvoice, setShowVoice] = useState(user.showvoice);
	
	const toggleScales = (e) => {
		e.preventDefault();
		const newShowVoice = !showvoice;
		setShowVoice(newShowVoice);
	}
	
	// Templates
	
	const [currentTemplate, setCurrentTemplate] = useState("post");
	const [temporaryTemplate, setTemporaryTemplate] = useState("post");
	const contentTemplates = ["post", "listicle", "tips", "how-to", "case study", "ultimate guide", "comparison", "product review", "product alternatives", "interview", "opinion piece"];
	
	const toggleDropdownTemplates = () => {
		setShowDropdownTemplates(!showDropdownTemplates);
	};
	
	const handleTemplatesChange = (template) => {
		setTemporaryTemplate(template);
	};
	
	const applyTemplateChanges = () => {
		setCurrentTemplate(temporaryTemplate);
		setShowDropdownTemplates(false);
	};
	
	const { 
		showDropdown: showDropdownTemplates, 
		setShowDropdown: setShowDropdownTemplates, 
		dropdownRef: dropdownTemplatesRef 
	} = useDropdownOutsideClick(false);
	
	// Export Content
	
	const getHTMLContent = () => {
		if (quillRef.current) {
			return quillRef.current.editor.root.innerHTML;
		}
		return '';
	}
	
	const exportContent = () => {
		let exportTitle = '';
		if ( currentcontent.heading.title && currentcontent.heading.title !== '' ) {
			exportTitle = '<h1>'+currentcontent.heading.title+'</h1>';
		}
		const contentHtml = exportTitle + getHTMLContent();
		const converted = htmlDocx.asBlob(contentHtml, {
			orientation: 'portrait',
			margin: { top: 720 }
		});
		let docname = 'export.docx';
		if ( currentcontent.heading.topic && currentcontent.heading.topic !== '' ) {
			docname = currentcontent.heading.topic + '.docx';
		}
		saveAs(converted, docname);
	}
	
	// Delete content
		
	const deleteContent = async (topicName) => {
		if (window.confirm("《☞〝●︿●》☞  Are you sure?")) {
			if ( !contentid ) {
				console.log('No content ID found');
				return;
			}
            try {
				const response = await protectedFetch(`/api/content/${contentid}`, {
					method: 'DELETE',
				});
				console.log(response);
				const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
				updateTopicData(newTopicData, topicName, 'progress', 'idea');
				deleteTopicData(newTopicData, topicName, 'content');
				updateData({ topicdata: newTopicData });
				setContentID(null);
				setContentParams(null);	
				gotourl('/app/write/');
			} catch (error) {
				console.error('Error deleting content: ', error);
			}
        } else {
            
        }	
	};
		
	// Brainstorm headings
	
	const [loadingheadings, setLoadingHeadings] = useState(false);
	
	const brainstormTitles = async () => {
		
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalUpgrade(true);
			return;
		}
		
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'generatetitles',
			loc: window.location.pathname,
		});
		
		setLoadingHeadings(true);
		const writingparamsObj = getWritingSettings();
		const writingparams = Object.entries(writingparamsObj).map(([key, value]) => `${key}: ${value}`).join(', ');
		let template = '';
		if ( currentTemplate !== 'post' ) {
			template = " Content template: " + currentTemplate + ".";
		}
		let writinglength = 60;
		if ( writingparamsObj.length === 'Short' ) {
			writinglength = 30;
		} else if ( writingparamsObj.length === 'Long' ) {
			writinglength = 90;
		}
		const input = `Brainstorm 3 headings for an article based on the following topic/keyword: "${contentparams.key ? contentparams.key : currentcontent.heading.topic}". Desired tone: ${writingparams}.${template} Maximum length per heading: ${writinglength} letters.`;
		try {
			const response = await apiOpenAI(
				input,
				1,
				100
			);
			const headingIdeasObj = response.split(/\n+/).map((item) => 
				item.replace(/^\d+(\.\s*|\(\)\s*|\s+)/, "") // remove numbering
					.replace(/^\d+\)\s*/, '') // remove numbering
					.replace(/^-+\s*/, "") // remove dash bullets
					.replace(/^"|"$/g, '') // remove quotes
					.trim()
				);
			let updatedHeading;
			if ('title' in currentcontent.heading) {
				const { title, ...rest } = currentcontent.heading;
				updatedHeading = { ...rest, ideas: headingIdeasObj }; 
			} else {
				updatedHeading = { ...currentcontent.heading, ideas: headingIdeasObj };
			}
			const updatedContent = {
				...currentcontent,
				heading: updatedHeading
			};
			setCurrentContent(updatedContent);
			setLoadingHeadings(false);
			setIsTyping(true);
		} catch (error) {
			console.error("Failed to fetch title ideas: ", error);
			setLoadingHeadings(false);
		}		
	};
	
	// Display a string in increments
	
	const [isTyping, setIsTyping] = useState(false);
	const GradualOutput = ({ text, increment, interval }) => {
		const [output, setOutput] = useState('');
		const [currentIndex, setCurrentIndex] = useState(0);
		const [showCursor, setShowCursor] = useState(true);
		const defaultClass = 'typing-static';
		const [divClass, setDivClass] = useState(defaultClass);
		useEffect(() => {
			setDivClass('typing-active');
			const timer = setInterval(() => {
				if (currentIndex < text.length) {
					setCurrentIndex((prevIndex) => {
						const newIndex = Math.min(prevIndex + increment, text.length);
						setOutput(text.slice(0, newIndex));
						return newIndex;
					});
				} else {
					clearInterval(timer);
					setShowCursor(false);
					setDivClass(defaultClass);
					setIsTyping(false);
				}
			}, interval);
			return () => clearInterval(timer);
		}, [currentIndex, text, increment, interval]);
		return <div className={divClass}>{output}{showCursor && <span className="typing-cursor">{'\u2588'}</span>}</div>;
	};
	
	// Choose title
	
	const chooseTitle = (title) => {
		title.replace(/^["']|["']$/g, '');
		setCurrentContent({ 
			...currentcontent, 
			heading: { 
				...currentcontent.heading,
				title: title,
			},
		});
		if (titleRef.current) {
			titleRef.current.focus();
		}
	};
	
	// Title input field

	const [titleInputValue, setTitleInputValue] = useState('');
	const [titlePrevValue, setTitlePrevValue] = useState('');
	const [titlesave, setTitleSave] = useState('');
	
	useEffect(() => {
        adjustTitleareaHeight(titleRef.current);
    }, [titleRef.current]);

	const handleTitleChange = (e) => {
		setCurrentContent({ 
			...currentcontent, 
			heading: { 
				...currentcontent.heading,
				title: e.target.value,
			},
		});
	};

	const handleTitleSave = () => {
		setTitleSave('saving');
		saveContentToDatabase(currentcontent);
		if ( currentcontent.heading.title !== titlePrevValue || currentcontent.heading.title === '' ) {
			setTimeout(() => {
				setTitleSave('saved');
			}, 1000);
			setTimeout(() => {
				setTitleSave('');
			}, 2000);
		}
		setTitlePrevValue(currentcontent.heading.title);
	};

	const handleTitleBlur = () => {
		if ( currentcontent.heading.title !== titlePrevValue || currentcontent.heading.title === '' ) {
			handleTitleSave();
		}
	};

	const handleTitleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleTitleSave();
			titleRef.current.blur();
		}
	};

	// Content Area
	
	const [lastSavedContent, setLastSavedContent] = useState('');
	const quillRef = useRef(null);
	const [isQuillInitialized, setIsQuillInitialized] = useState(false);
	
	const handleQuillLoad = (instance) => {
		if (instance) {
			quillRef.current = instance;
			setIsQuillInitialized(true);
		}
	};
	
	const handleEditorChange = (content) => {
		if ( quillRef.current ) {
			const quillInstance = quillRef.current.getEditor();
			const quillcontent = quillInstance.root.innerHTML; 
			const quillhistory = {
				undo: quillInstance.history.stack.undo,
				redo: quillInstance.history.stack.redo
			};
			setCurrentContent({ 
				...currentcontent, 
				content: quillcontent, 
				history: quillhistory,
			});
		}
	};
	
	useEffect(() => {
        if (quillRef.current) {
            setIsQuillInitialized(true);
            const quillInstance = quillRef.current.getEditor();
			if ( currentcontent.content ) {
				quillInstance.root.innerHTML = currentcontent.content;
			}
        }
    }, [quillRef.current]);
	
	let contentToRender;
    if (isQuillInitialized) {
        const quillInstance = quillRef.current.getEditor();
        const quillHTML = quillInstance.root.innerHTML;
		const trimmedQuillHTML = quillHTML.trim();
		const emptyValues = ['', '<p><br></p>', '<h1><br></h1>', '<h2><br></h2>', '<h3><br></h3>'];
		if (emptyValues.includes(trimmedQuillHTML)) {
			contentToRender = (
				<div className="writing-text-ideas-description">
					<img className="writing-ai" src={iconai} alt="AI" /><span className="writing-link writing-text-ideas-generate" onClick={() => brainstormOutline()}>Brainstorm outline</span> or start editing your content below:
				</div>
			);
		} else {
			contentToRender = (
				<div className="writing-text-ideas-description">
					Right-click anywhere for AI-powered goodness. Remove all content to brainstorm outline again.
				</div>
			);
		}	
    }
	
	// Save text editor contents
	
	const [textsave, setTextSave] = useState('');
  
	const saveEditorContent = async () => {
		if (quillRef.current) {
			setTextSave('saving');
			saveContentToDatabase(currentcontent);			
			if ( currentcontent.content !== lastSavedContent ) {
				setTimeout(() => {
					setTextSave('saved');
				}, 1000);
			}
			setTimeout(() => {
				setTextSave('');
			}, 2000);
		}
	};
	
	// Save content every 30 seconds if it changes and adjust title textarea hight
	
	useEffect(() => {
		if ( !currentcontent ) {
			return;
		}
		adjustTitleareaHeight(titleRef.current);
		const autoSaveInterval = setInterval(async () => {
			if (currentcontent.content && currentcontent.content !== lastSavedContent) {
				await saveEditorContent();
				setLastSavedContent(currentcontent.content);
			}
		}, 30000);
		return () => clearInterval(autoSaveInterval);
	}, [currentcontent]);
	
	// Brainstorm content outline
		
	const [loadingtext, setLoadingText] = useState(false);

	const brainstormOutline = async () => {
		
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'generateoutline',
			loc: window.location.pathname,
		});
		
		setLoadingText(true);
		const writingparamsObj = getWritingSettings();
		const writingparams = Object.entries(writingparamsObj).map(([key, value]) => `${key}: ${value}`).join(', ');
		let template = '';
		if ( currentTemplate !== 'post' ) {
			template = " Content template: " + currentTemplate + ".";
		}
		const input = `Brainstorm an outline (top-level h2 subheadings only) for an article based on the following topic/title: ${currentcontent.heading.title ? currentcontent.heading.title : currentcontent.heading.topic }".${template} Desired tone: ${writingparams}.`;
		try {
			const response = await apiOpenAI(
				input,
				0.1,
				300
			);
			const outlineObj = response.split(/\n+/).map((item) => {
				item = item.replace(/^\d+(\.\s*|\(\)\s*|\s+)/, "") // remove numbering
					.replace(/^\d+\)\s*/, '') // remove numbering
					.replace(/^-+\s*/, "") // remove dash bullets
					.replace(/^"|"$/g, '') // remove quotes
					.replace(/^(I{1,3}|IV|V|VI{1,3}|IX|X|XI{1,3}|XIV|XV|XVI{1,3})\.\s*(.*)$/gm, '<h2>$2</h2><br>') // roman bullets into h2 tags
					.trim();
				return item;
			});
			const updatedText = outlineObj.join('');
			const updatedContent = {
				...currentcontent,
				content: updatedText
			};
			setCurrentContent(updatedContent);
			const quillInstance = quillRef.current.getEditor();
			quillInstance.root.innerHTML = updatedText;
			setLoadingText(false);
		} catch (error) {
			console.error("Failed to fetch outline: ", error);
			setLoadingText(false);
		}		
	};
	
	// Add context menus
	
	const [contextMenu, setContextMenu] = useState({
		visible: false,
		x: '0px',
		y: '0px'
	});
	const [currentSelection, setCurrentSelection] = useState(null);
	const [loadingAIContent, setLoadingAIContent] = useState(false);

	useEffect(() => {
		if (isQuillInitialized) {
			quillRef.current.getEditor().container.addEventListener('contextmenu', handleContextMenu);
			return () => {
				quillRef.current.getEditor().container.removeEventListener('contextmenu', handleContextMenu);
			};
        }
	}, [isQuillInitialized]);

	const handleContextMenu = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const selection = quillRef.current.getEditor().getSelection();
		if (selection) { 
			const bounds = quillRef.current.getEditor().getBounds(selection.index);
			setCurrentSelection(selection);
			showContextMenu(bounds.left, bounds.top);
		}
	};

	const showContextMenu = (x, y) => {
		const quillInstance = quillRef.current.getEditor();
        const trimmedQuillHTML = quillInstance.root.innerHTML.trim();
		const emptyValues = ['', '<p><br></p>', '<h1><br></h1>', '<h2><br></h2>', '<h3><br></h3>'];
		if (emptyValues.includes(trimmedQuillHTML)) {
			return;
		}
		const rect = quillInstance.container.getBoundingClientRect();		
		const offsetX = x;
		const offsetY = y + 20;
		setContextMenu({
			visible: true,
			x: `${offsetX}px`,
			y: `${offsetY}px`
		});
		setTimeout(() => {
			document.addEventListener('click', checkOutsideClick);
			document.addEventListener('contextmenu', checkOutsideClick);
		}, 10);
	};
	
	const checkOutsideClick = (event) => {
		const contextMenuElement = document.querySelector('.context-menu-writing');
		if (contextMenuElement && !contextMenuElement.contains(event.target)) {
			hideContextMenu();
			document.removeEventListener('click', checkOutsideClick);
			document.removeEventListener('contextmenu', checkOutsideClick);
			event.stopPropagation();
		}
	};

	const hideContextMenu = () => {
		setContextMenu({
			visible: false,
			x: '0px',
			y: '0px'
		});
	};
	
	const renderContextMenu = () => {		
		const { x, y, visible } = contextMenu;
		if (!visible) return null;
		const style = {
			top: y,
			left: x,
		};
		return (
			<div className="context-menu context-menu-writing" style={style}>
				<div className="context-menu-pin">
				</div>
				{loadingAIContent ? (
					<div className="loader">...brainstorming <span className="loading-indicator"></span></div>
				) : (
					<div className="context-menu-wrap">
						<img className="writing-ai" src={iconai} alt="AI" />
						<div className="context-menu-items">
							<div className="context-menu-item context-menu-item-writing-draft" onClick={(e) => { e.stopPropagation(); handleAIContext('draft'); }}>Draft</div>
							<div className="context-menu-item context-menu-item-writing-continue" onClick={(e) => { e.stopPropagation(); handleAIContext('cont'); }}>Continue</div>
							<div className="context-menu-item context-menu-item-writing-draft-summarize" onClick={(e) => { e.stopPropagation(); handleAIContext('sum'); }}>Summarize</div>
							<div className="context-menu-item context-menu-item-writing-paraphrase" onClick={(e) => { e.stopPropagation(); handleAIContext('para'); }}>Paraphrase</div>
						</div>
						<div className="context-menu-close" onClick={hideContextMenu}>
							✕
						</div>
					</div>
				)}
			</div>
		);		
	};
	
	function deltaToHtml(deltaOps) {
		const container = document.createElement('div');
		document.body.appendChild(container);
		const tempQuill = new Quill(container);
		tempQuill.setContents(deltaOps);
		const html = container.getElementsByClassName('ql-editor')[0].innerHTML;
		document.body.removeChild(container);
		return html;
	}
	
	const handleAIContext = async (command) => {
		
		trackEvent({
			category: 'retention',
			action: 'usage',
			label: 'generatetext',
			command: command,
			loc: window.location.pathname,
		});
		
		const actionCost = 1;
		if (hasEnoughCredits(actionCost)) {
			deductCredits(actionCost);
		} else {
			setModalUpgrade(true);
			return;
		}
		
		if (!currentSelection) return;
		setLoadingAIContent(true);
		const { index: selectionStart, length: selectionLength } = currentSelection;
				
		const beforeDelta = quillRef.current.getEditor().getContents(0, selectionStart);
		const selectedDelta = quillRef.current.getEditor().getContents(selectionStart, selectionLength);
		const afterDelta = quillRef.current.getEditor().getContents(selectionStart + selectionLength);
		
		const restrictDeltaToLength = (delta, limit) => {
			let length = 0;
			let restrictedOps = [];
			for (let op of delta.ops) {
				if (length >= limit) break;
				if (op.insert && (length + op.insert.length) <= limit) {
					length += op.insert.length;
					restrictedOps.push(op);
				} else if (op.insert) { 
					const remaining = limit - length;
					const partialOp = { 
						insert: op.insert.substring(0, remaining) 
					};
					if (op.attributes) {
						partialOp.attributes = { ...op.attributes };
					}
					restrictedOps.push(partialOp);
					break;
				} else if (op.retain || op.delete) {
					restrictedOps.push(op);
				}
			}
			return { ops: restrictedOps };
		};
		
		function extractHeadingsFromHtml(htmlContent, selectionStart) {
			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlContent, 'text/html');
			const headings = Array.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6')).map(el => el.textContent);
			let closestHeading = null;
			let currentPosition = 0;
			Array.from(doc.body.childNodes).forEach(node => {
				if (node.nodeType === Node.TEXT_NODE) {
					currentPosition += node.textContent.length;
				} else if (node.nodeType === Node.ELEMENT_NODE && headings.includes(node.textContent)) {
					if (currentPosition < selectionStart) {
						closestHeading = node.textContent;
					}
					currentPosition += node.outerHTML.length;
				} else {
					currentPosition += node.outerHTML.length;
				}
			});
			const outlineString = headings.join('\n');
			return {
				outline: outlineString,
				closestToSelection: closestHeading
			};
		}
		
		const restrictedBeforeDelta = restrictDeltaToLength(beforeDelta, 2000);
		const restrictedSelectedDelta = restrictDeltaToLength(selectedDelta, 2000);
		const restrictedAfterDelta = restrictDeltaToLength(afterDelta, 2000);
		
		const fullDelta = quillRef.current.getEditor().getContents();
		const deltaToSend = (restrictedSelectedDelta.ops && restrictedSelectedDelta.ops.length > 0) ? restrictedSelectedDelta : restrictedBeforeDelta;
		const stringToSend = deltaToSend.ops.map(op => { if (typeof op.insert === 'string') { return op.insert; } return ''; }).join('');
		
		let input = '';
		const writingparamsObj = getWritingSettings();
		let writingparams;
		if (writingparamsObj.length === 'Medium' && writingparamsObj.formality === 'Neutral' && writingparamsObj.complexity === 'Neutral' && writingparamsObj.emotion === 'Neutral') {
			writingparams = '';
		} else {
			writingparams = ' Desired tone: ' + Object.entries(writingparamsObj).map(([key, value]) => `${key}: ${value}`).join(', ') + '.';
		}
		
		switch (command) {
			case "draft":
				const htmlContent = deltaToHtml(fullDelta.ops);
				const { outline, closestToSelection } = extractHeadingsFromHtml(htmlContent, selectionStart);
				if (!closestToSelection || closestToSelection === "") {
					input = `Create a short draft for content based on this title: ${currentcontent.heading.title ? currentcontent.heading.title : currentcontent.heading.topic }.${writingparams} Do not output the title, only content.`;
				} else {
					input = `Create a short draft - maximum 3 paragraphs - of the "${closestToSelection}" part from the following content outline: ${outline}. Output only the text, do not output the heading.${writingparams}`;
				}			
				break;
			case "cont":
				input = `Continue the below text with precisely 2 paragrahs. Do not conclude the post, just extend the existing content.${writingparams} Text: "${stringToSend}"`;
				break;
			case "sum": 
				input = `Summarize the text below.${writingparams} Text: "${stringToSend}"`;
				break;
			case "para":
				input = `Paraphrase the text below.${writingparams} Text: ${stringToSend}`;
				break;
		}
		console.log(input);
		try {
			const response = await apiOpenAI(
				input,
				1,
				500
			);
			const generatedText = response.replace(/^["']|["']$/g, '');
			const apiResponseDelta = new Delta().insert('\n').insert(generatedText).insert('\n');
			const newDeltaOps = [].concat(beforeDelta.ops, selectedDelta.ops, apiResponseDelta.ops, afterDelta.ops);
			const updatedText = deltaToHtml(newDeltaOps);
			const updatedContent = {
				...currentcontent,
				content: updatedText
			};
			setCurrentContent(updatedContent);
			setLoadingAIContent(false);
			const quillInstance = quillRef.current.getEditor();
			quillInstance.root.innerHTML = updatedText;
			hideContextMenu();
			quillRef.current.getEditor().focus();
		} catch (error) {
			console.error("Failed to generate content: ", error);
			hideContextMenu();
			quillRef.current.getEditor().focus();
		}		
	};
	
	// Choose item progress
	
	const progressStates = ["idea", "draft", "reviewing", "published"];
	
	const toggleDropdownProgress = () => {
		setShowDropdownProgress(!showDropdownProgress);
	};
	
	const handleProgressChange = (itemName, newState) => {
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		if ( newState === 'published' ) {
			const dateNow = format(new Date(), "MMM d, yyyy");
			updateTopicData(newTopicData, itemName, 'published', dateNow);
		} else {
			updateTopicData(newTopicData, itemName, 'published', '');
		}
		updateTopicData(newTopicData, itemName, 'progress', newState);
		updateData({ topicdata: newTopicData });
		setShowDropdownProgress(false);
	};
	
	const { 
		showDropdown: showDropdownProgress, 
		setShowDropdown: setShowDropdownProgress, 
		dropdownRef: dropdownProgressRef 
	} = useDropdownOutsideClick(false);
	
	// Choose item format
	
	const formatIcons = {
		Article,
		Social,
		Product,
		Video,
		Podcast,
		Email,
	}
	const formatStates = Object.keys(formatIcons).map(key => key.toLowerCase());
	formatStates.push("clear");
	
	const FormatIcon = ({ iconName }) => {
		iconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
		const Icon = formatIcons[iconName];
		return <Icon />;
	}
	
	const toggleDropdownFormat = () => {
		setShowDropdownFormat(!showDropdownFormat);
	};
	
	const handleFormatChange = (itemName, newState) => {
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		if ( newState === 'clear' ) {
			updateTopicData(newTopicData, itemName, 'format', '');
		} else {
			updateTopicData(newTopicData, itemName, 'format', newState);
		}
		updateData({ topicdata: newTopicData });
		setShowDropdownFormat(false);
	};
	
	const { 
		showDropdown: showDropdownFormat, 
		setShowDropdown: setShowDropdownFormat, 
		dropdownRef: dropdownFormatRef 
	} = useDropdownOutsideClick(false);
	
	// Set deadlines
	
	const handleDeadlineChange = (date, targetName) => {
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		updateTopicData(newTopicData, targetName, 'deadline', date ? format(date, "MMM d, yyyy") : null);
		updateData({ topicdata: newTopicData });
	};
	
	// Add notes
	
	const notePlaceholder = 'add notes';
	const notePlaceholderColor = '#0098C4';
	const [isNoteEditing, setIsNoteEditing] = useState(false);
	const [notecontent, setNoteContent] = useState(notePlaceholder);
	const noteRef = useRef(null);

	const handleNoteEdit = () => {
		if (notecontent === notePlaceholder) {
			setNoteContent('');
		}
		setIsNoteEditing(true);
	};

	const handleNoteBlur = (targetName) => (e) => {
		const currentText = e.currentTarget.innerText;
		if (!currentText.trim()) {
			setNoteContent(notePlaceholder);
		} else {
			setNoteContent(currentText);
		}
		const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
		updateTopicData(newTopicData, targetName, 'notes', currentText.trim());
		updateData({ topicdata: newTopicData });
		setIsNoteEditing(false);
	};
	
	const handleNoteKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			setNoteContent(event.currentTarget.innerText);
			event.target.blur();
		}
	}
	
	useEffect(() => {
		if (isNoteEditing && noteRef.current) {
			noteRef.current.textContent = notecontent;
			noteRef.current.focus();
		}
	}, [isNoteEditing]);
	
	// Create content in empty state
	
	const [showcreate, setShowCreate] = useState(false);
	const [newtitle, setNewTitle] = useState('');
	
	const handleNewTitleChange = (event) => {
		setNewTitle(event.target.value);
	};
	
	const createNewContent = async () => {
		
		//// Add custom error messages for these cases
		if ( newtitle === '' ) {
			return;
		}
		if ( !topicid || !userid || !data.topicdata ) {
			return;
		}
		
		function countWords(text) {
			const words = text.match(/\w+/g);
			return words ? words.length : 0;
		}
		
		let newcontenttopic = newtitle.toLowerCase();
		
		if ( countWords(newtitle) > 4 ) {
			const input = `Turn this title into a short topic/keyphrase (maximum length: 4 words): "${newtitle}"`;
			try {
				const response = await apiOpenAI(
					input,
					1,
					100
				);
				if (typeof response === 'string') { 
					const generatedText = response.replace(/^["']|["']$|\.$/g, '');
					if ( countWords(generatedText) < 5 ) {
						newcontenttopic = generatedText.toLowerCase();
					}
				}
			} catch (error) {
				console.error("Failed to fetch title ideas: ", error);
				setLoadingHeadings(false);
			}
			
		}
			
		let fetchid = null;
		let created = new Date().toISOString();
		
		try {
			const response = await protectedFetch('/api/content', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					userID: userid,
					topicID: topicid,
					heading: { topic: newcontenttopic, title: newtitle }
				})
			});
			const responsedata = await response.json();
			console.log(responsedata);
			if ( responsedata && responsedata._id ) {
				console.log('creating content');
				fetchid = responsedata._id;
				created = responsedata.created;	
				
				const newTopicData = JSON.parse(JSON.stringify(data.topicdata));
				if (!newTopicData.children) {
					newTopicData.children = [];
				}
				const newChild = {
					name: newcontenttopic,
					progress: 'draft',
					content: fetchid,
					created: created
				};
				newTopicData.children.push(newChild);
				console.log(newTopicData);
				updateData({ topicdata: newTopicData });							
				setContentID(fetchid);
				gotourl(`/app/write/${fetchid}`);
			} else {
				console.log('Error creating new content');
				return;
			}					
		} catch (error) {
			console.error('Content creation error:', error);
		}

	};
	
	// Render the writing view
	
	return (
		<div className="writing-wrap">
		
			{loadingcontent ? (
				
				<div className="loader">...loading content <span className="loading-indicator"></span></div>
			
			) : (
				
				contentid ? (
					
					<div className="writing-inner">
									
						<div className="workspace-nav">
							
							<div className="workspace-nav-item writing-nav-voice">	
								<div className="link-inline">
									<Voice className="icon icon-voice" />
									<a className="noselect" onClick={(e) => {toggleScales(e)}}>
										Writing style
									</a>
								</div>
								<span className="workspace-nav-hint">{showvoice ? "HIDE" : "SHOW" }</span>
							</div>
							
							<div className="workspace-nav-item writing-nav-template">	
								<div className="link-inline">
									<Template className="icon icon-template" />
									<a onClick={toggleDropdownTemplates} className="noselect">
										Template	
									</a>
								</div>
								{showDropdownTemplates && (
								<div className="dropdown dropdown-nav dropdown-writing-templates" ref={dropdownTemplatesRef}>
									<div className="dropdown-items">
										{contentTemplates.map((template, index) => (
										<div className="dropdown-item" key={index}>
											<input type="radio" id={'template-'+index} checked={temporaryTemplate === template} onChange={() => handleTemplatesChange(template)} />
											<label htmlFor={'template-'+index}>{template.charAt(0).toUpperCase() + template.slice(1)}</label>
										</div>
										))}
									</div>
									<div className="dropdown-apply" onClick={applyTemplateChanges}>
										Choose
									</div>
								</div>
								)}
								<span className="workspace-nav-hint">{currentTemplate === "post" ? "OFF" : "ON" }</span>
							</div>
							
							<div className="workspace-nav-item writing-nav-export">	
								<div className="link-inline">
									<Export className="icon icon-export" />
									<a className="noselect" onClick={exportContent}>
										Export
									</a>
								</div>
							</div>
							
							<div className="workspace-nav-item writing-nav-delete">	
								<div className="link-inline">
									<Delete className="icon icon-delete" />
									<a className="noselect" onClick={() => deleteContent(contentparams.name)}>
										Delete
									</a>
								</div>
							</div>
							
						</div>
														
						<div className="writing">
						
							<div className="writing-tools">
							
								{showvoice && (
								
								<div className="writing-settings noselect">
								
									{writingscales.map(scale => (
										<WritingScale
											key={scale.id}
											id={scale.id}
											labels={scale.labels}
											position={scale.position}
											onPointClick={handlePointClick}
											onDragEnd={handleDragEnd} 
										/>
									))}
								
								</div>
								
								)}
								
								<div className="writing-zone">
								
									<div className="writing-headings">
									
										<div className="writing-headings-field">
										
											{currentcontent && currentcontent.heading && (
												
												loadingheadings ? (
													<div className="loader">
														...brainstorming <span className="loading-indicator"></span>
													</div>
												) : (
												
													currentcontent.heading.title || currentcontent.heading.title === '' ? (
														
														<div className="writing-heading-ideas-wrap">
															<div className="writing-heading-ideas-description">
																Click to edit the title or <img className="writing-ai" src={iconai} alt="AI" /><span className="writing-link writing-heading-ideas-generate" onClick={() => brainstormTitles()}>generate other title ideas</span>:
															</div>
															<textarea 
																className="writing-input writing-heading-title" 
																placeholder="Add title or generate ideas above" 
																ref={titleRef} 
																type="text"
																value={currentcontent.heading.title}
																onChange={(e) => { handleTitleChange(e); adjustTitleareaHeight(titleRef.current); }}
																onInput={adjustTitleareaHeight(titleRef.current)}
																onBlur={handleTitleBlur}
																onKeyPress={handleTitleKeyPress}
															/>
															<div className={`writing-save ${titlesave ? `writing-${titlesave}` : ''} writing-save-heading`}>
																{titlesave}
															</div>
														</div>
														
													) : (
													
														currentcontent.heading.ideas ? (
															<div>
																<div className="writing-heading-ideas-description">
																	Choose the title you like or <img className="writing-ai" src={iconai} alt="AI" /><span className="writing-link writing-heading-ideas-generate" onClick={() => brainstormTitles()}>generate more title ideas</span>:
																</div>
																<ul className="writing-heading-ideas">
																	{currentcontent.heading.ideas.map((value, index) => {
																		return (
																		<li className="writing-heading-idea" key={index}>
																			{isTyping ? (
																				<GradualOutput text={value} increment={2} interval={30} />
																			) : (
																				<span className="writing-heading-idea-static" onClick={() => chooseTitle(value)}>
																					{value}
																					<span className="writing-heading-idea-choose">
																						<Choose />
																					</span>
																				</span>
																			)}
																		</li>
																		);
																	})}
																</ul>
															</div>
														) : ( 
															<div className="writing-heading-ideas-wrap">
																<div className="writing-heading-ideas-description">
																	<img className="writing-ai" src={iconai} alt="AI" /><span className="writing-link writing-heading-ideas-generate" onClick={() => brainstormTitles()}>Brainstorm title ideas</span> or input below:
																</div>
																<textarea 
																	className="writing-input writing-heading-title" 
																	placeholder="Add title" 
																	ref={titleRef} 
																	type="text"
																	value={currentcontent.heading.title}
																	onChange={(e) => { handleTitleChange(e); adjustTitleareaHeight(titleRef.current); }}
																	onInput={adjustTitleareaHeight(titleRef.current)}
																	onBlur={handleTitleBlur}
																	onKeyPress={handleTitleKeyPress}
																/>
																<div className={`writing-save ${titlesave ? `writing-${titlesave}` : ''} writing-save-heading`}>
																	{titlesave}
																</div>
															</div>
														)
														
													)	
													
												)
													
											)}
										
										</div>
										
									</div>
									
									<div className="writing-text">
									
										<div className="writing-text-field">
										
											{currentcontent && (
											
											<>
											
												{loadingtext ? (
													
													<div className="loader writing-text-ideas-description">
														...brainstorming <span className="loading-indicator"></span>
													</div>
													
												) : (
												
													contentToRender ? contentToRender : ''
													
												)}
												
											
												<div className="writing-editor-wrapper">
												
													<div className={`writing-save ${textsave ? `writing-${textsave}` : ''} writing-save-text`}>
														{textsave}
													</div>
													
													<QuillToolbar />
													<ReactQuill 
														ref={handleQuillLoad}
														placeholder={"Start writing or click above to let AI brainstorm an outline"}
														modules={modules}
														formats={formats}
														onChange={handleEditorChange} 
													/>
													{renderContextMenu()}
													
													<div className="writing-editor-save">
														<button className="writing-editor-save-button" onClick={saveEditorContent}>Save Now</button>
													</div>
													
												</div>
												
												
											</>
											
											)}
																					
										</div>
										
									</div>
								
								</div>
								
							</div>
							
							<div className="writing-meta">
								
								{currentcontent && contentparams ? (
								
								<>
								
								<div className="writing-meta-item writing-meta-topic">
									
									{data.topicdata &&
									<div className="writing-meta-topic-parent">
										┌─ {data.topicdata.name}
									</div>
									}
									
									<div className="writing-meta-topic-name">
										{contentparams.key ? contentparams.key : currentcontent.heading.topic}
									</div>
									
									{user.seomode && contentparams.volume && (
									<div className="writing-meta-topic-seo">
										<div className="writing-meta-volume">
											<span className="writing-meta-item-key">Volume: </span>
											<span className="writing-meta-item-value">{(Math.round(contentparams.volume / 100) * 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
										</div>
										<div className="writing-meta-difficulty">
											<span className="writing-meta-item-key">Difficulty: </span>
											<span className="writing-meta-item-value">{contentparams.difficulty}</span>
											<div className="tooltip">
												<div className="tooltip-mark">
													?
												</div>
												<div className="tooltip-text">
													<strong>Keyword Difficulty</strong> indicates how hard it is to get into the top-10 for a given keyword, on a scale from 0 (very easy) to 100 (extremely difficult).
												</div>
											</div>
										</div>
									</div>
									)}
									
								</div>								
								
								<div className="writing-meta-item writing-meta-plan">
									
									<div className="writing-meta-plan-table">
										
										<div className="writing-meta-progress">
											<div className="writing-meta-item-key writing-meta-plan-table-cell">Progress:</div>
											<div className="writing-meta-plan-table-cell">
												<div className={`plan-progress-tag plan-progress-tag-${contentparams.progress ? contentparams.progress : 'idea'}`} onClick={() => { toggleDropdownProgress(); }}>
													{contentparams.progress ? contentparams.progress : 'idea'}
												</div>
												{showDropdownProgress && (
													<div className="dropdown dropdown-progress" ref={dropdownProgressRef}>
														<div className="dropdown-items">
															{progressStates.map((state) => (
																<div className={`plan-progress-tag plan-progress-tag-${state}`} key={state} onClick={() => handleProgressChange(contentparams.name, state)}>
																	{state}
																</div>
															))}
														</div>
													</div>
												)}
											</div>
										</div>
										
										<div className="writing-meta-format">
											<div className="writing-meta-item-key writing-meta-plan-table-cell">Format:</div>
											<div className="writing-meta-plan-table-cell">
												{contentparams.format ? (
												<div className={`plan-format-tag writing-meta-plan-table-cell plan-format-tag-${contentparams.format}`} onClick={() => {toggleDropdownFormat(); }}>
													<FormatIcon iconName={contentparams.format} className={`icon icon-${contentparams.format}`} />
													{contentparams.format}
												</div>
												) : (
												<span className="writing-meta-set writing-meta-plan-table-cell" onClick={() => {toggleDropdownFormat(); }}>
													set
												</span>
												)}
												{showDropdownFormat && (
													<div className="dropdown dropdown-format" ref={dropdownFormatRef}>
														<div className="dropdown-items">
															{formatStates.map((state) => (
																<div className={`plan-format-tag plan-format-tag-${state}`} key={state} onClick={() => handleFormatChange(contentparams.name, state)}>
																	{state !== 'clear' && (
																	<FormatIcon iconName={state} className={`icon icon-${state}`} />
																	)}
																	{state}
																</div>
															))}
														</div>
													</div>
												)}
											</div>
										</div>
										
										<div className="writing-meta-deadline">
											<div className="writing-meta-item-key writing-meta-plan-table-cell">Deadline:</div>
											<div className="writing-meta-plan-table-cell">
												<DatePicker
													className={"datepicker writing-meta-plan-table-cell"}
													popperClassName="datepicker-popper"
													wrapperClassName="datepicker-wrapper"
													selected={contentparams.deadline ? new Date(contentparams.deadline) : null}
													onChange={(date) => handleDeadlineChange(date, contentparams.name)}
													dateFormat="MMM d, yyyy"
													isClearable
													placeholderText='set'
													showPopperArrow={false}
												/>
											</div>
										</div>
										
									</div>	
									
									<div className="writing-meta-notes">
										{isNoteEditing ? (
										<div 
											className="writing-meta-note writing-meta-note-editing"
											contentEditable
											onBlur={handleNoteBlur(contentparams.name)}
											onKeyDown={handleNoteKeyPress}
											ref={noteRef} />
										) : (
										<div
											className={`writing-meta-note ${notecontent === notePlaceholder ? 'writing-meta-note-empty' : ''}`}
											onClick={handleNoteEdit}
											style={notecontent === notePlaceholder ? { color: notePlaceholderColor } : {}}
										>
											{notecontent}
										</div>
										)}
									</div>
								</div>
								
								</>
								
								) : ('')}
								
								{currentcontent ? (
								<div className="writing-meta-item writing-meta-dates">
									<div className="writing-meta-created">
										<span className="writing-meta-item-key">Created: </span>
										<span className="writing-meta-item-value">{historyDate(currentcontent.created)}</span>
									</div>
									<div className="writing-meta-updated">
										<span className="writing-meta-item-key">Updated: </span>
										<span className="writing-meta-item-value">{currentcontent.created !== currentcontent.updated ? historyDate(currentcontent.updated) : 'not yet'}</span>
									</div>
								</div>
								) : ('')}
							
							</div>
												
						</div>
						
						{user.modalwriting && isauth && (
							<Modal className='modal-writing' backgroundImage={modalwriting} isOpen={user.modalwriting} onClose={() => { updateUser({ modalwriting: false }); } }>
								<WritingModal />
							</Modal>
						)}
						
					</div>
					
				) : (
				
					<div className="view-empty">
				
						<div className="view-empty-placeholder">
							Pick any (sub)topic in the <Link to="/app/topics">tree</Link> or <Link to="/app/plan">plan</Link> —<br />
							or <span className="create-content-link" onClick={(e) => setShowCreate(!showcreate)}>click here</span> to create new content under your current parent topic     <span className="textface">(づ ￣ ³￣)づ</span>
						</div>
						
						{showcreate && (
						
							<div className="writing-create">
																										
								<input 
									rows="1"
									className="writing-input writing-create-title" 
									placeholder="Start with a title, topic, or keyword" 
									ref={newTitleRef} 
									type="text"
									value={newtitle}
									onChange={(event) => handleNewTitleChange(event)}
								/>
								
								<button onClick={(e) => createNewContent()} className="userbutton userbutton-newcontent" type="button">
									Create new content
								</button>
																		
							</div>
							
						)}
					
					</div>
				
				)
							
			)}
		</div>
	);
	
};

// Display content history

function ContentHistory({
	updateData,
	contentid,
	topicid,
	setTopicID,
	gotourl,
	setContentID,
	contenthistory,
	setContentHistory,
	setLoadingContent,
	titleRef,
	adjustTitleareaHeight,
}) {
	
	const protectedFetch = useProtectedFetch();
	const isArrayEmpty = arr => Array.isArray(arr) && arr.length === 0;
	
	// Load content on click
	
	const handleHistoryItemClick = (content) => {
		setLoadingContent(true);
		if ( content ) {
			gotourl(`/app/write/${content._id}`);
			// setContentID(content._id);
			// updateData({ content: null });
			// setHistoryCounter((prevCounter) => prevCounter + 1);
		}
    };
	
	// Slide history panel
	
	const [isHistoryOpen, setIsHistoryOpen] = useState(false);	
	
	// Fetch content history
			
	const fetchLastContents = (topicid, number=1) => {
		if ( !topicid ) {
			return Promise.resolve(null); 
		}
		return protectedFetch(`/api/contents/${topicid}`)
		.then(response => {
			if ( !response.ok ) {
				console.error('Network response was not ok when fetching contents:', response);
				return Promise.reject('Network response was not ok when fetching contents');
			}
			return response.json();
		})
		.then(contents => {
			if (contents && contents.length > 0) {
				const sortedContents = contents.sort((a, b) => new Date(b.updated) - new Date(a.updated));
				if ( number > 0 ) {
					return sortedContents.slice(0, number);
				} else {
					return sortedContents;
				}
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Contents fetch error:', error);
			return null;
		});
	};
			
	// Set content history
	
	const setHistory = async() => {
		try {
			let fetchedHistory;
			fetchedHistory = await fetchLastContents(topicid, 5);
			if (fetchedHistory && fetchedHistory.length > 0) {
				if (!isEqual(fetchedHistory, contenthistory)) {
					setContentHistory(fetchedHistory);
				}
			}
		} catch (error) {
			console.error('Last topic fetch error:', error);
		}
	};
	
	useEffect(() => {	
		setHistory();
    }, [contentid, topicid]);
	
	if ( isArrayEmpty(contenthistory) ) {
		return;
	}
	
	return (
			
		<div className={`history history-content ${isHistoryOpen ? 'history-open' : 'history-closed'}`}>
			
			<div className="history-slide" onClick={() => { setIsHistoryOpen(!isHistoryOpen); setTimeout(() => {adjustTitleareaHeight(titleRef.current); }, 1000); }}>
				<Slide className="icon icon-slide" />
				{isHistoryOpen ? ( 
					<span className="history-slide-desc">Collapse history</span>
				) : (
					<History className="icon icon-history" />
				)}
			</div>
			
			{isHistoryOpen && (
			
            <ul className="history-list">
			
                {contenthistory.map((content, index) => (
                    
					<li className="history-item" key={index} onClick={() => handleHistoryItemClick(content)}>
						
						<div className="history-item-icon">
							<History className="icon icon-history" />
						</div>
						
						<div className="history-item-inner">
							<div className="history-item-name">
								{content.heading && content.heading.topic}
							</div>
							<div className="history-item-updated">
								{historyDate(content.updated)}
							</div>
						</div>
						
                    </li>
					
                ))}
				
            </ul>
			
			)}
			
        </div>
				
    );
	
}

// Drafting component for the Main view

function Write() {
	
	// Initialize context and shared states
	
	const { 
		userid,
		user, updateUser, 
		isauth, setIsAuth,
		contentid, setContentID,
		topicid, setTopicID,
		accesstoken, 
		setModalUpgrade,
	} = useUserContext();
	const {	data, updateData, } = useDataContext();
	const gotourl = useNavigate();
	const [currentcontent, setCurrentContent] = useState(null);
	const [contenthistory, setContentHistory] = useState([]);
	const [loadingcontent, setLoadingContent] = useState(false);
	const titleRef = useRef(null);
	const newTitleRef = useRef(null);
	
	// Adjust title area height

	const adjustTitleareaHeight = () => {
		if (titleRef.current) {
			const baseHeight = 65;  
			titleRef.current.style.height = `${baseHeight}px`;
			const scrollHeight = titleRef.current.scrollHeight;
			titleRef.current.style.height = `${Math.max(scrollHeight, baseHeight)}px`;
		}
	};
	
	// Render the writing view
	
	return (
		<div className="view view-write">
			<Writing
				userid={userid}
				user={user}
				updateUser={updateUser}
				data={data}
				updateData={updateData}
				topicid={topicid}
				setTopicID={setTopicID}
				gotourl={gotourl}
				contentid={contentid}
				setContentID={setContentID}
				currentcontent={currentcontent}
				setCurrentContent={setCurrentContent}
				loadingcontent={loadingcontent}
				setLoadingContent={setLoadingContent}
				titleRef={titleRef}
				newTitleRef={newTitleRef}
				adjustTitleareaHeight={adjustTitleareaHeight}
				accesstoken={accesstoken}
				isauth={isauth}
				setModalUpgrade={setModalUpgrade}
			/>
			<ContentHistory
				updateData={updateData}
				contentid={contentid}
				topicid={topicid}
				setTopicID={setTopicID}
				gotourl={gotourl}
				setContentID={setContentID}
				contenthistory={contenthistory}
				setContentHistory={setContentHistory}
				setLoadingContent={setLoadingContent}
				titleRef={titleRef}
				adjustTitleareaHeight={adjustTitleareaHeight}
			/>
		</div>
	);
	
}

export default Write;
