import React, { createContext, useContext, useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
const UserContext = createContext();

export const useUserContext = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('UserContext must be used within a UserContextProvider');
	}
	return context;
};

export function UserContextProvider({ children }) {
	
	const { urlid } = useParams();
	
	// Set parameters
	
	const [userid, setUserID] = useState(null);
	const [isauth, setIsAuth] = useState(false);
	const [isdemo, setIsDemo] = useState(false);
	const [currentplan, setCurrentPlan] = useState('demo');
	const [accesstoken, setAccessToken] = useState(() => localStorage.getItem('accessToken') || null);
	const [topicid, setTopicID] = useState(null);
	const [contentid, setContentID] = useState(null);	
	const [doneloading, setDoneLoading] = useState(false);
	const [topichistory, setTopicHistory] = useState([]);
	const [modalsignup, setModalSignup] = useState(false);
	const [modalupgrade, setModalUpgrade] = useState(false);
	const [modallogin, setModalLogin] = useState(false);
	const [modalwelcome, setModalWelcome] = useState(false);
	const [democount, setDemoCount] = useState(null);
	const [loadingTopics, setLoadingTopics] = useState('');
	const [loadingSEOData, setLoadingSEOData] = useState('');
	const [keywordsEmpty, setKeywordsEmpty] = useState('');
	
	const [user, setUser] = useState({
		seomode: true,
		seocountry: 'United States',
		seolanguage: 'English',
		planorder: null,
		columnsToShow: {
			topic: true,
			deadline: false,
			progress: true,		
			format: false,
			volume: true,
			difficulty: true,
			created: false,
			published: false,
			notes: true,
		},
		showvoice: true,
		lastpage: '/app/dashboard',
		settingsActiveTab: 'user',
		notificationsUpdates: true,
		notificationsNewsletter: false,
		modalwriting: true,
		projectid: null,
		topicid: null,
		contentid: null,
		credits: 0,
	});
	
	// Check authentication requirements
	
	const requiresAuth = (path) => {
		const authRequiredRoutes = ['/app'];
		return authRequiredRoutes.some(route => path.startsWith(route));
	};
	
	// Refresh access token
	
	const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
	
	const refreshToken = async () => {
		try {
			const response = await fetch('/api/token', {
				method: 'POST',
				credentials: 'include'
			});
			const data = await response.json();
			if (data.token) {
				localStorage.setItem('accessToken', data.token);
				setAccessToken(data.token);
			} else {
				setAccessToken(null);
				localStorage.removeItem('accessToken');
				setNavigateUrl(`/login`);
			}
		} catch (error) {
			console.error('Error refreshing token:', error);
		}
	};
	
	const gotourl = useNavigate();
	const [navigateUrl, setNavigateUrl] = useState(null);
	
	useEffect(() => {
		if (navigateUrl) {
			gotourl(navigateUrl);
		}
	}, [navigateUrl, gotourl]);
	
	// Get user ID from token
	
	const location = useLocation();

	useEffect(() => {
		const newtoken = localStorage.getItem('accessToken');
		if ( newtoken ) {
			if ( newtoken !== accesstoken ) {
				setAccessToken(newtoken);
			}
			const decodedToken = jwtDecode(newtoken);
			const currentTimestamp = Date.now() / 1000;
			if (decodedToken.exp && decodedToken.exp < currentTimestamp && !isTokenRefreshing) {
				setIsTokenRefreshing(true);
				refreshToken().finally(() => setIsTokenRefreshing(false));
			} else if (!decodedToken.exp || decodedToken.exp >= currentTimestamp) {
				const userId = decodedToken.userId;
				setUserID(userId);
				setIsAuth(true);
			}
		} else {
			const demodata = localStorage.getItem('demodata');
			if (requiresAuth(location.pathname) && !demodata && !isdemo) {
				setNavigateUrl('/login');
			}
		}
	}, [accesstoken]);
		
	// Update user
	
	const updateUser = (updatedValues) => {
		const updatedUser = {
			...user,
			...updatedValues,
			columnsToShow: {
				...user.columnsToShow,
				...updatedValues.columnsToShow,
			},
		};
		setUser(updatedUser);
		if ( ! userid || !accesstoken ) {
			return;
		}
		fetch(`/api/user/${userid}`, {
			method: 'PUT',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accesstoken}`
			},
			body: JSON.stringify({ usercontext: updatedUser })
		})
		.catch(updateError => {
			console.error('Usercontext update error:', updateError);
		});
	};
	
	// Fetch user parameters and project
	
	useEffect(() => {
		if (!userid || !accesstoken) {
			return;
		}
		fetch(`/api/user/${userid}`, { 
			headers: {
				'Authorization': `Bearer ${accesstoken}`
			}
		})
		.then(response => {
			if ( !response.ok ) {
				console.error('Error fetching user:', response);
				return Promise.reject('Network response was not ok while fetching user');
			}
			return response.json();
		})
		.then(userdata => {
			if (userdata.plan) {
				setCurrentPlan(userdata.plan);
			} else {
				if (isdemo) {
					setCurrentPlan('demo');
				} else {
					setCurrentPlan('free');
				}
			}
			if (userdata.usercontext) {
				const userParameters = { ...user };
				for (const key in userdata.usercontext) {
					userParameters[key] = userdata.usercontext[key];
				}
				if (userdata.usercontext.columnsToShow) {
					userParameters.columnsToShow = {
						...user.columnsToShow,
						...userdata.usercontext.columnsToShow
					};
				}
				setUser(userParameters);
				setDoneLoading(true);
				
				// Set project if not found in user parameters
				
				if ( !userParameters.projectid ) {
					if ( urlid ) {
						if ( location.pathname ) {
							const urlparts = extractURLParts(location.pathname);
							if ( urlparts ) {
								const findobj = CheckExistence(urlparts.type, urlparts.id);
								if ( findobj && findobj._id && findobj.projectID && findobj.userID === userid ) {
									updateUser({ projectid: findobj.projectID });
								} else {
									setNavigateUrl('/app/dashboard');
								}
							}
						}
					} else {
						fetch(`/api/projects/${userid}`, { 
							headers: {
								'Authorization': `Bearer ${accesstoken}`
							}
						})
						.then(response => {
							if ( !response.ok ) {
								console.error('Error fetching project:', response);
								return Promise.reject('Network response was not ok while fetching project');
							}
							return response.json();
						})
						.then(projects => {
							if (projects && projects.length > 0) {
								const sortedProjects = projects.sort((a, b) => new Date(b.updated) - new Date(a.updated));
								updateUser({ projectid: sortedProjects[0]._id });
								return;
							} else {
								setNavigateUrl('/app/dashboard');
								return;
							}
						})
						.catch((error) => {
							console.error('Fetch error:', error);
						});
					}
				}
				
				// Update database entry if extra data
				
				if (Object.keys(userParameters).length > Object.keys(userdata.usercontext).length) {
					fetch(`/api/user/${userid}`, {
						method: 'PUT',
						headers: { 
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${accesstoken}`
						},
						body: JSON.stringify(userParameters)
					})
					.then(updateResponse => {
						if (!updateResponse.ok) {
							console.error('Error updating user:', updateResponse);
						}
					})
					.catch(updateError => {
						console.error('Update error:', updateError);
					});
				}								
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.error('Fetch error:', error);
		});
	}, [userid, accesstoken]);
	
	// Extract ID and view from URL
	
	function extractURLParts(url) {
		const regex = /\/app\/(topics|plan|write)\/([a-zA-Z0-9]+)/;
		const result = url.match(regex);
		if (result && result.length === 3) {
			return {
				view: result[1],
				id: result[2]
			};
		} else {
			return null;
		}
	}
	
	// Check if object exists
	
	const CheckExistence = (type, id) => {
		if ( !type || !id || !accesstoken ) {
			return null;
		}
		fetch(`/api/find/${type}/${id}`, { 
			headers: {
				'Authorization': `Bearer ${accesstoken}`
			}
		})
		.then(response => {
			if ( !response.ok ) {
				console.error('Error fetching object:', response);
				return Promise.reject('Network response was not ok while fetching object');
			}
			return response.json();
		})
		.then(objectdata => {
			return objectdata;
		})
		.catch(updateError => {
			console.error('Existence not confirmed:', updateError);
		});
	};
		
	// Capture last viewed page
		
	useEffect(() => {
		if ( location.pathname.startsWith('/app') && accesstoken ) {
			updateUser({ lastpage: location.pathname });
		}
	}, [location.pathname, accesstoken]);
	
	// Expose context update method
	
	return (
		<UserContext.Provider value={{ 
			user,
			updateUser,
			userid, 
			setUserID,
			isauth, 
			setIsAuth,
			isdemo,
			setIsDemo,
			currentplan, 
			setCurrentPlan,
			accesstoken,
			setAccessToken,
			refreshToken,
			topicid,
			setTopicID,
			contentid,
			setContentID,
			doneloading,
			setDoneLoading,
			topichistory, 
			setTopicHistory,
			modalsignup, 
			setModalSignup,
			modalupgrade, 
			setModalUpgrade,
			modallogin,
			setModalLogin,
			modalwelcome, 
			setModalWelcome,
			democount,
			setDemoCount,
			loadingTopics,
			setLoadingTopics,
			loadingSEOData, 
			setLoadingSEOData,
			keywordsEmpty,
			setKeywordsEmpty
		}}>
			{children}
		</UserContext.Provider>
	);
	
}