import { useEffect, useState, useRef } from 'react';

export const useDropdownOutsideClick = (initialState) => {
	
	const [showDropdown, setShowDropdown] = useState(initialState);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return { showDropdown, setShowDropdown, dropdownRef };
	
};