import { Link, useLocation } from 'react-router-dom';

function Terms() {	

	return (
		<div className="page page-wrap terms-wrap aux-wrap">
			
			<div className="sections-container">
				
				<h1>Privacy Policy</h1>
				
				<p>
					<em>Last updated: 4 February 2024</em>
				</p>
				
				<p>Welcome to Satori Studio, a service provided by Satoriweb OU ("us", "we", "our"). We are registered in Estonia and our principal place of business is at Koorti 30 - 33. Your privacy is important to us. This privacy policy ("Policy") applies to your use of the website located at satoristudio.net ("Website") operated by Satoriweb OU. It explains how we collect, use, and share information about you when you access or use our service ("Service").</p>

				<h2>1. Information We Collect</h2>
				<p>We collect information that you provide directly to us when you use our Services. This may include:</p>
				<ul>
					<li>Contact information (such as your name, email address, and phone number).</li>
					<li>Content that you create, share, or post in audio, video, text, images, and other media or software files.</li>
					<li>Information that you provide when you participate in any interactive features, surveys, contests, promotions, activities, or events.</li>
				</ul>

				<p>We also automatically collect certain information, including:</p>
				<ul>
					<li>Log information about how you access or use our Services, including your access times, browser types, and language.</li>
					<li>Device information, including your computer or mobile device and connection information such as your IP address, browser types, and versions.</li>
					<li>Information collected by cookies and other tracking technologies.</li>
				</ul>

				<h2>2. How We Use Information</h2>
				<p>We use the information we collect to:</p>
				<ul>
					<li>Provide, maintain, and improve our Services.</li>
					<li>Understand your needs and interests, and personalize your experience with our Services.</li>
					<li>Respond to your requests, questions, and feedback.</li>
					<li>Send you technical notices, updates, security alerts, information regarding changes to our policies, and support and administrative messages.</li>
					<li>Protect against, identify, investigate, and respond to fraud, illegal activity, and other harmful activity.</li>
				</ul>

				<h2>3. Sharing Of Information</h2>
				<p>We do not share your personal information with third parties except as described in this Policy:</p>
				<ul>
					<li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
					<li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process.</li>
					<li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Satoriweb OU or others.</li>
					<li>Between and among Satoriweb OU and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership.</li>
					<li>With your consent or at your direction.</li>
				</ul>

				<h2>4. Your Choices</h2>
				<p>You may update, correct, or delete your account information and preferences at any time by accessing your account settings page on the Service. If you wish to access or amend any other personal data we hold about you, or to request that we delete any information about you, you may contact us <Link to="/contact">here</Link>. Note that deletion of your information or content does not ensure complete or comprehensive removal of the content or information posted on the Services.</p>

				<h2>5. Cookies and Tracking Technologies</h2>
				<p>We use cookies and similar tracking technologies to collect information about your interaction with our Services. You can restrict the use of cookies or other tracking technologies through your browser settings.</p>

				<h2>6. Jurisdiction</h2>
				<p>This Policy is governed by the laws of Estonia, without regard to its conflict of laws principles.</p>

				<h2>7. Changes to This Policy</h2>
				<p>We may update this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice.</p>

				<h2>8. Contact Us</h2>
				<p>If you have any questions about this Policy, please contact us <Link to="/contact">here</Link>.</p>
					
			</div>
			
			<div className="gradient gradient-bottom">
				<div className="gradient-blue">
				</div>
				<div className="gradient-red">
				</div>
			</div>
			
		</div>
	);
}

export default Terms;