export const Modal = ({ isOpen, onClose, children, backgroundImage, className='' }) => {
	
	if (!isOpen) return null;
	
    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
	
	return (
		<div className="modal-overlay" onClick={handleOutsideClick}>
			<div className={`modal ${className}`} onClick={(e) => e.stopPropagation()}>
				<div className="modal-wrap">
					<div className="close-button" onClick={onClose}>{'\u2573'}</div>
					<div className="modal-inner">
						{children}
					</div>
				</div>
				{backgroundImage && (
				<div className="modal-image">
					<img src={backgroundImage} alt="Modal Background" />
				</div>
				)}
			</div>
		</div>
	);
}
