import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { useUserContext } from '../contexts/user';
import { LogoutLink, RequestInvite } from './authentication';
import ParallaxImage from '../components/parallax';
import Seed from '../components/seed';

import imgHero from '../assets/hero-front.jpg';
import imgSatori from '../assets/art-satori.png';
import imgSatoriAwake from '../assets/art-satori-awake.png';
import imgFeatureTopics from '../assets/feature-topics.gif';
import imgFeaturePlan from '../assets/feature-plan.gif';
import imgFeatureWrite from '../assets/feature-write.gif';
import imgFeaturePlanFigure from '../assets/feature-plan-figure.png';
import imgFeatureWriteFigure from '../assets/feature-write-figure.png';
import imgJpnFrontBottom from '../assets/jpn-front-bottom.svg';
import imgTierFree from '../assets/tier-free.png';
import imgTierPro from '../assets/tier-pro.png';
import imgTierEnterprise from '../assets/tier-enterprise.png';
import imgStackHubspot from '../assets/stack-hubspot.svg';
import imgStackMoz from '../assets/stack-moz.svg';
import imgStackAhrefs from '../assets/stack-ahrefs.svg';
import imgStackSemrush from '../assets/stack-semrush.svg';
import imgStackWP from '../assets/stack-wordpress.svg';
import imgStackGA from '../assets/stack-ga.svg';
import { ReactComponent as IconTopics } from '../assets/icon-topics.svg';
import { ReactComponent as IconPlan } from '../assets/icon-plan.svg';
import { ReactComponent as IconWrite } from '../assets/icon-write.svg';

function Home() {
	
	const { userid, isauth } = useUserContext();
	const frontSatoriBackgroundRef = useRef(null);
	const navigate = useNavigate();
	
	// Focus on input field on load
	
	useEffect(() => {		
		const focusElement = document.getElementById('input-seed');
		if (focusElement) {
			focusElement.focus();
		}
	}, []);
	
	// Scroll to top and focus on input field
		
	const scrollFocus = (event) => {		
		const focusElement = document.getElementById('input-seed');
		if (window.pageYOffset === 0) {
            if (focusElement) {
                focusElement.focus();
            }
            return;
        }
		window.scrollTo({
			top: focusElement.offsetTop - 200,
			left: 0,
			behavior: 'smooth'
		});
		const handleScroll = () => {
            if (window.pageYOffset < focusElement.offsetTop) {
                window.removeEventListener('scroll', handleScroll);
                const focusElement = document.getElementById('input-seed');
                if (focusElement) {
                    focusElement.focus();
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
	}
	
	// Switch between billing cycles
	
	const [billingMonthly, setBillingMonthly] = useState(false);
	
	// Render component
	
	return (
		<div className="page page-wrap home-wrap">
		
			<div className="header header-front">
				
				<div className="header-logo">
					<Link to={`${isauth ? '/app/dashboard' : '/'}`}>
						<Logo className="logo" />
					</Link>
				</div>
				
				<div className="navbar-menu navbar-menu-nonauth">
					{isauth ? (
						<LogoutLink className='navbar-menu-link navbar-menu-link-logout' />
					) : (
						<div className="auth-links">
							<Link to={'/login'} className="navbar-menu-link navbar-menu-link-login">Login</Link>
							<Link to={'/signup'} className="navbar-menu-link menu-link-register">Register</Link>
						</div>
					)}
				</div>
			
			</div>
			
			<div className="primary">
				
				<div className="hero hero-front">
					
					<div className="hero-top">
						<div className="hero-title">
							<h1>
								<span className="hero-highlight hero-highlight-purple">10x</span> YOUR CONTENT STRATEGY WITH <span className="hero-highlight hero-highlight-blue">AI</span>
							</h1>
						</div>	
						<div className="hero-image">
							<img src={imgHero} alt="Humans and machines, working side by side" />
						</div>
					</div>	
					
					<div className="hero-bottom">
						<Seed action='home' />
					</div>
					
				</div>
				
				<div className="sections-container">
				
					<div className="section front-section">
						
						<div className="section-horizontal section-horizontal-three front-usp">
							<div className="section-item">
								<div className="section-item-title">
									<span className="section-item-title-inner">
										Intelligence everywhere
									</span>
									<div className="section-item-title-cursor">
									</div>
								</div>
								<div className="section-item-description">
									Become one with your mecha suit ~ <span className="usp-highlight">exponentiate</span> all stages of content marketing and SEO with <span className="usp-highlight">best-in-class AI</span>. 
								</div>
							</div>
							<div className="section-item">
								<div className="section-item-title">
									<span className="section-item-title-inner">
										Infused with SEO data
									</span>
									<div className="section-item-title-cursor">
									</div>
								</div>
								<div className="section-item-description">
									Stay sharp, commander ~ make data-driven decisions with <span className="usp-highlight">country-</span> and <span className="usp-highlight">language-</span> level SEO data always at your fingertips.
								</div>
							</div>
							<div className="section-item">
								<div className="section-item-title">
									<span className="section-item-title-inner">
										Full content workflows
									</span>
									<div className="section-item-title-cursor">
									</div>
								</div>
								<div className="section-item-description">
									Finally, you can go from an idea, to content planning, drafting, and publishing - in a <span className="usp-highlight">single</span>, <span className="usp-highlight">adaptive</span> control hub ~
								</div>
							</div>
						</div>
						
					</div>
					
					<div className="section front-section">
					
						<div className="section-image front-satori">
							
							<div ref={frontSatoriBackgroundRef} className="front-satori-background"></div>
							
							<div className="parallax">
								<ParallaxImage 
									src={imgSatori} 
									newSrc={imgSatoriAwake} 
									swapScrollThreshold={400}
									speed={0.5}
									delay={0}
									maxscroll={900}
									initialtopWide={-300} 
									initialtopMob={0}
									fadeElementRef={frontSatoriBackgroundRef}
								/>
							</div>
							
						</div>
					
					</div>
					
					<div className="section front-section">
					
						<div className="section-vertical front-features">
						
							<div className="features-intro">
								<span className="feature-topics"><IconTopics className="feature-title-icon feature-icon-topics" /><span className="feature-highlight">Brainstorm</span></span>, <span className="feature-plan"><IconPlan className="feature-title-icon feature-icon-plan" /><span className="feature-highlight">plan</span></span>, and <span className="feature-write"><IconWrite className="feature-title-icon feature-icon-write" /><span className="feature-highlight">craft</span></span> content like never before:
							</div>
							
							<div className="feature feature-topics">
								<div className="feature-text">
									<div className="feature-title">
										<h2>
											Brainstorm <span className="feature-title-focus"><IconTopics className="feature-title-icon" /><span className="feature-highlight">topic clusters</span></span> effortlessly
										</h2>
									</div>
									<div className="feature-description">
										Tap into a brain as big as the Internet itself: turn a single keyword into a semantic tree with the help of AI, then fetch crucial SEO data in a click ~
									</div>
								</div>
								<div className="feature-image">
									<img src={imgFeatureTopics} className="feature-image-screen" alt="Topic cluster creator" />
								</div>
							</div>
							
							<div className="feature feature-plan feature-right">
								<div className="feature-text">
									<div className="feature-title">
										<h2>
											Manage <span className="feature-title-focus"><IconPlan className="feature-title-icon" /><span className="feature-highlight">content plan</span></span> like a boss
										</h2>
									</div>
									<div className="feature-description">
										Convert your topic tree into awesome content: switch into planning mode to tag, prioritize, and manage your entire pipeline in one place ~
									</div>
								</div>
								<div className="feature-image">
									<img className="feature-image-figure" src={imgFeaturePlanFigure} />
									<img src={imgFeaturePlan} className="feature-image-screen" alt="Content planner" />
								</div>
							</div>
							
							<div className="feature feature-write">
								<div className="feature-text">
									<div className="feature-title">
										<h2>
											Let AI help you <span className="feature-title-focus"><IconWrite className="feature-title-icon" /><span className="feature-highlight">draft content</span></span> in style
										</h2>
									</div>
									<div className="feature-description">
										Embrace human-machine collab: brainstorm headings and outlines in a click... then add, rewrite, and summarize text 10x faster ~
									</div>
								</div>
								<div className="feature-image">
									<img className="feature-image-figure" src={imgFeatureWriteFigure} />
									<img src={imgFeatureWrite} className="feature-image-screen" alt="Word drafter" />
								</div>
							</div>
							
						</div>
						
						
					
					</div>
					
					<div className="section front-section">
						<div className="section-japanese">
							<img className="section-japanese-text" src={imgJpnFrontBottom} />
						</div>
					</div>
					
					<div className="section front-section">
						
						<div className="section-prepricing">
						
							<h2>YOUR ENTIRE CONTENT PIPELINE, <span className="hero-highlight hero-highlight-purple">10x</span> BETTER</h2>
							
							<div className="section-prepricing-description">
								Transform the way you conceive, plan, produce, and optimize content. Change plans anytime.
							</div>
							
						</div>	
						
					</div>
					
					<div className="section front-section">
						
						<div className="section-horizontal section-tiers">
							
							<div className="tier tier-free">
								<div className="tier-image">
									<img className="tier-image-img" alt="Free" src={imgTierFree} />
								</div>
								<div className="tier-name">
									<div className="tier-name-container">
										<div className="tier-name-prefix">
											free
										</div>
										<div className="tier-name-main">
											droid
										</div>
									</div>
								</div>
								<div className="tier-price">
									<span className="tier-price-main">
										$0 forever
									</span>
									<span className="tier-price-extra">
										and ever
									</span>
								</div>
								<div className="tier-credits">
									<span className="tier-credits-number">
										10
									</span>
									<span>monthly credits</span>
									<div className="tooltip">
										<div className="tooltip-mark">
											?
										</div>
										<div className="tooltip-text">
											Credits are used for AI-enhanced actions such as brainstorming subtopics, creating headlines, and drafting content.
										</div>
									</div>
								</div>
								<div className="tier-perks">
									<div className="tier-perk">
										topic cluster maker
									</div>
									<div className="tier-perk">
										content planner
									</div>
									<div className="tier-perk">
										writing assistant
									</div>
									<div className="tier-perk">
										meta tag optimizer
									</div>
									<div className="tier-perk">
										SEO brainstorm mode
									</div>
									<div className="tier-perk">
										use any language
									</div>
								</div>
								<div className="tier-action">
									<button className="userbutton userbutton-free" type="button" onClick={(event) => scrollFocus(event)}>
										Try it now<span className="button-italic">!</span>
									</button>
								</div>
							</div>
							
							<div className="tier tier-pro">
								<div className="tier-image">
									<img className="tier-image-img" alt="Pro" src={imgTierPro} />
								</div>
								<div className="tier-name">
									<div className="tier-name-container">
										<div className="tier-name-prefix">
											+pro
										</div>
										<div className="tier-name-main">
											mecha
										</div>
									</div>
								</div>
								<div className="tier-price">
									<span className="tier-price-main">
										${billingMonthly ? '22' : '18'}/month
									</span>
									<span className="tier-price-extra">
										<span className={billingMonthly ? 'tier-billing-active' : 'tier-billing'} onClick={() => setBillingMonthly(!billingMonthly)}>annual</span>
										/
										<span className={billingMonthly ? 'tier-billing' : 'tier-billing-active'} onClick={() => setBillingMonthly(!billingMonthly)}>monthly</span>
									</span>
								</div>
								<div className="tier-credits">
									<span className="tier-credits-number">
										2000
									</span>
									<span>monthly credits</span>
									<div className="tooltip">
										<div className="tooltip-mark">
											?
										</div>
										<div className="tooltip-text">
											Credits are used for AI-enhanced actions such as brainstorming subtopics, creating headlines, and drafting content.
										</div>
									</div>
								</div>
								<div className="tier-perks">
									<div className="tier-everything">
										Everything in DROID and
									</div>
									<div className="tier-perk">
										7 content types
									</div>
									<div className="tier-perk">
										23 content formats
									</div>
									<div className="tier-perk">
										tone of voice mixer
									</div>
									<div className="tier-perk">
										priority support
									</div>
									<div className="tier-perk">
										$0.01/extra credit
									</div>
								</div>
								<div className="tier-action">
									<RequestInvite />
								</div>
							</div>
							
							<div className="tier tier-enterprise">
								<div className="tier-image">
									<img className="tier-image-img" alt="Enterprise" src={imgTierEnterprise} />
								</div>
								<div className="tier-name">
									<div className="tier-name-container">
										<div className="tier-name-main">
											enterprise
										</div>
									</div>
								</div>
								<div className="tier-price">
									<span className="tier-price-extra">
										from
									</span>
									<span className="tier-price-main">
										$38/user/month
									</span>
								</div>
								<div className="tier-credits">
									<span className="tier-credits-number">
										5000
									</span>
									<span>monthly credits</span>
									<div className="tooltip">
										<div className="tooltip-mark">
											?
										</div>
										<div className="tooltip-text">
											Credits are used for AI-enhanced actions such as brainstorming subtopics, creating headlines, and drafting content.
										</div>
									</div>
								</div>
								<div className="tier-perks">
									<div className="tier-everything">
										Everything in MECHA and
									</div>
									<div className="tier-perk">
										role management
									</div>
									<div className="tier-perk">
										single sign-on
									</div>
									<div className="tier-perk">
										custom limits
									</div>
									<div className="tier-perk">
										dedicated manager
									</div>
									<div className="tier-perk">
										pay by invoice
									</div>
								</div>
								<div className="tier-action">
									<button className="userbutton userbutton-enterprise" type="button" onClick={() => navigate('/contact?subject=enterprise')}>
										Talk to us<span className="button-italic">~</span>
									</button>
								</div>
							</div>
							
						</div>	
						
					</div>
					
					<div className="section front-section">
						
						<div className="section-stack">
													
							<div className="section-stack-description">
								Add a solid foundation to your existing content and SEO stack:
							</div>
							
							<div className="section-stack-logos">
								<img className="section-stack-hubspot" src={imgStackHubspot} />
								<img className="section-stack-moz" src={imgStackMoz} />
								<img className="section-stack-ahregs" src={imgStackAhrefs} />
								<img className="section-stack-semrush" src={imgStackSemrush} />
								<img className="section-stack-wordpress" src={imgStackWP} />
								<img className="section-stack-ga" src={imgStackGA} />
							</div>
							
						</div>	
						
					</div>
					
				</div>
				
			</div>
			
			<div className="footer footer-front">
				
				<div className="footer-items">
					
					<div className="footer-logo">
						<Link to={`${isauth ? '/app/dashboard' : '/'}`}>
							<Logo className="logo" />
						</Link>
					</div>
				
					<div className="footer-menu">
						<Link className="footer-menu-link footer-menu-link-contact" to='/contact'>Contact us</Link>
						<Link className="footer-menu-link footer-menu-link-affiliates" to='/contact?subject=affiliates'>Affiliates</Link>
						<Link className="footer-menu-link footer-menu-link-blog" to='/blog'>Blog</Link>
						<Link className="footer-menu-link footer-menu-link-terms" to='/terms' target="_blank">Terms of use</Link>
						<Link className="footer-menu-link footer-menu-link-privacy" to='/privacy' target="_blank">Privacy policy</Link>
					</div>
					
				</div>
				
				<div className="gradient gradient-bottom">
					<div className="gradient-blue">
					</div>
					<div className="gradient-red">
					</div>
					<div className="gradient-green">
					</div>
				</div>
				
			</div>
			
		</div>
	);
}

export default Home;