import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { Message } from '../components/messages';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { useProtectedFetch } from '../components/fetch';
import { sendEmail } from '../apis/email'; 
import { useGA4React } from '../apis/googleanalytics';

// Create new user

export const Register = ({ fullpage=true }) => {
	
	const { isdemo, setIsDemo } = useUserContext();
	const { trackEvent } = useGA4React();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [iserror, setIsError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
	const [showForm, setShowForm] = useState(true);
	
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const queryInvite = queryParams.get('invite');
	
	const handlePasswordBlur = () => {
		if (password.length < 6) {
			setPasswordError(true);
			if ( password.length > 0 ) {
				setPasswordErrorMessage("Let's make it at least 6 characters long ~");
			}
		} else {
			setPasswordError(false);
			setPasswordErrorMessage("");
		}
	}

	const handleRegisterSubmit = async (e) => {
		e.preventDefault();
		if (passwordError) {
			console.log("Cannot submit due to insufficient password length.");
			return;
		}
		let demoid = null;
		let demoDataParsed = null;
		const demoData = localStorage.getItem('demodata');	
		if ( demoData ) {
			try {
				demoDataParsed = JSON.parse(demoData);
				demoid = demoDataParsed.demoid;
			} catch(e) {
				console.error("Error parsing JSON from localStorage:", e);
			}
		}
		
		let utmParamsObject = null;
		const utmParamsString = localStorage.getItem('utmParams');
		if (utmParamsString) {
			utmParamsObject = JSON.parse(utmParamsString);
		}
		
		if ( queryInvite === 'pro' ) {
			const emailData = {
				to: 'hello@satoristudio.net',
				reply: email, 
				subject: 'Pro Invite',
				text: `User: ${email}`
			};
			try {
				const result = await sendEmail(emailData);
			} catch (error) {
				console.error('Error sending email:', error.message);
			}
		}			
		try {
			const response = await fetch('/api/register', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email: email,
					password: password,
					demoid: demoid,
					invite: queryInvite,
					utmparams: utmParamsObject,
				})
			});
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${JSON.stringify(response)}`);
			}
			const responseData = await response.json();
			setMessage(responseData.message);
			if ( responseData.success ) {
				trackEvent({
					category: 'retention',
					action: 'signup',
					label: demoid,
					loc: window.location.pathname,
				});
				setIsError(false);
				setShowForm(false);
			} else {
				setIsError(true);
			}
		} catch (error) {
			setIsError(true);
			console.error('Registration error:', error);
			setMessage('(ಠ︹ಠ)   Registration failed, please try a different email address or contact us ~');
		}
	};

	const content = (
		
		<div className="auth-wrap register-wrap">
			
			<h1>Create account<span className="blinking-cursor">{'\u25ae'}</span></h1>
			
			{isdemo && (
				<div className="auth-description register-description">
					How about a <em>respawn point</em>? Create an account to save your progress and create more topics, projects, and content drafts!
				</div>
			)}
			
			{message && <div className={`form-message ${iserror ? 'form-error' : ''}`}><span className="form-message-text">{message}</span></div>}
			
			{showForm && (
			<div className="auth-container register-container">
				<form className="form-user form-register" onSubmit={handleRegisterSubmit}>
					<div className="form-field form-auth-field form-auth-field-email">
						<label>Email:</label>
						<input
							type="email"
							value={email}
							placeholder="You will use it to log in" 
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					<div className="form-field form-auth-field form-auth-field-password">
						<label>Password:</label>
						<input
							type="password"
							placeholder="At least 6 symbols please" 
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							onBlur={handlePasswordBlur} 
							required
						/>
						{passwordErrorMessage && (
						<div className="form-message form-error password-error"><span className="form-message-text">{passwordErrorMessage}</span></div>
						)}
					</div>
					<button type="submit">Sign up</button>
				</form>			
			</div>
			)}
			
		</div>
	
	);
	
	return (
	
		<>
		{fullpage ? <Wrapper type="register">{content}</Wrapper> : content}	
		</>
		
    );
  
};

// Handle email confirmation link at signup

export const Confirm = () => {
    
	const [message, setMessage] = useState('Confirming your email address...');
	const [iserror, setIsError] = useState(false);
	const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
	const { 
		isauth, setIsAuth, 
		user, 
		userid, setUserID, 
		updateUser, 
		isdemo, setIsDemo, 
		setModalWelcome, 
		setTopicID, 
		setContentID, 
		accesstoken, setAccessToken
	} = useUserContext();
	const gotourl = useNavigate();
	const protectedFetch = useProtectedFetch();

    useEffect(() => {
		
        const ConfirmEmail = async () => {
            try {
                const response = await fetch(`/api/confirm-email`, {
                    method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ confirmationToken: token })
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message);
                }
				if (responseData.success) {					
					setIsError(false);
				}
				setMessage(responseData.message);
				if (responseData.success) {
					
					setIsAuth(true);
					setAccessToken(responseData.token);
					localStorage.setItem('accessToken', responseData.token);
					setUserID(responseData.userid);
					
				}
            } catch (error) {
				setIsError(true);
                console.error('Email confirmation error:', error);
				if ( error ) {
					setMessage(error.message);
				} else {
					setMessage('(*︵*)   Failed to confirm email... please try again or contact us ~');
				}
            }
        };

        if (token) {
            ConfirmEmail();
        } else {
			setIsError(true);
            setMessage('╮(●︿●)╭   Invalid or missing confirmation token. Check your link perhaps?');
        }
		
    }, [token]);
	
	useEffect(() => {
		
		const SetupAccount = async () => {
		
			if ( !isauth || !accesstoken || !userid ) {
				return;
			}
			
			let firsttopic = null;
			let firstproject = null;
			try {
				firstproject = await firstProject(userid, protectedFetch);
				if (firstproject && firstproject._id) {
					updateUser({ projectid: firstproject._id });
					setContentID(null);
				}
			} catch (error) {
				console.error('First project creation error:', error);
			}
			
			if ( firstproject ) {
				try {
					firsttopic = await firstTopic(firstproject._id, userid, protectedFetch);
					if (firsttopic && firsttopic._id) {
						setTopicID(firsttopic._id);
					}
				} catch (error) {
					console.error('First topic creation error:', error);
				}
			}
			
			if ( isdemo ) {
				setModalWelcome(true);
				setIsDemo(false);
				localStorage.removeItem('demodata');
			}
			
			if ( user.lastpage && firsttopic ) {
				gotourl(user.lastpage);
			} else {
				gotourl("/app/dashboard");
			}
			
		};
		
		SetupAccount();
		
	}, [isauth, accesstoken, userid]);

    return (
		
		<div className="page page-wrap confirmation-wrap centered-wrap">
		
			<div className="header header-confirmation">
				
				<div className="header-logo">
					<Link to={`${isauth ? '/app/dashboard' : '/'}`}>
						<Logo className="logo" />
					</Link>
				</div>
			
			</div>
				
			<div className="primary">
	
				<div className="centered-container email-confirmation-container">
					<h1>Email Confirmation<span className="blinking-cursor">{'\u25ae'}</span></h1>
					<div className={`form-message ${iserror ? 'form-error' : ''}`}><span className="form-message-text">{message}</span></div>
				</div>
			
			</div>
			
			<div className="gradient gradient-bottom">
				<div className="gradient-blue">
				</div>
				<div className="gradient-red">
				</div>
			</div>
		
		</div>
				
    );
	
};

// Login page/form

export const Login = ({ fullpage=true }) => {
    
	const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
	const [iserror, setIsError] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
	const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
	const { user, isauth, setIsAuth, userid, setUserID, updateUser, setAccessToken, refreshToken, isdemo, setIsDemo, setContentID, setTopicID } = useUserContext();
	const gotourl = useNavigate();
	const protectedFetch = useProtectedFetch();

    const HandleLoginSubmit = async (e) => {
		
        e.preventDefault();
		
        try {
			
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.message);
            }
			
			if (responseData.success) {
				
				setIsError(false);
				setIsAuth(true);
				localStorage.setItem('accessToken', responseData.token);
				setAccessToken(responseData.token);
				setUserID(responseData.userid);
				
				setIsDemo(false);
				localStorage.removeItem('demodata');
				
				if ( user.lastpage && ( typeof user.lastpage === 'string' || user.lastpage instanceof String ) ) {
					console.log(`Returning to ${user.lastpage}`);
					gotourl(user.lastpage);
				} else {
					gotourl("/app/dashboard");
				}
				
			} else {
				setIsError(true);
			}				
            setMessage(responseData.message);

        } catch (error) {
			setIsError(true);
            setMessage(error.message);
        }
		
    };
	
	const requestPasswordReset = async (email) => {       
		try {
            const response = await fetch('/api/password-reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });
            const responseData = await response.json();
            if (!response.ok) {
				setIsError(true);
				setForgotPasswordMessage('〈◕﹏◕〉 Ouf, something went wrong when requesting password reset; please try refreshing the page and doing this again?');
                throw new Error(responseData.message || 'Error requesting password reset.');				
            } else {
				setIsError(false);
				setForgotPasswordMessage(responseData.message);
			}
        } catch (error) {
			setIsError(true);
            console.error('Error:', error);
            setForgotPasswordMessage(error.message || 'Error in requesting password reset.');
        }		
    };
	
	const handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();
        await requestPasswordReset(forgotPasswordEmail);
    };
	
	const content = (
	
		<div className="auth-wrap login-wrap">
			
			{showForgotPassword ? (
			
			<>
			
				<h1>Reset password<span className="blinking-cursor">{'\u25ae'}</span></h1>
				
				{forgotPasswordMessage && (
					<div className={`form-message ${iserror ? 'form-error' : ''}`}>
						<span className="form-message-text">{forgotPasswordMessage}</span>
					</div>
				)}
				
				<div className="auth-container forgot-container">
					<form className="form-user form-forgot" onSubmit={handleForgotPasswordSubmit}>
						<div className="form-field form-auth-field form-auth-field-email">
							<label>Email:</label>
							<input 
								type="email"
								required
								value={forgotPasswordEmail} 
								onChange={(e) => setForgotPasswordEmail(e.target.value)} 
								placeholder="You'll get a reset link" 
							/>
						</div>
						<button type="submit">Reset Password</button>
					</form>
				</div>
				
			</>
				
			) : (
			
			<>
			
				<h1>Log in<span className="blinking-cursor">{'\u25ae'}</span></h1>
				
				{isdemo && (
					<div className="auth-description login-description">
						<em>Suit up</em>, commander, it's action time! Log in to ensure your projects, topics, and content is not lost to posterity ~  
					</div>
				)}
				
				{message && <div className={`form-message ${iserror ? 'form-error' : ''}`}><span className="form-message-text">{message}</span></div>}
				
				<div className="auth-container login-container">
					<form className="form-user form-login" onSubmit={HandleLoginSubmit}>
						<div className="form-field form-auth-field form-auth-field-email">
							<label>Email:</label>
							<input
								type="email"
								value={email}
								placeholder="Identify yourself please" 
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>
						<div className="form-field form-auth-field form-auth-field-password">
							<label>Password:</label>
							<input
								type="password"
								placeholder="Your secret code, captain" 
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
							<div className="forgot-password-link">
								<a href="#" onClick={() => setShowForgotPassword(true)}>Forgot Password?</a>
							</div>
						</div>
						<button type="submit">Log in</button>
					</form>			
				</div>
				
			</>
				
			)}
			
		</div>
	
	);

    return (
	
		<>
		{fullpage ? <Wrapper type="login">{content}</Wrapper> : content}	
		</>
		
    );
	
};

// Render logout link

export const LogoutLink = ({ className='' }) => {
    
	const { setIsAuth, setUserID } = useUserContext();
    const gotourl = useNavigate();
	
	async function logoutUser(setIsAuth, setUserID) {
		try {
			const response = await fetch('/api/logout', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				credentials: 'include'
			});
			if (response.ok) {
				const data = await response.json();
				if (data.success) {
					setIsAuth(false);
					setUserID(null);
					localStorage.removeItem('accessToken');
					gotourl("/login"); 
				} else {
					console.error(data.message);
				}
			} else {
				console.error("Server responded with status:", response.status);
			}
		} catch (error) {
			console.error("Error logging out:", error);
		}
	}

    return (
		<a href="#" className={className} onClick={() => logoutUser(setIsAuth, setUserID)}>Logout</a>
    );
	
};

// Reset password form

export const Reset = () => {
    
	const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
	const { isauth } = useUserContext();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const navigate = useNavigate();
	
    const handlePasswordChange = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/password-change', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, newPassword })
            });
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message);
            }
            setMessage(responseData.message);
            setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
            setIsError(true);
            setMessage(error.message || 'Error resetting password.');
        }
    };

    return (
	
		<div className="page page-wrap reset-wrap centered-wrap">
			
			<div className="header header-reset">
				
				<div className="header-logo">
					<Link to={`${isauth ? '/app/dashboard' : '/'}`}>
						<Logo className="logo" />
					</Link>
				</div>
			
			</div>
				
			<div className="primary">
			
				<div className="centered-container reset-container">
					
					<h1>Password Reset<span className="blinking-cursor">{'\u25ae'}</span></h1>
					
					<form className="form-user form-reset" onSubmit={handlePasswordChange}>
						<div className="form-field form-auth-field form-auth-field-password">
							<label>New password:</label>
							<input
								type="password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeholder="At least 6 symbols, remember?"
								required
							/>
						</div>
						<button type="submit">Reset Password</button>
					</form>
					
					<div className={`form-message ${isError ? 'form-error' : ''}`}>
						<span className="form-message-text">{message}</span>
					</div>
					
				</div>
				
			</div>
			
			<div className="gradient gradient-bottom">
				<div className="gradient-blue">
				</div>
				<div className="gradient-red">
				</div>
			</div>
						
        </div>
		
    );
	
};

// Create new project
	
const firstProject = (userid, protectedFetch) => {
	if ( !userid ) {
		return;
	}
	return protectedFetch('/api/project', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			userID: userid,
			name: 'default project',
		})
	})
	.then(response => {
		if ( !response.ok ) {
			console.error('Network response was not ok while creating new project:', response);
			return Promise.reject('Network response was not ok while creating project');
		}
		return response.json();
	})
	.then(project => {
		return project;
	})
	.catch((error) => {
		console.error('Project update error:', error);
		return null;
	});
};

// Create new topic
	
const firstTopic = (projectid, userid, protectedFetch) => {
	const demodata = localStorage.getItem('demodata');
	const demodataparsed = JSON.parse(demodata);
	if ( !projectid || !userid || !demodata ) {
		return null;
	}	
	return protectedFetch('/api/topic', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			userID: userid,
			projectID: projectid,
			seed: demodataparsed.seedvalue,
            topictree: demodataparsed.topicdata,
		})
	})
	.then(response => {
		if ( !response.ok ) {
			console.error('Network response was not ok while creating first topic:', response);
			return Promise.reject('Network response was not ok while creating first topic');
		}
		return response.json();
	})
	.then(topic => {
		return topic;
	})
	.catch((error) => {
		console.error('Topic update error:', error);
		return null;
	});
};

const Wrapper = ({ children, type }) => {
	
	const { isauth, setIsAuth, setIsDemo } = useUserContext();
	
	return (
		
		<div className={`page page-wrap ${type}-wrap centered-wrap`}>
	
			<div className={`header header-${type}`}>
				
				<div className="header-logo">
					<Link to='/' onClick={() => { setIsAuth(false); setIsDemo(false); }}>
						<Logo className="logo" />
					</Link>
				</div>
			
			</div>
				
			<div className="primary">				
				<div className={`centered-container ${type}-container`}>				
					{children}						
				</div>					
			</div>
			
			<div className="gradient gradient-bottom">
				<div className="gradient-blue">
				</div>
				<div className="gradient-red">
				</div>
			</div>
		
		</div>
			
	);

};


// Request invite
	
export const RequestInvite = () => {
	
	const { isauth, userid } = useUserContext();
	const { trackEvent } = useGA4React();
    const gotourl = useNavigate();
	const [mailsuccess, setMailSuccess] = useState(false);
	const [invitesent, setInviteSent] = useState(false);
	
	const handleInvite = async(event) => {
		event.preventDefault();
		let eventlabel = '';
		if ( userid ) {
			eventlabel = userid;
		}
		trackEvent({
			category: 'conversion',
			action: 'invite',
			label: eventlabel,
			loc: window.location.pathname,
		});
		if ( isauth ) {
			const emailData = {
				to: 'hello@satoristudio.net',
				reply: 'hello@satoristudio.net', 
				subject: 'Pro invite request',
				text: `User: ${userid}`
			};
			try {
				const result = await sendEmail(emailData);
				setInviteSent(true);
				if (result.success) {
					setMailSuccess(true);
				}
			} catch (error) {
				console.error('Error sending email:', error.message);
			}
		} else {
			gotourl('/signup?invite=pro');
		}
	};

	return (
		
		invitesent ? (
			<div className="invite-sent">
				{mailsuccess ? (
					<span className="invite-sent-success">(ꈍ‿ꈍ)  Appreciate that! We will email you shortly</span>
				) : (
					<span className="invite-sent-fail">〈◕﹏◕〉  Something went wrong! Please <a href="/contact">contact us</a></span>
				)}
			</div>
		) : (
			<button className="userbutton userbutton-pro" type="button" onClick={(event) => handleInvite(event)}>
				Get invite<span className="button-italic">!</span>
			</button>
		)
	
	);
	
};