import React, { useState, useEffect } from 'react';
import { useUserContext } from '../contexts/user';
import { generateChecksum } from './checksum';

export const CREDIT_LIMITS = {
	demo: 5,
	free: 10,
	pro: 1000,
};

export const useCredits = () => {
	
	const { 
		isdemo, setIsDemo, 
		democount, setDemoCount, 
		modalsignup, setModalSignup,
		currentplan, setCurrentPlan,
		user, updateUser
	} = useUserContext();
	
	// Update demo credits if demo
	
	let demoDataParsed = null;
	if ( isdemo ) {		
		const demoData = localStorage.getItem('demodata');
		if ( demoData ) {
			try {
				demoDataParsed = JSON.parse(demoData);
			} catch(e) {
				localStorage.removeItem('demodata');
				console.error("Error parsing JSON from localStorage:", e);
			}
		} else {
			setDemoCount(0);
		}
	}
	
	// Deduct credits from actions
	
	const deductCredits = (amount) => {	
		let newCredits = null;
		if (isdemo) {			
			if ( !democount ) {
				const newChecksum = generateChecksum(demoDataParsed.seedvalue, demoDataParsed.count);
				if ( newChecksum === demoDataParsed.session ) {
					newCredits = demoDataParsed.count + 1;
				} else {
					setModalSignup(true);
					return false;
				}
			} else {
				newCredits = Math.max(0, democount - amount);
			}			
			setDemoCount(newCredits);
			demoDataParsed.count = newCredits;
			demoDataParsed.session = generateChecksum(demoDataParsed.seedvalue, newCredits);       
			localStorage.setItem('demodata', JSON.stringify(demoDataParsed));
		} else {
			newCredits = Math.max(0, user.credits - amount);
			updateUser({ credits: newCredits });
		}
	};
	
	// Check if user has enough credits to perform an action
	
	const hasEnoughCredits = (amount) => {
		let hasEnough = false;
		if ( isdemo ) {
			hasEnough = democount >= amount;
		} else {
			hasEnough = user.credits >= amount;
		}
		return hasEnough;
	};
	
	return { deductCredits, hasEnoughCredits };
	
};

export const Credits = () => {
	
	const { 
		user,
		isauth, 
		isdemo, 
		democount,
		currentplan,
		setModalSignup,
		setModalUpgrade,
		doneloading
	} = useUserContext();
	
	// Show  modal if no more credits
		
	const handleUpgradeClick = (event) => { 
		event.preventDefault();
		if ( isdemo && !isauth ) {			
			setModalSignup(true);
		} else {
			setModalUpgrade(true);
		}
		return;
	};
	
	// Calculate total credits
	
	const currentCredits = () => {
		if ( isdemo ) {
			return (democount ? democount : 0);
		} else {
			return (user.credits ? user.credits : 0);
		}
	};
	
	return (
	
		doneloading ? (
		
			<div className={`navbar-credits ${currentCredits() > 0 ? 'navbar-credits-active' : 'navbar-credits-depleted'}`}>
				<span className="navbar-credits-desc">
					{isdemo ? 'Demo credits left:' : 'Credits:'}
				</span>
				<div className="navbar-credits-count">
					{isdemo ? democount : user.credits}<span className="navbar-credits-count-limit">/{CREDIT_LIMITS[currentplan]}</span>
				</div>			
				{currentCredits() < 1 && (
					isdemo ? ( 
						<div className="navbar-message navbar-demo-message">
							Please <a className="navbar-action-link" href='#' onClick={(event) => handleUpgradeClick(event)}>create an account</a> to continue ~
						</div>
					) : (
						currentplan === 'free' ? (
							<div className="navbar-message navbar-upgrade-message">
								<div className="navbar-action-link" onClick={(event) => handleUpgradeClick(event)}>Upgrade to Pro</div> to get {CREDIT_LIMITS.pro} credits and more cool stuff ~
							</div>
						) : (
							<div className="navbar-message navbar-topup-message">
								Please purchase additional credits to continue ~
							</div>
						)
						
					)
					
				)}
			</div>
			
		) : (
			
			<span className="loading-indicator"></span>
			
		)
		
	);
	
};