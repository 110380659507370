import { Quill } from "react-quill";

import { ReactComponent as Undo } from '../assets/editor-undo.svg';
import { ReactComponent as Redo } from '../assets/editor-redo.svg';

function undoChange() {
	this.quill.history.undo();
}
function redoChange() {
	this.quill.history.redo();
}

export const modules = {
	toolbar: {
		container: "#toolbar",
		handlers: {
			undo: undoChange,
			redo: redoChange
		}
	},
	history: {
		delay: 500,
		maxStack: 100,
		userOnly: true
	}
};

export const formats = [
	"header",
	"bold",
	"italic",
	"underline",
	"script",
	"list",
	"bullet",
	"link",
	"code-block",
	//"font",
	//"size",
	//"image",
	//"color",
	//"indent",
	//"blockquote",
	//"background",
	//"align",
	//"strike",
];

export const QuillToolbar = () => (
	<div id="toolbar">
		<span className="ql-formats">
			<select className="ql-header" defaultValue="4">
				<option value="1">Heading 1</option>
				<option value="2">Heading 2</option>
				<option value="3">Heading 3</option>
				<option value="4">Normal</option>
			</select>
		</span>
		<span className="ql-formats">
			<button className="ql-bold ql-custom-icon" />
			<button className="ql-italic ql-custom-icon" />
			<button className="ql-underline ql-custom-icon" />
			<button className="ql-list ql-custom-icon" value="ordered" />
			<button className="ql-list ql-custom-icon" value="bullet" />
			<button className="ql-link ql-custom-icon" />
			<button className="ql-code-block ql-custom-icon" />
			<button className="ql-clean ql-custom-icon" />
			<button className="ql-undo">
				<Undo />
			</button>
			<button className="ql-redo">
				<Redo />
			</button>
		</span>
	</div>
);

export default QuillToolbar;