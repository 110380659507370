const login = "ashekhirev@gmail.com";
const pass = "de27a1f2165d75ca"

const apiDataForSEO = async (keyword, type, loc, lang, lim, exact) => {
	
	const postData = [{
		"include_serp_info": false,
	}];
	if ( typeof keyword === 'string' ) {
		keyword = keyword.toLowerCase().replace(/[^\w\s-]|_/g, ' ').replace(/\s+/g, ' ').trim();
		postData[0].keyword = keyword;
		postData[0].order_by = ["impressions_info.daily_impressions_average,desc"];
		postData[0].filters = ["keyword_info.search_volume", ">", 100];
		postData[0].exact_match = exact;
		postData[0].limit = lim;
		postData[0].include_seed_keyword = true;
	} else {
		postData[0].keywords = keyword;
	}
	if (loc) {
		postData[0].location_name = loc;
	}
	if (lang) {
		postData[0].language_name = lang;
	}
	try {
		
		const url = "https://api.dataforseo.com/v3/dataforseo_labs/google/"+type+"/live";
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Authorization": `Basic ${btoa(`${login}:${pass}`)}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(postData),
		});
		
		if (!response.ok) {
			throw new Error("DataForSEO API network response was not ok");
		}
		const data = await response.json();
		
		return data;
		
	} catch (error) {
		
		console.error("DataForSEO API error: ", error);
		return null;
		
	}
};

const getSEOKeywords = async (initkeyword, type="keyword_suggestions", loc, lang, lim=200, exact=false) => {
	
	if ( loc === "World" ) {
		loc = null;
	}
	if ( lang === "All languages" ) {
		lang = null;
	}
	
	try {
		
		const response = await apiDataForSEO(initkeyword, type, loc, lang, lim, exact);
		
		const keywords = response?.tasks?.[0]?.result?.[0]?.items || [];
		const totalVolArray = keywords.map(item => item.keyword_info?.search_volume || 0);
		const totalImpArray = keywords.map(item => item.impressions_info?.daily_impressions_average * 30 || 0);
		totalVolArray.sort((a, b) => b - a);
		totalImpArray.sort((a, b) => b - a);
		const totalVolCutoff = totalVolArray[lim / 2];
		const totalImpCutoff = totalImpArray[lim / 2];
		const keywordsMap = {};		
		
		keywords.forEach(item => {
			
			if (
				item.keyword.includes('.') ||
				item.keyword.includes('-') ||
				item.keyword.includes(':')
			) {
				return;
			}
			
			const keyw = item?.keyword || '';
			const diff = item.keyword_properties?.keyword_difficulty || 0;
			const intent = item.search_intent_info?.main_intent || '';
			const searchVol = item.keyword_info?.search_volume || 0;
			const avgImpDaily = item.impressions_info?.daily_impressions_average || 0;
			const avgImp = avgImpDaily * 30;
			
			if (searchVol < totalVolCutoff + 1 || avgImp < totalImpCutoff + 1) {
				return;
			}
			
			if (keywordsMap[keyw]) {
				keywordsMap[keyw].intVol += searchVol;
				keywordsMap[keyw].intImp += avgImp;
				keywordsMap[keyw].difficulty = Math.max(keywordsMap[keyw].difficulty, diff);
				keywordsMap[keyw].intent = (keywordsMap[keyw].intent === "navigational" || intent === "navigational") ? "navigational" : intent; 
			} else {
				keywordsMap[keyw] = {
					key: keyw,
					volume: Math.round((avgImp + searchVol) / 2, -2),
					difficulty: diff,
					intent: intent,
					intVol: searchVol,
					intImp: avgImp,
				};
			}
			
		});
		const keywordsArr = Object.values(keywordsMap);
		const sortedKeywordsArr = keywordsArr.sort((a, b) => b.volume - a.volume); 
				
		return sortedKeywordsArr;
		
	} catch (error) {
		
		console.error("Failed to fetch keywords: ", error);
		
	}
}

export default getSEOKeywords;
