const apiScrapeUrl = async (url, chars=250) => {
	const standardizeUrl = (inputUrl) => {
		if (!/^https?:\/\//i.test(inputUrl)) {
			return `http://${inputUrl}`;
		}
		return inputUrl;
	};
	const stdurl = standardizeUrl(url);
	try {
		const response = await fetch(`/scrape?url=${encodeURIComponent(stdurl)}`);
		console.log(response);
		const html = await response.text();		
		const parser = new DOMParser();
		const dom = parser.parseFromString(html, 'text/html');
		const metaDesc = dom.querySelector("meta[name='description']");
		if (metaDesc) {
			return metaDesc.getAttribute('content');
		} else {
			const nodes = dom.evaluate(
				'//*[not(self::script or self::style)]/text()[normalize-space(.) != ""]',
				dom,
				null,
				XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
				null
			);
			let content = '';
			for (let i = 0; i < nodes.snapshotLength; i++) {
				content += ' ' + nodes.snapshotItem(i).nodeValue.trim();
			}
			return content.substring(0, chars);
		}
	} catch (error) {
		console.error('Error fetching the URL content:', error);
		return '';
	}
};

export default apiScrapeUrl;