import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataContext } from '../contexts/data';
import { useUserContext } from '../contexts/user';
import { useProtectedFetch } from './fetch';
import { generateChecksum } from './checksum';
import { CREDIT_LIMITS } from './credits'; 
import { useGA4React } from '../apis/googleanalytics';
import apiOpenAI from '../apis/openai';
import apiScrapeUrl from '../apis/scrape';

import { ReactComponent as Logomark } from '../assets/logomark.svg';

// Process seed into topic tree

const handleSeed = async(seed, location, updateData, seomode) => {
	
	const pathSegments = location.pathname.split('/').filter(Boolean);
	const confirmation = pathSegments.length >= 2 && (pathSegments[0] === 'app') && (pathSegments[1] === 'plan' || pathSegments[1] === 'write');
	
	// In case we're on the plan or writing view, inform the user what's about to happen

	if ( confirmation ) {
		const isConfirmed = window.confirm("(╭ರ_•́)  This will create a new topic tree and content plan, do you want to continue?");
		if (!isConfirmed) {
			return;
		} else {
			alert('ⅽ(٥¯∻¯ ٥)ↄ  alright, let me think for a moment...');
		}
	}
	
	updateData({ isloading: true });				
		
	try {
		
		// Determine user input type (keyword, description, or url)
		
		const response = await apiOpenAI(
			'is this a search keyword (reply "1"), a business description (reply "2"), or a web address (reply "3"): "' + seed + '"',
		);
		const seedtype = parseInt(response, 10);
		let convseed = seed;
		let urlexcerpt = '';

		// Parse url and get description
		
		if (seedtype === 3) {
			urlexcerpt = await apiScrapeUrl(seed);
			convseed = urlexcerpt;
		}
		
		// Generate parent topic from description
		
		let parenttopic = '';
		if (seedtype === 1) {
			parenttopic = seed;
		} else if (seedtype > 1 && convseed) {
			const response = await apiOpenAI(
				'Business description: "' + convseed + '". Primary 2-word keyphrase: "',
				0.6,
				50,
				['.', '"'],
			);
			parenttopic = response.replace(/["'.]/g, '').trim().toLowerCase();
		} else {
			parenttopic = seed;
		}
		
		// Brainstorm subtopics from parent topic
		
		if (parenttopic) {
			
			let subtopicstring = 'Subtopic search keywords';
			if ( !seomode ) {
				subtopicstring = 'Short subtopics for a content campaign';
			}
			
			const response = await apiOpenAI(
				'Parent topic: "' + parenttopic + '". ' + subtopicstring + ' (max 10):',
				0,
				300,
			);
			const subItemsArray = response.split(/[\n,]+/).map((item) => 
				item.replace(/^\d+(\.\s*|\(\)\s*|\s+)/, "") // remove numbering
					.replace(/["'.]/g, "") // remove all quotes
					.replace(/^(\s?and\s)/, "") // remove the conjunctive "and"
					.replace(/-\s+/g, "") // remove dash bullets
					.trim()
					.toLowerCase()
			);		
			return { parent: parenttopic, children: subItemsArray };
			
		} else {
			return null; 
		}

	} catch (error) {
		console.error('Failed to fetch data from apiOpenAI: ', error);
	}

}; 

// Create demo entry

const saveDemo = async (userID, seed) => {
    try {
        const response = await fetch('/api/demo', {
			method: 'POST',
			headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userID, seed })
        });
        if (!response.ok) {
            throw new Error(`HTTP error saving demo! status: ${response}`);
        } else {
            const newdemo = await response.json();
			return newdemo;
        }
    } catch (error) {
        console.error('Demo save error:', error);
    }
};

// Render seed input field

function Seed({ action=null }) {
	
	const { updateData } = useDataContext();
	const { 
		user, updateUser, 
		userid, setUserID,
		isdemo, setIsDemo, 
		democount, setDemoCount,
		isauth, setIsAuth, 
		setModalWelcome,
		setModalSignup,
		setTopicID, 
		setContentID 
	} = useUserContext();
	const userSeedInputRef = useRef(null);
	const userSeedSubmitRef = useRef(null);
	const protectedFetch = useProtectedFetch();
	const [seedInput, setSeedInput] = useState('');
	
	const [navigateUrl, setNavigateUrl] = useState(null);
	const location = useLocation();
	const gotourl = useNavigate();
	const { trackEvent } = useGA4React();
	
	useEffect(() => {
		if (navigateUrl) {
			gotourl(navigateUrl);
		}
	}, [navigateUrl, gotourl]);

	const seedInputPress = (event) => {
		const regex = /^[A-Za-z0-9 '",.\-%$\/\?:@&_+#]+$/;
		const controlKeys = [
			'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End'
		];
		if (event.key === 'Enter') {
			event.preventDefault();
			handleSeedSubmit(seedInput);
		} else if (controlKeys.indexOf(event.key) !== -1) {
			return;
		} else if (!regex.test(event.key)) {
			event.preventDefault();
		}
	};
	
	const handleInputChange = (event) => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		} else {
			setSeedInput(event.target.value);
		}
	};
		
	const handleSeedSubmit = async(seedInput) => {
		
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		}
		
		const validseed = seedInput.replace(/[^A-Za-z0-9 '",.\-%$\/\?:@&_+#]/g, '');
		if ( validseed === '' || validseed === ' ' ) {
			alert("（；¬＿¬)  uh, oh, try entering something...");
			return;
		}
		
		const checksum = generateChecksum(validseed, 0);
		let demoID = null;
		let newDemo = null;
		
		try {
			newDemo = await saveDemo(null, validseed);
			if (newDemo && newDemo._id) {
				demoID = newDemo._id;
			}
		} catch (error) {
			console.error('Demo save error:', error);
		}
		
		let localdemo = {
			seedvalue: validseed, 
			count: CREDIT_LIMITS.demo,
			session: checksum,
			demoid: demoID,
		};
		localStorage.setItem('demodata', JSON.stringify(localdemo));
		
		updateData({ isloading: true });
		setIsDemo(true);
		setModalWelcome(true);
		setDemoCount(CREDIT_LIMITS.demo);
		setTopicID(null);
		setContentID(null);
		
		gotourl('/app/topics');
		
		const newtopicdata = await handleSeed(validseed, location, updateData, user.seomode);

		if ( !newtopicdata ) {
			alert("（；¬＿¬)  snap, something went wrong - please try again!");
			localStorage.removeItem('demodata');
			return;
		}
		
		const newTopicData = {
			name: newtopicdata.parent,
			added: new Date().toISOString(),
			expanded: true,
			children: newtopicdata.children.map((subItem) => ({ 
				name: subItem, 
				expanded: false,
			})),
		};
				
		updateData({ 
			seedvalue: validseed, 
			topicdata: newTopicData, 
			isloading: false 
		});
		
		localdemo = {
			...localdemo,
			topicdata: newTopicData
		};
				
		localStorage.setItem('demodata', JSON.stringify(localdemo));
		
		if ( action === 'home' ) {
			trackEvent({
				category: 'interest',
				action: 'demo',
				label: demoID,
				loc: window.location.pathname,
			});
		}
				
	};
	
	// Render seed input
	
	return (
	
		<div className="seed">
			<input ref={userSeedInputRef} id="input-seed" className="userinput userinput-main" type="text" placeholder={( isauth || isdemo ) ? "New topic tree: input a keyword, a topic, a free-form description, or a URL" : "Your topic, keyword, description, or url ~"} onKeyPress={seedInputPress} onChange={handleInputChange} value={seedInput} />
			<button ref={userSeedSubmitRef} onClick={(e) => handleSeedSubmit(seedInput)} className="userbutton userbutton-find" type="button">
				{( isauth || isdemo ) ? ( 
				<Logomark className="search-logo" />
				) : (
				<span className="button-inner">Initiate<span className="button-italic">!</span></span>
				)}
			</button>
		</div>
	
	);
	
}

export default Seed;