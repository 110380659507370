import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { LogoutLink } from '../pages/authentication';
import { Credits } from './credits';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Logomark } from '../assets/logomark.svg';

function Navbar() {
	
	const { user, updateUser } = useUserContext();
	const { isauth, setIsAuth } = useUserContext();
	const { isdemo, setIsDemo } = useUserContext();
	const { modalsignup, setModalSignup } = useUserContext();
	const { modallogin, setModalLogin } = useUserContext();
	const { democount, setDemoCount } = useUserContext();
	
	const handleSignupClick = (event) => { 
		if ( isdemo && !isauth ) {
			event.preventDefault();
			setModalSignup(true);
			return;
		}
	};
	
	const handleLoginClick = (event) => { 
		if ( isdemo && !isauth ) {
			event.preventDefault();
			setModalLogin(true);
			return;
		}
	};
	
	return (
		
		<div className="navbar-links">
						
			<div className="navbar-logo">
				<Link to={`${isauth ? '/app/dashboard' : '/'}`}>
					<Logo className="logo" />
				</Link>
			</div>
			
			{isauth ? (
				<div className='navbar-menu navbar-menu-auth'>
					<Link className="navbar-menu-link navbar-menu-link-account" to='/app/account'>Account</Link>
					<Link className="navbar-menu-link navbar-menu-link-help" to='/contact?subject=support' target="_blank">Help</Link>
					<LogoutLink className='navbar-menu-link navbar-menu-link-logout' />
					<Credits />
				</div>
			) : (
				<div className='navbar-menu'>
					<Link className="navbar-menu-link navbar-menu-link-login" to='/app/login' onClick={(event) => handleLoginClick(event)}>Log in</Link>
					<Link className="navbar-menu-link navbar-menu-link-help" to='/contact?subject=support' target="_blank">Help</Link>
					<Link className="navbar-menu-link navbar-menu-link-signup" to='/app/signup' onClick={(event) => handleSignupClick(event)}>Sign up</Link>
					<Credits />
				</div>
			)}
			
		</div>
			
	);
	
};

export default Navbar;
