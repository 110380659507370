import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { ReactComponent as IconDashboard } from '../assets/icon-dashboard.svg';
import { ReactComponent as IconTopics } from '../assets/icon-topics.svg';
import { ReactComponent as IconPlan } from '../assets/icon-plan.svg';
import { ReactComponent as IconWrite } from '../assets/icon-write.svg';
import { ReactComponent as IconPublish } from '../assets/icon-publish.svg';

function Sidebar() {
	
	const location = useLocation();
	
	const { isauth, setIsAuth } = useUserContext();
	const { isdemo, setIsDemo } = useUserContext();
	const { modalsignup, setModalSignup } = useUserContext();
	
	const menuitems = {
		"dashboard": IconDashboard,
		"topics": IconTopics,
		"plan": IconPlan,
		"write": IconWrite,
		// "publish": IconPublish,
	};
	
	const handleSidebarClick = (event, item) => { 
		if ( isdemo && !isauth && ( item !== 'topics' && item !== 'plan' ) ) {
			event.preventDefault();
			setModalSignup(true);
			return;
		}
	};
	
	return (
		<div className="sidebar">
			<div className="sidebar-nav">
				{Object.entries(menuitems).map(([item,MenuIcon], index) => {
					const menuname = item.charAt(0).toUpperCase() + item.slice(1);
					return (
					<div className={`sidebar-nav-item sidebar-nav-item-${item} ${location.pathname.startsWith(`/app/${item}`) ? 'sidebar-nav-item-active' : ''}`} key={index}>
						<Link to={`/app/${item}`} className={`sidebar-link sidebar-link-${item}`} onClick={(event) => handleSidebarClick(event, item)}><MenuIcon className={`sidebar-nav-icon sidebar-nav-icon-${item}`} /> {menuname}</Link>
					</div>
					);
				})}
			</div>
		</div>
	);
}

export default Sidebar;
