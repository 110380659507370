import React, { useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { Register, Confirm, Login, RequestInvite } from './authentication';
import { Modal } from '../components/modal';
import Navbar from '../components/navbar';
import Seed from '../components/seed';
import SEOswitch from '../components/seoswitch';
import Sidebar from '../components/sidebar';
import modalregister from '../assets/modal-register.jpg';
import modalhello from '../assets/modal-welcome.jpg';
import modalpro from '../assets/modal-upgrade.jpg';
// import Breadcrumbs from '../components/seoswitch';
// import Credits from '../components/seoswitch';


function Product() {
	
	const { 
		isdemo,
		isauth,
		userid,
		modalsignup, setModalSignup,
		modalupgrade, setModalUpgrade,
		modallogin, setModalLogin,
		modalwelcome, setModalWelcome
	} = useUserContext();	
	
	const navigate = useNavigate();
	
	// Welcome modal
	
	const WelcomeModal = ({ isdemo }) => {
		return (
			<div className="modal-wecome-inner">
				<h1>Welcome to Satori!<span className="blinking-cursor">{'\u25ae'}</span></h1>
				<div className="modal-text">
				{isdemo ? (
					<span>This is the <em>topic tree</em> view, captain. <strong>Hover</strong> and <strong>right-click</strong> over any tree node for options. <br /><br />Oh, and remember - we're in <strong>demo mode</strong> right now, so after 5 actions I will kindly ask you to create an account to save your progress ~</span>
				) : (
					<span>This is your dashboard, captain. Click on the topic cluster or create a new one to get started. Use the menu on the left to switch between views.</span>
				)}
				</div>
				<div className="modal-action">
					<div className="userbutton" type="button" onClick={() => setModalWelcome(false)}>
						Let's get to it!
					</div>
				</div>
			</div>
		);
	};
	
	// Upgrade modal
	
	const UpgradeModal = () => {
		return (
			<div className="modal-upgrade-inner">
				<h1>Upgrade to Pro<span className="blinking-cursor">{'\u25ae'}</span></h1>
				<div className="modal-text">		
					<span>Time to kick things into high gear, commander. The Pro mode provides <strong>1000 credits</strong> as well as <strong>unlocks</strong> all Content Writer features, like formats, templates, and tone of voice mixer. Plus <strong>priority support</strong>, naturally. Let me know when you're ready ~</span>
				</div>
				<div className="modal-action">
					<RequestInvite />
				</div>
			</div>
		);
	};
	
	// Render product

	return (
	
		<div className="app-wrap">
		
			<div className="navbar">
				<div className="navbar-top">
					<Navbar />
				</div>
				<div className="navbar-middle">
					<Seed />
					<SEOswitch />
				</div>
			</div>
			
			<div className="main">
				<Sidebar />
				<Outlet />
			</div>		
			
			<div className="modals">
				{!isauth && (
				<div className="modals-auth">
					<Modal className='modal-signup' backgroundImage={modalregister} isOpen={modalsignup} onClose={() => setModalSignup(false)}>
						<Register fullpage={false} />
					</Modal>
					<Modal className='modal-login' backgroundImage={modalregister} isOpen={modallogin} onClose={() => setModalLogin(false)}>
						<Login fullpage={false} />
					</Modal>
				</div>
				)}
				<Modal className='modal-upgrade' backgroundImage={modalpro} isOpen={modalupgrade} onClose={() => setModalUpgrade(false)}>
					<UpgradeModal />
				</Modal>
				<Modal className='modal-welcome' backgroundImage={modalhello} isOpen={modalwelcome} onClose={() => { setModalWelcome(false); } }>
					<WelcomeModal isdemo={isdemo} />
				</Modal>
			</div>
			
		</div>
		
	);
  
}

export default Product;