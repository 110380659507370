import { Link, useLocation } from 'react-router-dom';

function Terms() {	

	return (
		<div className="page page-wrap terms-wrap aux-wrap">
		
			<div className="sections-container">
							
				<h1>Terms of Use</h1>
				
				<p>
					<em>Last updated: 4 February 2024</em>
				</p>
				
				<p>
					Welcome to Satori ("Company", "we", "our", "us"). These Terms and Conditions ("Terms", "Terms and Conditions") govern your use of our website located at satoristudio.net (together or individually "Service") operated by Satoriweb OU.
				</p>
				
				<p>
					Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service.
				</p>

				<p>
					By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.
				</p>
				
				<h2>1. Communications</h2>
				
				<p>
					By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
				</p>
				
				<h2>
					2. Content
				</h2>
				
				<p>
					Our Service allows you to create topic clusters, manage content plans, and write text for various types of content campaigns with the assistance of artificial intelligence. You are responsible for the content that you create, publish, display, link to, or otherwise make available on the Service, including its legality, reliability, and appropriateness.
				</p>
				
				<p>
					By posting Content on the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post, or display on or through the Service and you are responsible for protecting those rights.
				</p>
				
				<h2>
					3. Prohibited Uses
				</h2>
				
				<p>
					You may use the Service only for lawful purposes and in accordance with Terms. You agree not to use the Service:
				</p>
				
				<p>
					a. In any way that violates any applicable national or international law or regulation.
					b. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
					c. To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
					d. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
					e. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
				</p>
				
				<h2>
					4. Analytics
				</h2>
				
				<p>
					We may use third-party Service Providers to monitor and analyze the use of our Service.
				</p>
				
				<h2>
					5. No Use By Minors
				</h2>
				
				<p>
					The Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using the Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms.
				</p>
				
				<h2>
					6. Accounts
				</h2>
				
				<p>
					When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.

					You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.

					You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
				</p>

				<h2>
					7. Intellectual Property
				</h2>
				
				<p>
					The Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of Satoriweb OU and its licensors. The Service is protected by copyright, trademark, and other laws of both the Estonia and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Satoriweb OU.
				</p>

				<h2>
					8. Termination
				</h2>
				
				<p>
					We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
				</p>
				
				<p>
					If you wish to terminate your account, you may simply discontinue using the Service.
				</p>
				
				<p>
					All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
				</p>
				
				<h2>
					9. Limitation of Liability
				</h2>
				
				<p>
					In no event shall Satoriweb OU, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
				</p>
				
				<h2>
					10. Disclaimer
				</h2>
				
				<p>
					Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
				</p>
				
				<p>
					Satoriweb OU its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
				</p>
				
				<h2>
					11. Exclusions
				</h2>
				
				<p>
					Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
				</p>
				
				<h2>
					12. Governing Law
				</h2>
				
				<p>
					These Terms shall be governed and construed in accordance with the laws of Estonia, without regard to its conflict of law provisions.
				</p>
				
				<p>
					Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.
				</p>
				
				<h2>
					13. Changes to Terms
				</h2>
				
				<p>
					We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
				</p>
				
				<p>
					By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
				</p>
				
				<h2>
					14. Contact Us
				</h2>
				
				<p>
					If you have any questions about these Terms, please contact us <Link to="/contact">here</Link>.
				</p>
				
			</div>
			
			<div className="gradient gradient-bottom">
				<div className="gradient-blue">
				</div>
				<div className="gradient-red">
				</div>
			</div>
			
		</div>
	);
}

export default Terms;