import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { useDropdownOutsideClick } from './dropdowns';
import jsonData from '../apis/dataforseo-locales.json'; 

function SEOswitch() {
	
	const { user, updateUser } = useUserContext();
	const { isauth, setIsAuth } = useUserContext();
	const { isdemo, setIsDemo } = useUserContext();
	const { modalsignup, setModalSignup } = useUserContext();
	const { doneloading, setDoneLoading } = useUserContext();
		
	const [selectedCountryObj, setSelectedCountryObj] = useState(false);
	const locations = jsonData.tasks[0].result;
	
	const handleSEOMode = () => {
		updateUser({ seomode: !user.seomode });
	}
	
	const toggleDropdownCountry = () => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		} else {
			setShowDropdownCountry(!showDropdownCountry);
		}
	};
	
	const toggleDropdownLanguage = () => {
		if ( isdemo && !isauth ) { 
			setModalSignup(true);
			return;
		} else {
			setShowDropdownLanguage(!showDropdownLanguage);
		}
	};
	
	const handleSEOCountryChange = (locale) => {
		updateUser({ seocountry: locale });
		setShowDropdownCountry(false);
	};
	
	const handleSEOLanguageChange = (lang) => {
		updateUser({ seolanguage: lang });
		setShowDropdownLanguage(false);
	}
	
	const { 
		showDropdown: showDropdownCountry, 
		setShowDropdown: setShowDropdownCountry, 
		dropdownRef: dropdownCountryRef 
	} = useDropdownOutsideClick(false);
	
	const { 
		showDropdown: showDropdownLanguage, 
		setShowDropdown: setShowDropdownLanguage, 
		dropdownRef: dropdownLanguageRef 
	} = useDropdownOutsideClick(false);
	
	useEffect(() => {
		const selectedCountry = locations.find(
			(loc) => loc.location_name === user.seocountry
		);
		setSelectedCountryObj(selectedCountry);
		if (selectedCountry && selectedCountry.available_languages.length > 0) {
			const languageWithHighestSerps = selectedCountry.available_languages.reduce(
				(max, language) => (language.serps > max.serps ? language : max),
				selectedCountry.available_languages[0]
			);
			const hasSpecificLanguage = selectedCountry.available_languages.some(
				(language) => language.language_name === user.seolanguage
			);
			if ( !hasSpecificLanguage && user.seolanguage !== 'All languages' ) {
				updateUser({ seolanguage: languageWithHighestSerps.language_name });
			}
		}
    }, [user.seocountry]);
	
	// Render component
	
	return (
		
		<div className="navbar-controls">
		
			<div className="seo-mode">
				
				<div className="seo-mode-main">
					<div className="seo-mode-text">
						SEO mode
					</div>
					{doneloading ? (
						<div onClick={handleSEOMode} className={`switch switch-seomode switch-red switch-${user.seomode ? 'on' : 'off'}`}>
							<div className="switch-handle">
								{`${'\u00a0\u00a0'}`}
							</div>
							<div className="switch-status">
								{user.seomode ? `${'\u00a0'}on` : 'off'}
							</div>
						</div>
					) : (
						<span className="loading-indicator"></span>
					)}
				</div>
				
				<div className={`seo-mode-settings seo-mode-settings-${user.seomode ? 'on' : 'off'}`}>
					
					{doneloading && (
					
					<>
					
					<span className="seo-mode-setting seo-mode-setting-country">
						<span className="seo-mode-setting-text" onClick={toggleDropdownCountry} title={user.seomode ? undefined : 'Turn on SEO mode to change this setting'}>
							{user.seocountry}
						</span>
						{showDropdownCountry && user.seomode && (
						<div className="dropdown dropdown-seo dropdown-seo-country" ref={dropdownCountryRef}>
							<div className="dropdown-items">
								<div className="dropdown-item dropdown-item-world" key="9999" onClick={() => handleSEOCountryChange('World')}>
									World
								</div>
								{locations.map((loc) => (
									<div className={`dropdown-item dropdown-item-${loc.location_name.toLowerCase()}`} key={loc.location_code} onClick={() => handleSEOCountryChange(loc.location_name)}>
										{loc.location_name}
									</div>
								))}
							</div>
						</div>
						)}
					</span>	
					
					<span className="seo-settings-gap">
						{`${'\u00a0:\u00a0'}`}
					</span>
					
					<span className="seo-mode-setting seo-mode-setting-language">
						<span className="seo-mode-setting-text" onClick={toggleDropdownLanguage} title={user.seomode ? undefined : 'Turn on SEO mode to change this setting'}>
							{user.seolanguage}
						</span>
						{showDropdownLanguage && user.seomode && (
						<div className="dropdown dropdown-seo dropdown-seo-language" ref={dropdownLanguageRef}>
							<div className="dropdown-items">
								<div className="dropdown-item dropdown-item-all" key="9999" onClick={() => handleSEOLanguageChange('All languages')}>
									All languages
								</div>
								{selectedCountryObj && (
									selectedCountryObj.available_languages.map((lang, index) => (
										<div className={`dropdown-item dropdown-item-${lang.language_name.toLowerCase()}`} key={lang.language_code} onClick={() => handleSEOLanguageChange(lang.language_name)}>
											{lang.language_name}
										</div>
									))
								)}
							</div>
						</div>
						)}
					</span>
					
					</>
					
					)}
					
				</div>
				
			</div>
			
		</div>
	);
	
};

export default SEOswitch;
