export const sendEmail = async (emailData) => {
    
	try {
        
		const response = await fetch('/api/sendmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to send email');
        }

        return await response.json();
		
    } catch (error) {
        
		throw error;
		
    }
	
}