const openaiApiKey = "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";
const modelVersion = "gpt-3.5-turbo";

const apiOpenAI = async (content, temperature=0.7, max_tokens=200, stop=null) => {
	const postData = {
		model: modelVersion,
		temperature,
		max_tokens,
		frequency_penalty: 0,
		presence_penalty: 0,
		messages: [
			{
				role: "user",
				content,
			},
		],
	};
	if (stop) {
		postData.stop = stop;
	}
	try {
		const response = await fetch("https://api.openai.com/v1/chat/completions", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${openaiApiKey}`,
			},
			body: JSON.stringify(postData),
		});
		if (!response.ok) {
			throw new Error("OpenAI API network response was not ok");
		}
		const data = await response.json();
		return data.choices[0].message.content;
	} catch (error) {
		console.error("OpenAI API error: ", error);
		return null;
	}
};

export default apiOpenAI;
