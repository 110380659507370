import React, { useState, useEffect, useRef } from 'react';
import { useUserContext } from '../contexts/user';
import { useProtectedFetch } from '../components/fetch';
import { sendEmail } from '../apis/email';
import { CREDIT_LIMITS } from '../components/credits';
import { ReactComponent as IconUser } from '../assets/icon-account-user.svg';
import { ReactComponent as IconBilling } from '../assets/icon-account-billing.svg';
import { ReactComponent as IconNotifications } from '../assets/icon-account-notifications.svg';

function Account() {	
	
	const { 
		user, updateUser,
		isauth, setIsAuth,
		userid, setUserID,
		currentplan, setCurrentPlan,
		setModalUpgrade
	} = useUserContext();
	const protectedFetch = useProtectedFetch();
	const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
	
	const [userData, setUserData] = useState({
        firstname: '',
		lastname: '',
        email: '',
    });
	
	// Change the active tab

	const tabs = [
		{ id: 'user', name: 'User settings', icon: IconUser },
		{ id: 'billing', name: 'Plan and billing', icon: IconBilling },
		{ id: 'notifications', name: 'Notifications', icon: IconNotifications }
	];	
		
	const handleTabClick = (tabId) => {
		updateUser({ settingsActiveTab: tabId });
	};
	
	// Get user parameters
	
	const fetchUserParams = async (id) => {
        try {
            const response = await protectedFetch(`/api/user/${id}`);
            const userdata = await response.json();
            if (response.ok) {
				if ( userdata.email ) {
					setUserData({ firstname: userdata.firstname, lastname: userdata.lastname, email: userdata.email });
				}
            } else {
                throw new Error(userdata.error || 'Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
	
	useEffect(() => {
        if (isauth && userid) {
            fetchUserParams(userid);
        }
    }, [isauth, userid]);
	
	// Change name
	
	const [settingName, setSettingName] = useState({ isSaving: false, saveSuccess: false });
	
	const handleNameChange = (e) => {
		const { name, value } = e.target;
		if (name === 'firstName') {
			setUserData(prev => ({ ...prev, firstname: value }));
		} else if (name === 'lastName') {
			setUserData(prev => ({ ...prev, lastname: value }));
		}
		setSettingName(s => ({ ...s, saveSuccess: false }));
	};
	
	const sanitizeInput = (input) => {
		const trimmed = input.trim();
		const sanitized = trimmed.replace(/<script.*?>.*?<\/script>/gi, '');
		return sanitized;
	};
	
	const handleNameClick = async () => {
		
		setSettingName({ isSaving: true, saveSuccess: false });
		
		const sanitizedFirstName = sanitizeInput(userData.firstname);
		const sanitizedLastName = sanitizeInput(userData.lastname);
		if (!sanitizedFirstName || !sanitizedLastName) {
			alert('【⚆ _ ⚆】 First and last name cannot be empty ~');
			setSettingName({ isSaving: false, saveSuccess: false });
			return;
		}
		setUserData(prev => ({ ...prev, firstname: sanitizedFirstName, lastname: sanitizedLastName }));
		
		try {
			const saveOperation = await protectedFetch(`/api/user/${userid}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ firstname: userData.firstname, lastname: userData.lastname })
			});
			const [response] = await Promise.all([saveOperation, delay(1000)]); 
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			setSettingName({ isSaving: false, saveSuccess: true });
		} catch (error) {
			setSettingName({ isSaving: false, saveSuccess: false });
			console.error("Error saving data:", error);
		}
		
	};
	
	// Change email
	
	const [settingEmail, setSettingEmail] = useState({ isSaving: false, saveSuccess: false });
	const [newEmail, setNewEmail] = useState('');
	const [verificationCode, setVerificationCode] = useState('');
	const [verificationRequested, setVerificationRequested] = useState(false);
	
	const handleEmailChange = (e) => {
		const { email, value } = e.target;
		setNewEmail(value);
		setSettingEmail(s => ({ ...s, saveSuccess: false }));
	};
	
	const handleEmailClick = async () => {
		setSettingEmail({ isSaving: true, saveSuccess: false });
		await protectedFetch('/api/email-verification', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ oldemail: userData.email, newemail: newEmail }),
		});	
		setVerificationRequested(true);
	};
	
	const verifyEmail = async () => {
		const newEmailBuffer = newEmail;
		const response = await protectedFetch('/api/verify-email', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ oldemail: userData.email, newemail: newEmailBuffer, code: verificationCode }),
		});
		if (response.ok) {
			setUserData(prev => ({ ...prev, email: newEmailBuffer }));
			setNewEmail('');
			setVerificationCode('');
			setVerificationRequested(false);
			setSettingEmail({ isSaving: false, saveSuccess: true });
			alert('(ノ•́ᴗ•́)ノ  Email successfully verified!');
		} else {
			setSettingEmail({ isSaving: false, saveSuccess: false });
			alert('╮(ﾟ～ﾟ;)╭   Ouf, verification failed... Please check the code and try again.');
		}
	};
	
	// Reset password
	
	const [settingPassword, setSettingPassword] = useState({ isSaving: false, saveSuccess: false });
	
	const requestPasswordReset = async (email) => {
		setSettingPassword({ isSaving: true, saveSuccess: false });
		try {
            const response = await fetch('/api/password-reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });
            const responseData = await response.json();
            if (!response.ok) {
				setSettingPassword({ isSaving: false, saveSuccess: false });
				alert('〈◕﹏◕〉   Ouf, verification failed... Please check the code and try again.');
                throw new Error(responseData.message || 'Error requesting password reset.');				
            } else {
				setSettingPassword({ isSaving: false, saveSuccess: true });
			}
        } catch (error) {
			setSettingPassword({ isSaving: false, saveSuccess: false });
            console.error('Error:', error);
            alert(error.message || 'Error in requesting password reset.');
        }		
    };
	
	const handlePasswordClick = async (e) => {
        e.preventDefault();
        await requestPasswordReset(userData.email);
    };
	
	// Delete Account
	
	const [deleteRequested, setDeleteRequested] = useState(false);
	const [userEmail, setUserEmail] = useState(false);
	
	const handleAccountDelete = async (e) => {
		e.preventDefault();
		const emailData = {
            to: 'hello@satoristudio.net',
			reply: userData.email, 
            subject: 'Account deletion request',
            text: `User: ${userData.email}`
        };
        try {
            const result = await sendEmail(emailData);
			setDeleteRequested('Account removal request sent!');
        } catch (error) {
            console.error('Error sending email:', error.message);
			setDeleteRequested('Something went wrong, contact us!');
        }
	};
	
	// Switch plan
	
	const handlePlanChange  = async () => {
		//// TEMP; switch to actual plan change
		setModalUpgrade(true);
		return;
	};
	
	// Manage plan
	
	const managePlan  = async () => {
		
	};
	
	// Notifications settings
		
	const handleNotifs = (type) => {
		if ( type === 'updates' ) {
			updateUser({ notificationsUpdates: !user.notificationsUpdates });
		} else if ( type === 'newsletter' ) {
			updateUser({ notificationsNewsletter: !user.notificationsNewsletter });
		}
	};
	
	// Render account settings
	
	return (
		
		<div className="account-wrap">
			
			<h2>Account Settings</h2>
			
			<div className="account-settings">
			
				<div className="account-tabs">
					
					{tabs.map(tab => (
					
						<div key={tab.id} className={`account-tab ${user.settingsActiveTab === tab.id ? 'account-tab-active' : ''}`} onClick={() => handleTabClick(tab.id)} >
							<tab.icon className={`account-tab-icon account-tab-icon-${tab.id}`} />
							<span className="account-tab-name">{tab.name}</span>
						</div>
					
					))}
					
				</div>
				
				<div className="account-container">
					
					{user.settingsActiveTab === 'user' && 
						
						<div className="account-settings-section">
							
							<div className="account-setting account-setting-name">
								<div className="setting-title">
									What's your name?
								</div>
								<div className="setting-container">
									<div className="setting-value setting-value-input">
										<input type="text" value={userData.firstname} name="firstName" disabled={settingName.isSaving} onChange={handleNameChange} placeholder="First name" />
										<input type="text" value={userData.lastname} name="lastName" disabled={settingName.isSaving} onChange={handleNameChange} placeholder="Last name" />
									</div>
									<div className="setting-save">
										<button onClick={handleNameClick} disabled={settingName.isSaving || settingName.saveSuccess}>{settingName.isSaving ? 'Saving...' : settingName.saveSuccess ? `Saved! Hello ${userData.firstname} ~` : 'Save changes'}</button>
									</div>
								</div>
							</div>
							
							<div className="account-setting account-setting-email">
								<div className="setting-title">
									Email
								</div>
								<div className="setting-container">
									<div className="setting-value setting-value-input">
										<input type="email" required value={newEmail ? newEmail : userData.email} name="email" disabled={settingEmail.isSaving || verificationRequested} onChange={handleEmailChange} placeholder="Your email" />
									</div>
									<div className="setting-save">
										<button onClick={handleEmailClick} disabled={settingEmail.isSaving || settingEmail.saveSuccess || verificationRequested}>{settingEmail.isSaving ? 'Processing...' : settingEmail.saveSuccess ? 'Email updated!' : 'Save changes'}</button>
									</div>
									{verificationRequested && (
										<div className="setting-email-verify">
											<input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder="Code" />
											<button onClick={verifyEmail}>Verify</button>
										</div>
									)}
								</div>
							</div>
							
							<div className="account-setting account-setting-password">
								<div className="setting-title">
									Password
								</div>
								<div className="setting-container">
									<div className="setting-save">
										<button className="account-reset-password" onClick={handlePasswordClick} disabled={settingPassword.isSaving || settingPassword.saveSuccess}>{settingPassword.isSaving ? 'Sending reset email' : settingPassword.saveSuccess ? 'Check your mailbox!' : 'Reset password'}</button>
									</div>
								</div>
							</div>
							
							<div className="account-setting account-setting-delete">
								<div className="setting-title">
									Delete account
								</div>
								<div className="setting-container">
									<div className="setting-save">
										<button className="account-delete" onClick={handleAccountDelete}>{deleteRequested ? deleteRequested : 'Send account removal request'}</button>
									</div>
								</div>
							</div>
							
						</div>
						
					}
					
					{user.settingsActiveTab === 'billing' && 
					
						<div className="account-settings-section">
							
							<div className="account-setting account-setting-plan">
								<div className="setting-title">
									Your current plan
								</div>
								<div className="setting-container">
									<div className="setting-value setting-value-state">
										<div className="setting-value-plan-name">{currentplan === 'free' ? 'Droid:free' : 'Mecha:pro'}</div>
										<span className="setting-value-plan-dash">—</span>
										<div className="setting-value-plan-price">{currentplan === 'free' ? '$0/month' : '$25/month'}</div>
									</div>
									<div className="setting-save">
										{currentplan === 'pro' && <button onClick={managePlan}>Manage</button>}
										<button className={currentplan === 'free' ? 'button-plan-upgrade' : 'button-plan-downgrade'} onClick={handlePlanChange}>{currentplan === 'free' ? 'Upgrade' : 'Downgrade'}</button>
									</div>
								</div>
							</div>
							
							<div className="account-setting account-setting-credits">
								<div className="setting-title">
									Available credits
								</div>
								<div className="setting-container">
									{user.credits}<span className="navbar-credits-count-limit">/{CREDIT_LIMITS[currentplan]}</span>
								</div>
							</div>
							
						</div>
						
					}
					
					{user.settingsActiveTab === 'notifications' && 
					
						<div className="account-settings-section">
							
							<div className="account-setting account-setting-notif-updates">
								<div className="setting-title">
									Product updates
								</div>
								<div className="setting-container setting-switch">
									<div onClick={() => handleNotifs('updates')} className={`switch notifications-updates-switch switch-${user.notificationsUpdates ? 'on' : 'off'}`}>
										<div className="switch-handle">
											{`${'\u00a0\u00a0'}`}
										</div>
										<div className="switch-status">
											{user.notificationsUpdates ? `${'\u00a0'}on` : 'off'}
										</div>
									</div>
									<div className="switch-description">
										Stay in the loop on new feature releases.
									</div>
								</div>
							</div>
							
							<div className="account-setting account-setting-notif-newsletter">
								<div className="setting-title">
									Monthly newsletter
								</div>
								<div className="setting-container setting-switch">
									<div onClick={() => handleNotifs('newsletter')} className={`switch notifications-updates-switch switch-${user.notificationsNewsletter ? 'on' : 'off'}`}>
										<div className="switch-handle">
											{`${'\u00a0\u00a0'}`}
										</div>
										<div className="switch-status">
											{user.notificationsNewsletter ? `${'\u00a0'}on` : 'off'}
										</div>
									</div>
									<div className="switch-description">
										Subscribe for tutorials, tips, and secrets.
									</div>
								</div>
							</div>
							
						</div>
						
					}
					
				</div>
				
			</div>
			
		</div>
		
	);
  
}

export default Account;