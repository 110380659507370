import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate, useLocation, Redirect, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Product from './pages/product';
import Dashboard from './pages/dashboard';
import Account from './pages/account';
import Home from './pages/home';
import Topics from './components/topics';
import Plan from './components/plan';
import Write from './components/write';
import Contact from './pages/contact';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import NotFound from './pages/notfound';
import { useUserContext } from './contexts/user';
import { Register, Confirm, Login, Reset } from './pages/authentication';
import { useGA4React } from './apis/googleanalytics';

import './styles.css';

function App() {	

	const { isauth, isdemo } = useUserContext();
	const location = useLocation();
	
	// Convert current location to class
	
	function CurrentPathToClassName(path) {
		let className;
		if (path === '/') {
			className = 'front';
		} else {
			const patternsWithIds = ["topics", "users", "posts"];
			let segments = path.split('/').filter(segment => segment);
			for (let i = 0; i < segments.length; i++) {
				if (patternsWithIds.includes(segments[i]) && /^[a-z0-9]{24}$/i.test(segments[i + 1])) {
					segments.splice(i + 1, 1);
				}
			}
			className = segments.join('-');
		}
		return className;
	}
	
	// Protected routes
	
	const ProtectedRoute = ({ children }) => {
		const demodata = localStorage.getItem('demodata');
		const gotourl = useNavigate();
		const tokenInStorage = localStorage.getItem('accessToken');	
		useEffect(() => {
			if (!tokenInStorage && !isauth && !demodata) {
				gotourl('/login');
			}
		}, [isauth, tokenInStorage]);
		if (!tokenInStorage && !isauth && !demodata) {
			return null;
		}
		return children;
	};
	
	// Add meta tags
	
	const HelmetWrapper = ({ children, title, description }) => {
		return (
			<>
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={description} />
				</Helmet>
				{children}
			</>
		);
	};
	
	// Google Analytics
	
	useGA4React();
	
	// Capture UTM tags
	
	const [utmParams, setUtmParams] = useState({});
	const [captured, setCaptured] = useState(false);

	useEffect(() => {
		const extractUtmParameters = () => {
			const queryParams = new URLSearchParams(window.location.search);
			const utmObject = {};
			if (queryParams.has('utm_source')) {
				utmObject.source = queryParams.get('utm_source');
			}
			if (queryParams.has('utm_medium')) {
				utmObject.medium = queryParams.get('utm_medium');
			}
			if (queryParams.has('utm_campaign')) {
				utmObject.campaign = queryParams.get('utm_campaign');
			}
			if (queryParams.has('utm_term')) {
				utmObject.term = queryParams.get('utm_term');
			}
			if (queryParams.has('utm_content')) {
				utmObject.content = queryParams.get('utm_content');
			}
			localStorage.setItem('utmParams', JSON.stringify(utmObject));
			setUtmParams(utmObject);
		};
		const utmParamsString = localStorage.getItem('utmParams');
		if (!utmParamsString) {
			extractUtmParameters();
			setCaptured(true);
		} else {
			setUtmParams(JSON.parse(utmParamsString));
		}
	}, []);

	// Render all the things

	return ( 
		<div className={`wrap loc-${CurrentPathToClassName(location.pathname)}`}>
			<Routes>
				<Route path="/" element={
					isauth ? 
						<Navigate to="/app/dashboard" /> 
					: isdemo ? 
						<Navigate to="/app/topics" /> 
					: 
						<HelmetWrapper title="Satori: 10x your content strategy ~" description="Man and machine, unite: brainstorm topic clusters, create seamless content plans, and write drafts in seconds, not hours.">
							<Home />
						</HelmetWrapper>
				} />
				<Route path="/app" element={<ProtectedRoute><Product /></ProtectedRoute>}>
					<Route index element={<Navigate replace to="/app/dashboard" />} />
					<Route path="dashboard" element={
						<HelmetWrapper title="Dashboard" description="Welcome to your command center, captain">
							<Dashboard />
						</HelmetWrapper>
					} />
					<Route path="topics/:id?" element={
						<HelmetWrapper title="Topics" description="Brainstorm topic clusters and SEO maps">
							<Topics />
						</HelmetWrapper>
					} />
					<Route path="plan/:id?" element={
						<HelmetWrapper title="Plan" description="Plan your content like a pro">
							<Plan />
						</HelmetWrapper>
					} />
					<Route path="write/:id?" element={
						<HelmetWrapper title="Write" description="Draft new content in a breeze">
							<Write />
						</HelmetWrapper>
					} />
					<Route path="account" element={
						<HelmetWrapper title="Account settings" description="Modify your parameters here">
							<Account />
						</HelmetWrapper>
					} />
					<Route path="*" element={<Navigate replace to="/app/dashboard" />} />
				</Route>
				<Route path="/signup" element={
					<HelmetWrapper title="Create account" description="How about a respawn point? Create an account to save your progress and create more topics, projects, and content drafts!">
						<Register />
					</HelmetWrapper>
				} />
				<Route path="/confirm-email" element={
					<HelmetWrapper title="Confirm email" description="We're almost there!">
						<Confirm />
					</HelmetWrapper>
				} />
				<Route path="/password-reset" element={
					<HelmetWrapper title="Reset your password" description="Let's stay safe, commander - don't use your existing passwords and avoid simple combos like 'password1!'">
						<Reset />
					</HelmetWrapper>
				} />
				<Route path="/login" element={
					<HelmetWrapper title="Log in" description="Welcome back, commander! Please enter your credentials to continue">
						<Login />
					</HelmetWrapper>
				} />
				<Route path="/contact" element={
					<HelmetWrapper title="Contact" description="Ready for your transmission!">
						<Contact />
					</HelmetWrapper>
				} />
				<Route path="/terms" element={
					<HelmetWrapper title="Terms" description="This all looks like legal gibberish - until it's actually useful">
						<Terms />
					</HelmetWrapper>
				} />
				<Route path="/privacy" element={
					<HelmetWrapper title="Privacy" description="Here's how we collect, store, process, and protect your data">
						<Privacy />
					</HelmetWrapper>
				} />
			</Routes>
		</div>
	);
  
}

console.log('Satori Studio good to go, roger');

export default App;