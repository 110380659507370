import React, { useRef, useEffect, useState } from 'react';

const ParallaxImage = ({ 
	src, 
	newSrc, 
	swapScrollThreshold = 300, 
	speed = 0.5, 
	delay = 0, 
	maxscroll = 500, 
	initialtopWide = 0, 
	initialtopMob = 0, 
	fadeElementRef 
}) => {
    
	const imageRef = useRef(null);
    const newImagePreloaded = useRef(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const preloadNewImage = () => {
        const img = new Image();
        img.onload = () => {
            newImagePreloaded.current = true;
        };
        img.src = newSrc;
    };
	
	const handleScroll = () => {
        if (imageRef.current) {
            
			const elementTop = imageRef.current.getBoundingClientRect().top;
            const viewportHeight = window.innerHeight;
			let initialtop = initialtopWide;
			let swapscroll = swapScrollThreshold;
			let stopscroll = maxscroll;
			let fadeSpeedFactor = 1.5;
			if ( screenWidth < 1024 ) {
				initialtop = initialtopMob;
				swapscroll = swapScrollThreshold * 0.5;
				stopscroll = maxscroll * 0.7;
				fadeSpeedFactor = 3;
			}
            const scrolled = Math.max(viewportHeight - elementTop - delay, 0);
            const effectiveScroll = Math.min(scrolled, stopscroll);
            const newPos = initialtop + (effectiveScroll * speed);
            imageRef.current.style.top = `${newPos}px`;

            if (effectiveScroll >= swapscroll && newImagePreloaded.current) {
                imageRef.current.src = newSrc;
            } else if (effectiveScroll < swapscroll) {
                imageRef.current.src = src;
            }
			
			if (fadeElementRef.current) {
                const opacity = Math.min(1, effectiveScroll / (stopscroll / fadeSpeedFactor));
                fadeElementRef.current.style.opacity = opacity;
            }
			
        }
    };

    useEffect(() => {
        preloadNewImage();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [swapScrollThreshold, speed, maxscroll, delay]);

    return <img ref={imageRef} src={src} className="parallax-image" />;

};

export default ParallaxImage;