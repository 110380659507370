import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-DYVFM7G7T4";

export const useGA4React = () => {
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			(async () => {
				try {
					ReactGA.initialize(TRACKING_ID);
				} catch (error) {
					console.error("Failed to initialize GA4", error);
				}
			})();
		}
	}, []);

	// Function to track events
	const trackEvent = (params) => {
		if (process.env.NODE_ENV === 'production') {
            ReactGA.event(params);
        }
	};

	return { trackEvent };
};