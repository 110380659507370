import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/user';
import { useProtectedFetch } from '../components/fetch';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { sendEmail } from '../apis/email'; 
import { LogoutLink } from './authentication';
import contactwelcome from '../assets/contact.jpg';
	
const ContactForm = ({ setMessage, setIsError }) => {
	
	const { isauth, setIsAuth } = useUserContext();
	const { userid, setUserID } = useUserContext();
	const location = useLocation();
	const protectedFetch = useProtectedFetch();
	
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        subject: 'contact'
    });
	
	const queryParams = new URLSearchParams(location.search);
	const querySubject = queryParams.get('subject');
	
    useEffect(() => {
        if (isauth && userid) {
            fetchUserEmail(userid);
        }
		if ( querySubject ) {
			formData.subject = querySubject;
		}
    }, [isauth, userid, location.search]);

    const fetchUserEmail = async (id) => {
        try {
            const response = await protectedFetch(`/api/user/${id}`);
            const userData = await response.json();
            if (response.ok) {
				if ( userData.email ) {
					setFormData(prev => ({ ...prev, email: userData.email }));
				}
            } else {
                throw new Error(userData.error || 'Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
		const emailData = {
            to: 'hello@satoristudio.net',
			reply: formData.email, 
            subject: `[${formData.subject}] New convo: Satori and ${formData.name}`,
            text: formData.message
        };
        try {
            const result = await sendEmail(emailData);
            console.log('Email sent successfully', result);
			setIsError(false);
            setMessage('(⚆ ̫ ⚆)  Thanks for your message, we will reply fast-fast!');
        } catch (error) {
            console.error('Error sending email:', error.message);
			setIsError(true);
			setMessage('(･_･、)  Cound not send email, please try again!');
        }
    };

    return (
        
		<form className="form-contact" onSubmit={handleSubmit}>
			
			<div className="form-field form-contact-field form-contact-field-name">
				<label>Name:</label>
				<input 
					type="text" 
					name="name" 
					value={formData.name} 
					onChange={handleChange} 
					placeholder="Nice to meet you ^_^" 
					required 
				/>
			</div>
			
			<div className="form-field form-contact-field form-contact-field-email">
				<label>Email:</label>
				<input 
					type="email" 
					name="email" 
					value={formData.email} 
					onChange={handleChange} 
					placeholder="We'll reply asap" 
					required 
				/>
			</div>
			
			<div className="form-field form-contact-field form-contact-field-message">
				<label>{querySubject === 'enterprise' ? 'Tell us about your needs:' : 'Message:'}</label>
				<textarea 
					name="message" 
					value={formData.message} 
					onChange={handleChange} 
					placeholder="How can we help?" 
					required 
				/>
			</div>
            
            <button type="submit">Send Message</button>
			
        </form>
		
    );
};

const Wrapper = ({ children }) => {
	
	const { isauth } = useUserContext();
	const { user } = useUserContext();
		
	return (
		
		<div className="page page-wrap contact-wrap centered-wrap">
	
			<div className="header header-contact">
				
				<div className="header-logo">
					<Link to='/' >
						<Logo className="logo" />
					</Link>
				</div>
				
				<div className="navbar-menu navbar-menu-nonauth">
					{isauth ? (
						<div className="user-links">
							<Link className="navbar-menu-link navbar-menu-link-workspace" to={ user.lastpage ? user.lastpage : '/app/dashboard' }>Workspace</Link>
							<LogoutLink className='navbar-menu-link navbar-menu-link-logout' />
						</div>
					) : (
						<div className="auth-links">
							<Link to={'/login'} className="navbar-menu-link navbar-menu-link-login">Login</Link>
							<Link to={'/signup'} className="navbar-menu-link menu-link-register">Register</Link>
						</div>
					)}
				</div>
			
			</div>
				
			<div className="primary">				
				<div className="contact-container">		
					{children}
					<div className="contact-image">
						<img src={contactwelcome} alt="How may I help you?" />
					</div>					
				</div>					
			</div>
					
		</div>
			
	);

};

const Contact = ({ fullpage=true }) => {
	
	const [message, setMessage] = useState('');
	const [iserror, setIsError] = useState(false);
	
	const content = (
		
		<div className="contact-wrap">
		
			<h1>Ready for your transmission!<span className="blinking-cursor">{'\u25ae'}</span></h1>
					
			<ContactForm 
				setMessage={setMessage} 
				setIsError={setIsError} 
			/>
			
			{message && <div className={`form-message ${iserror ? 'form-error' : ''}`}><span className="form-message-text">{message}</span></div>}
		
		</div>
		
	);
	
	return (
	
		<>
		{fullpage ? <Wrapper>{content}</Wrapper> : content}	
		</>
		       
    );
	
};

export default Contact;