import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUserContext } from './user';
import { useProtectedFetch } from '../components/fetch';
import { generateChecksum } from '../components/checksum';

const DataContext = createContext();

export const useDataContext = () => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error('DataContext must be used within a DataContextProvider');
	}
	return context;
};

export function DataContextProvider({ children }) {
	
	// Set parameters
	
	const [data, setData] = useState({
		topicdata: null,
		uniquetopics: new Set(),
		seedvalue: '',
		isloading: true,
	});
	
	const { user, updateUser } = useUserContext();
	const { userid, setUserID } = useUserContext();
	const { projectid, setProjectID } = useUserContext();
	const { topicid, setTopicID } = useUserContext();
	const { isdemo, setIsDemo } = useUserContext();
	const { democount, setDemoCount } = useUserContext();
	const { modalsignup, setModalSignup } = useUserContext();
	const { setDoneLoading } = useUserContext();
	const protectedFetch = useProtectedFetch();
	
	// Update data
	
	const updateData = (updatedValues) => {
		const updatedData = {
			...data,
			...updatedValues,
		};
		setData(updatedData);
	};
	
	// Save topicdata when it changes
	
	useEffect(() => {
		if (data.topicdata) {
			saveTopicTreeToDatabase(data.topicdata);
		}
	}, [data.topicdata]);
	
	const saveTopicTreeToDatabase = async (topicdata) => {
		if ( isdemo ) {
			return;
		}
		try {
			if (topicid) {
                const response = await protectedFetch(`/api/topic/${topicid}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        topictree: topicdata,
                    })
                });
                const responsedata = await response.json();
            } else {
                const response = await protectedFetch('/api/topic', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
						userID: userid,
						projectID: projectid,
						seed: data.seedvalue,
                        topictree: topicdata,      
                    })
                });
                const responsedata = await response.json();
                setTopicID(responsedata._id);
            }
		} catch (error) {
            console.log('Error saving topic tree: ', error);
        }
    };
	
	// Set demo if data present in local storage
	
	useEffect(() => {
		try {
			const demodata = localStorage.getItem('demodata');
			if (demodata) {
				const demodataparsed = JSON.parse(demodata);
				setIsDemo(true);	
				if ( !democount ) {
					const newChecksum = generateChecksum(demodataparsed.seedvalue, demodataparsed.count);
					if ( newChecksum === demodataparsed.session ) {
						setDemoCount(demodataparsed.count);
					} else {
						setModalSignup(true);
						return;
					}	
				}				
				setDoneLoading(true);
				updateData({
					seedvalue: demodataparsed.seedvalue,
					topicdata: demodataparsed.topicdata, 
					uniquetopics: demodataparsed.uniquetopics, 
					isloading: false,
				});
			}
		} catch (e) {
			localStorage.removeItem('demodata');
			console.error("Error parsing JSON from localStorage:", e);
		}
	}, [data.seedvalue]);
	
	// Expose context
	
	return (
		<DataContext.Provider value={{ data, updateData	}}>
			{children}
		</DataContext.Provider>
	);
	
}