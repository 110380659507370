export const Message = (message) => {
	const convertToLink = (text) => {
		const urlRegex = /\[([^\|]+)\|\/([^\]]+)\]/g;
		return text.replace(urlRegex, (match, anchorText, relativePath) => {
			const absoluteUrl = window.location.origin + '/' + relativePath;
			return `<a href="${absoluteUrl}" target="_blank" rel="noopener noreferrer">${anchorText}</a>`;
		});
	};
	return (
		<div className="message" dangerouslySetInnerHTML={{ __html: convertToLink(message) }} />
	);
};