import { useUserContext } from '../contexts/user';

export const useProtectedFetch = () => {
	const { accesstoken, refreshToken } = useUserContext();
	async function protectedFetch(url, options = {}) {
		const response = await fetch(url, {
			...options,
			headers: {
				...options.headers,
				'Authorization': `Bearer ${accesstoken}`
			}
		});
		if (response.status === 401) {
			await refreshToken();
			return protectedFetch(url, options);
		}
		return response;
	}
	return protectedFetch;
}
