// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { DataContextProvider } from './contexts/data';
import { UserContextProvider } from './contexts/user';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	//<React.StrictMode>
		<BrowserRouter>
			<UserContextProvider>
				<DataContextProvider>
					<App />
				</DataContextProvider>
			</UserContextProvider>		
		</BrowserRouter>
	//</React.StrictMode>
);